import { Input, Typography, Row, InputProps } from "antd";
import { PasswordVisible, PasswordInvisible } from "../../assets";

interface PasswordInputProps extends InputProps {
  title: string;
  error: string | undefined;
}

export function PasswordInput({
  title,
  onChange,
  value,
  error,
}: PasswordInputProps) {
  const { Password } = Input;
  const { Text } = Typography;
  return (
    <Row className="component-row">
      <Text type="secondary">{title}</Text>
      <Password
        style={{ borderRadius: "25px" }}
        onChange={onChange}
        value={value}
        iconRender={(visible) =>
          visible ? (
            <PasswordVisible height="20px" />
          ) : (
            <PasswordInvisible height="20px" />
          )
        }
      />
      {error && <Text type="danger">{error}</Text>}
    </Row>
  );
}
