import {
  Level,
  ProgramCategory,
} from "../../../../../../../constants/enums";
import * as Yup from "yup";
import { i18n } from "../../../../../../../i18n";
import { ProgramTemplateWithExerciseTemplates } from "../../../../../../../hooks/api/ProgramTemplates";

const t = (key: string) => i18n.t(key, { ns: "translation" });

export interface EditProgramFormData {
  name: string;
  category: ProgramCategory;
  level: Level;
  length: number;
  equipmentNeeded: string;
  description: string;
  folderId: number;
}

export const initEditProgramFormData = (program: ProgramTemplateWithExerciseTemplates) => ({
  name: program.name,
  category: program.category,
  level: program.level,
  length: program.length,
  equipmentNeeded: program.equipmentNeeded,
  description: program.description,
  folderId: program.folderId,
})

export const editProgramSchema: Yup.SchemaOf<EditProgramFormData> =
  Yup.object()
    .shape({
      name: Yup.string()
        .min(2, t("validators.tooShort"))
        .max(50, t("validators.tooLong"))
        .required(t("validators.required")),
      category: Yup.mixed<ProgramCategory>().oneOf(
        Object.values(ProgramCategory)
      ),
      level: Yup.mixed<Level>().oneOf(Object.values(Level)),
      length: Yup.number()
        .min(1)
        .integer(t("validators.integer"))
        .required(t("validators.required")),
      equipmentNeeded: Yup.string().nullable(),
      description: Yup.string().required(t("validators.required")),
      folderId: Yup.number().required(t("validators.required")),
    })
    .noUnknown(true)
    .strict()
    .required();
