import { Input } from "antd";
import Text from "antd/lib/typography/Text";
import { colors } from "../../../../../../../../constants/colors";

interface LabelInfoProps {
  label?: string;
  value?: any;
  onChange: (event: string) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorText?: string;
  defaultValue?: any;
}

export function LabelInfoEdit({
  label,
  value,
  onChange,
  onBlur,
  errorText,
  defaultValue,
}: LabelInfoProps) {
  return (
    <div style={{ padding: "5px" }}>
      {label ? (
        <div style={{ color: colors.textSecondary }}>{label}</div>
      ) : null}

      <Input
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        value={value}
        style={{
          borderRadius: "40px",
          borderStyle: "solid",
          borderColor: colors.textSecondary,
        }}
        defaultValue={defaultValue}
      />
      {errorText ? (
        <Text style={{ color: colors.errorColor }}>{errorText}</Text>
      ) : null}
    </div>
  );
}
