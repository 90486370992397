// @ts-nocheck
import { ExerciseTempalteWithSubcategory } from "../hooks/api/ExerciseTemplates";

export const groupByCategory = (
  _exercises: ExerciseTempalteWithSubcategory[]
) => {
  const grouped =
    _exercises?.reduce((acc, value) => {
      const category = value.exerciseSubcategory.category ?? "myExercises";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(value);
      return acc;
    }, {}) ?? [];
  return Object.keys(grouped).map((key) => ({
    name: key,
    subcategories: groupBySubcategory(grouped[key]),
  }));
};

const groupBySubcategory = (_exercises: ExerciseTempalteWithSubcategory[]) => {
  const grouped = _exercises.reduce((acc, value) => {
    const subcategory = value.exerciseSubcategory.name;
    if (!acc[subcategory]) {
      acc[subcategory] = [];
    }

    acc[subcategory].push(value);

    return acc;
  }, {});
  return Object.keys(grouped).map((key) => ({
    name: key,
    ...groupBySubSubcategory(grouped[key]),
  }));
};

const groupBySubSubcategory = (
  _exercises: ExerciseTempalteWithSubcategory[]
) => {
  const grouped = _exercises.reduce(
    (acc, value) => {
      if (value?.exerciseSubSubcategory) {
        const subsubcategory = value.exerciseSubSubcategory.name;
        if (!acc[subsubcategory]) {
          acc[subsubcategory] = [];
        }

        acc[subsubcategory].push(value);
      } else {
        acc.exercises.push(value);
      }

      return acc;
    },
    { exercises: [] }
  );

  const { exercises } = grouped;
  delete grouped.exercises;

  return {
    exercises,
    subsubcategories: Object.keys(grouped).map((key) => ({
      name: key,
      exercises: grouped[key],
    })),
  };
};
