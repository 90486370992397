// @ts-nocheck
import { useEffect, useState } from "react";
import { programTemplateExerciseTemplate } from "./api/ProgramTemplates";
import { AdditionalParameters } from "./api/ProgramTemplates";
import { v4 as uuidv4 } from "uuid";
import { exerciseTemplate } from "./api/ExerciseTemplates";
import { restTimeInSeconds } from "../utils/timeConverter";

export interface programTemplateExerciseTemplateEdit
  extends programTemplateExerciseTemplate {
  uuid: string;
}

const generateUuid = () => uuidv4();

export const useProgramExercises = (
  exercisesInit: Array<programTemplateExerciseTemplate> | null
) => {
  const [exercises, setExercises] = useState<
    Array<programTemplateExerciseTemplateEdit>
  >([]);

  useEffect(() => {
    if (exercisesInit) {
      const prepareExerciseParameters = (values: AdditionalParameters) => {
        const additionalParameters = {
          ...values,
          ...values.tempo,
          restTime: restTimeInSeconds(String(values.restTime)),
        };
        delete additionalParameters.tempo;
        return additionalParameters;
      };

      setExercises(
        exercisesInit
          .sort((a, b) => a.order - b.order)
          .map((exercise) => ({
            ...exercise,
            uuid: generateUuid(),
            exerciseParameters: prepareExerciseParameters(exercise),
          }))
      );
    }
  }, [exercisesInit]);

  const clearArray = () => setExercises([]);

  const moveUp = (index: number) => {
    setExercises((exercises) => {
      const newExercise = [...exercises];
      newExercise[0].breakBefore = true;
      newExercise[index].order -= 1;
      newExercise[index - 1].order += 1;

      const tmp = newExercise[index];
      newExercise[index] = newExercise[index - 1];
      newExercise[index - 1] = tmp;
      return newExercise;
    });
  };

  const moveDown = (index: number) => {
    setExercises((exercises) => {
      const newExercise = [...exercises];
      newExercise[index].order += 1;
      newExercise[index + 1].order -= 1;

      const tmp = newExercise[index];
      newExercise[index] = newExercise[index + 1];
      newExercise[index + 1] = tmp;
      return newExercise;
    });
  };

  const changeBreak = (index: number) => {
    const newExercise = [...exercises];
    newExercise[index].breakBefore = !exercises[index].breakBefore;
    setExercises(newExercise);
  };

  const prepareExercise = (
    exercise: exerciseTemplate,
    additionalParameters?: AdditionalParameters,
    uuid?: string,
    oldExercise?: programTemplateExerciseTemplate
  ) => {
    return {
      ...exercise,
      id:
        exercises.find((oldExercise) => oldExercise.uuid === uuid)?.id ||
        exercise.id,
      ...additionalParameters,
      exerciseParameters: additionalParameters,
      order: oldExercise?.order ?? exercises.length,
      breakBefore: true,
      uuid: generateUuid(),
    };
  };

  const addExercise = (
    exercise: exerciseTemplate,
    additionalParameters?: AdditionalParameters
  ) => {
    const newExercise = prepareExercise(exercise, additionalParameters);
    setExercises((exercises) => [...exercises, newExercise]);
  };

  const copyExercises = (
    exerciseTemplates: programTemplateExerciseTemplate[]
  ) => {
    const copiedExercises = exerciseTemplates.map((item, index) => {
      const parameters: AdditionalParameters = {
        repetitions: item.repetitions,
        sets: item.sets,
        time: item.time,
        equipment: item.equipment,
        weight: item.weight,
        tempo1: item.tempo1,
        tempo2: item.tempo2,
        tempo3: item.tempo3,
        tempo4: item.tempo4,
        repetitionMaximum: item.repetitionMaximum,
        restTime: item.restTime,
      };

      return prepareExercise(item, parameters, undefined, {
        ...item,
        order: exercises.length + index,
      });
    });

    setExercises((exercises) => [...exercises, ...copiedExercises]);
  };

  const editExercise = (
    exercise: exerciseTemplate,
    additionalParameters: AdditionalParameters,
    uuid: string
  ) => {
    const indexOfExercise = exercises.findIndex(
      (oldExercise) => oldExercise.uuid === uuid
    );
    const newExercise = prepareExercise(
      exercise,
      additionalParameters,
      uuid,
      exercises[indexOfExercise]
    );

    exercises[indexOfExercise] = newExercise;
    setExercises([...exercises]);
  };

  const removeExercise = (uuid: string) => {
    const newExercises = exercises.filter(
      (toRemoveExercise) => toRemoveExercise.uuid !== uuid
    );
    setExercises(newExercises);
  };

  return {
    moveUp,
    moveDown,
    changeBreak,
    addExercise,
    editExercise,
    removeExercise,
    clearArray,
    copyExercises,
    exercises,
  };
};
