import { i18n } from "../../../i18n";
import * as Yup from "yup";
import { ForgotPasswordDto } from "../../../hooks/api/Auth";

export type ForgotPasswordTypes = Pick<ForgotPasswordDto, "email">;

export const forgotPasswordInitValues: ForgotPasswordTypes = {
  email: "",
};

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("validators.email", { ns: "translation" }))
    .required(i18n.t("validators.required", { ns: "translation" })),
});
