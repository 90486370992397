import { AxiosRequestConfig, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { API } from "../../services/API";
import { exerciseSubcategory, exerciseSubSubcategory } from "./Subcategories";

export type exerciseTemplate = {
  id: number;
  name: string;
  description: string | null;
  imageUrl: string | null;
  videoUrl: string | null;
  exerciseSubcategoryId: number;
  exerciseSubSubcategoryId: number;
  frontalPlane: boolean;
  sagittalPlane: boolean;
  transversePlane: boolean;
  instructions: string;
  repetitions: string;
  isFree: boolean;
  createdAt: Date;
  trainerId: number | null;
  sets?: number | null;
  time?: string | null;
  equipment?: string | null;
  weight?: number | null;
  tempo1?: string | null;
  tempo2?: string | null;
  tempo3?: string | null;
  tempo4?: string | null;
  repetitionMaximum?: number | null;
  restTime?: number | null;
  uuid?: string;
};

export interface AddExerciseTemplateEntry {
  name: string;
  description: string;
  exerciseSubcategoryId: number;
  frontalPlane: boolean;
  sagittalPlane: boolean;
  transversePlane: boolean;
  instructions: string;
  repetitions: string;
  sets?: number | string;
  time?: string;
  equipment?: string;
  weight?: number | string;
  tempo1?: string;
  tempo2?: string;
  tempo3?: string;
  tempo4?: string;
  repetitionMaximum?: number | string;
  restTime?: number;
  isFree: boolean;
}

export interface EditExerciseTemplateEntry {
  name: string;
  description: string | null;
  exerciseSubcategoryId: number;
  exerciseSubSubcategoryId: number | null;
  frontalPlane: boolean;
  sagittalPlane: boolean;
  transversePlane: boolean;
  instructions: string;
  repetitions: string;
  sets?: number | null;
  time?: string | null;
  equipment?: string | null;
  weight?: number | null;
  tempo1?: string | null;
  tempo2?: string | null;
  tempo3?: string | null;
  tempo4?: string | null;
  repetitionMaximum?: number | null;
  restTime: number;
}
export interface ExerciseTempalteWithSubcategory extends exerciseTemplate {
  exerciseSubcategory: exerciseSubcategory;
  exerciseSubSubcategory: exerciseSubSubcategory;
}

// export interface ExerciseTempalteWithSubcategory extends exerciseTemplate {
//   exerciseSubcategory: exerciseSubcategory;
//   exerciseSubSubcategory: exerciseSubSubcategory;
// }

// const getFreeExerciseTemplates = async () => {
//   const url = `/exercise-templates/free`;
//   const { data } = await API.get<{}, AxiosResponse<Array<exerciseTemplate>>>(url);
//   return data;
// };

// export function useGetFreeExerciseTemplates() {
//   return useQuery(["getFreeExerciseTempaltes"], getFreeExerciseTemplates);
// }

// const getExerciseTemplates = async (name: string, category: string, subcategory: string, subsubcategory: string) => {
//   const url = `/exercise-templates?name=${name}&category=${category}&subcategory=${subcategory}&subsubcategory=${subsubcategory}`;
//   const { data } = await API.get<{}, AxiosResponse<Array<ExerciseTempalteWithSubcategory>>>(url);
//   return data;
// };

// export function useGetExerciseTemplates(
//   name: string,
//   category: string = "",
//   subcategory: string = "",
//   subsubcategory: string = "",
// ) {
//   return useQuery(["getExerciseTemplates", name, category, subcategory, subsubcategory], () =>
//     getExerciseTemplates(name, category, subcategory, subsubcategory),
//   );
// }

// const getNewestExerciseTemplates = async () => {
//   const url = `/exercise-templates/newest`;
//   const { data } = await API.get<{}, AxiosResponse<Array<exerciseTemplate>>>(url);
//   return data;
// };

// export function useGetNewestExerciseTemplates() {
//   return useQuery(["getNewestExerciseTemplates"], getNewestExerciseTemplates);
// }

const getExerciseTemplate = async (id: number, programId?: number) => {
  const url = `/exercise-templates/${id}`;

  const { data } = await API.get<{}, AxiosResponse<exerciseTemplate>>(url, {
    params: { programId },
  });

  return data;
};

export function useGetExerciseTemplate(id: number) {
  return useQuery(["getExerciseTemplate", id], () => getExerciseTemplate(id), {
    enabled: !!id,
  });
}

const getExerciseTemplates = async (
  name: string,
  category: string,
  subcategory: string,
  subsubcategory: string
) => {
  const url = `/exercise-templates?name=${name}&category=${category}&subcategory=${subcategory}&subsubcategory=${subsubcategory}`;
  const { data } = await API.get<
    {},
    AxiosResponse<Array<ExerciseTempalteWithSubcategory>>
  >(url);
  return data;
};

export function useGetExerciseTemplates(
  name: string,
  category: string = "",
  subcategory: string = "",
  subsubcategory: string = ""
) {
  return useQuery(
    ["getExerciseTemplates", name, category, subcategory, subsubcategory],
    () => getExerciseTemplates(name, category, subcategory, subsubcategory)
  );
}

const addExerciseTemplate = async (reqData: AddExerciseTemplateEntry) => {
  const url = `/exercise-templates`;
  const { data } = await API.post(url, reqData);
  return data;
};

export function useAddExerciseTemplate() {
  return useMutation(addExerciseTemplate);
}

const addExerciseTemplateImage = async ({
  id,
  reqData,
  config,
}: {
  id: number;
  reqData: FormData;
  config: AxiosRequestConfig;
}) => {
  const url = `/exercise-templates/${id}/image`;
  const { data } = await API.post<{ imageUrl: string }>(url, reqData, {
    ...config,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export function useAddExerciseTemplateImage() {
  return useMutation(addExerciseTemplateImage);
}

const addExerciseTemplateVideo = async ({
  id,
  reqData,
  config,
}: {
  id: number;
  reqData: FormData;
  config: AxiosRequestConfig;
}) => {
  const url = `/exercise-templates/${id}/video`;
  const { data } = await API.post<{ imageUrl: string }>(url, reqData, {
    ...config,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export function useAddExerciseTemplateVideo() {
  return useMutation(addExerciseTemplateVideo);
}

const updateExerciseTemplate = async ({
  id,
  reqData,
}: {
  id: number;
  reqData: EditExerciseTemplateEntry;
}) => {
  const url = `/exercise-templates/${id}`;
  const { data } = await API.put(url, reqData);
  return data;
};

export function useUpdateExerciseTemplate() {
  return useMutation(updateExerciseTemplate);
}

const deleteExerciseTemplate = async ({ id }: { id: number }) => {
  const url = `/exercise-templates/${id}`;
  const { data } = await API.delete(url);
  return data;
};

export function useDeleteExerciseTemplate() {
  return useMutation(deleteExerciseTemplate);
}

// const deleteExerciseTemplateImage = async ({ id }: { id: number }) => {
//   const url = `/exercise-templates/${id}/image`;
//   const { data } = await API.delete(url);
//   return data;
// };

// export function useDeleteExerciseTemplateImage() {
//   return useMutation(deleteExerciseTemplateImage);
// }

// const deleteExerciseTemplateVideo = async ({ id }: { id: number }) => {
//   const url = `/exercise-templates/${id}/video`;
//   const { data } = await API.delete(url);
//   return data;
// };

// export function useDeleteExerciseTemplateVideo() {
//   return useMutation(deleteExerciseTemplateVideo);
// }
