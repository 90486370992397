import { loginInitValues, loginValidationSchema } from "./login.utils";
import { Typography, Col, Row } from "antd";
import { Formik, Form } from "formik";
import { LabelInput, PasswordInput, RoundButton } from "../../../components";
import { colors } from "../../../constants/colors";
import { LoginDto, useLogin } from "../../../hooks/api/Auth";
import { storage } from "../../../services/storage";
import {
  DisplaySuccessNotification,
  DisplayErrorNotification,
} from "../../../services/notificationService";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { LogoBlack } from "../../../assets/index";
import { useTranslation } from "../../../hooks/useTranslation";

export function LoginForm() {
  const { Title, Text } = Typography;
  const { t: tNotification } = useTranslation("notificationText");
  const { t } = useTranslation("loginPage");

  const navigate = useNavigate();

  const { mutate, isLoading } = useLogin();

  const login = (values: LoginDto) => {
    mutate(values, {
      onSuccess: (data) => {
        storage.setAuth(data.token, data.refreshToken);
        storage.setUser(data.user);
        DisplaySuccessNotification(`${tNotification("loggedIn")}`);
        navigate(0);
      },
      onError: (error) => {
        DisplayErrorNotification(tNotification("wrongEmailOrPassword"));
      },
    });
  };

  return (
    <Col style={{ maxWidth: "500px", padding: "60px 0 0" }}>
      <div style={{ textAlign: "center" }}>
        <LogoBlack />
      </div>
      <Title className="public-title">{t("loginTitle")}</Title>
      <Formik
        initialValues={loginInitValues}
        validationSchema={loginValidationSchema}
        onSubmit={login}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <Form>
            <LabelInput
              title={t("email")}
              onChange={handleChange("email")}
              value={values.email}
              error={errors.email && touched.email ? errors.email : undefined}
            />
            <PasswordInput
              title={t("password")}
              onChange={handleChange("password")}
              value={values.password}
              error={
                errors.password && touched.password
                  ? errors.password
                  : undefined
              }
            />
            <Text className="forgot-password-text">
              <Link to="/forgot-password">{t("forgotPassword")}</Link>
            </Text>
            <RoundButton
              title={t("login")}
              style={{ marginTop: "25px", marginBottom: "50px" }}
              onClick={() => handleSubmit()}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
      <Row style={{ paddingBottom: "60px" }}>
        <Col span={24}>
          <Text className="bold-500">{t("signupDescribe")}</Text>
        </Col>
        <Col span={14}>
          <Text className="bold-500">{t("signupDescribe2")}</Text>
        </Col>
        <Col offset={7}>
          <Text
            className="login-signup-text"
            style={{ color: colors.primary }}
            onClick={() => navigate("/register")}
          >
            {t("signup").toUpperCase()}
          </Text>
        </Col>
      </Row>
    </Col>
  );
}
