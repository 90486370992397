import { QueryLoader } from "../../../../../../../../components";
import { useGetProgramTemplate } from "../../../../../../../../hooks/api/ProgramTemplates";
import { ProgramInfo } from "./ProgramInfo";

export function ProgramLoader({ id }: { id: number }) {
  const {
    data: programTemplate,
    isLoading,
    isError,
  } = useGetProgramTemplate(id);

  return (
    <QueryLoader
      isError={isError}
      isLoading={isLoading}
      item={programTemplate}
      component={(program) => <ProgramInfo key={id} program={program} />}
    />
  );
}
