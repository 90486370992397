import { QueryLoader } from "../../../../../../../../components";
import { SubcategoryList } from "./SubcategoryList";
import { useGetMySubcategories } from "../../../../../../../../hooks/api/Subcategories";
import { useExerciseBank } from "../../../../../../../../hooks/useExerciseBank";

export function MySubcategoryList() {
  const { data: mySubcategories, isLoading, isError } = useGetMySubcategories();
  const { selectedCategory } = useExerciseBank();

  return (
    <div style={selectedCategory === "myExercise" ? { marginTop: "70px" } : {}}>
      <QueryLoader
        isError={isError}
        isLoading={isLoading}
        item={mySubcategories}
        component={(mySubcategories) => (
          <SubcategoryList subcategories={mySubcategories} />
        )}
      />
    </div>
  );
}
