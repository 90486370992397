import {
  Level,
  ProgramCategory,
} from "../../../../../../../../constants/enums";
import * as Yup from "yup";
import { i18n } from "../../../../../../../../i18n";

const t = (key: string) => i18n.t(key, { ns: "translation" });

export interface CreateProgramFormData {
  name: string;
  category: ProgramCategory;
  level: Level;
  length: number;
  equipmentNeeded: string;
  description: string;
  folderId: number;
}

export const initCreateProgramFormData: CreateProgramFormData = {
  name: "",
  category: ProgramCategory.Mobility,
  level: Level.Beginner,
  length: 30,
  equipmentNeeded: "",
  description: "",
  folderId: 0,
};

export const createProgramSchema: Yup.SchemaOf<CreateProgramFormData> =
  Yup.object()
    .shape({
      name: Yup.string()
        .min(2, t("validators.tooShrt"))
        .max(50, t("validators.tooLong"))
        .required(t("validators.required")),
      category: Yup.mixed<ProgramCategory>().oneOf(
        Object.values(ProgramCategory)
      ),
      level: Yup.mixed<Level>().oneOf(Object.values(Level)),
      length: Yup.number().typeError(t("validators.numberRequired"))
        .min(1)
        .integer(t("validators.integer"))
        .required(t("validators.required")),
      equipmentNeeded: Yup.string().nullable(),
      description: Yup.string().required(t("validators.required")),
      folderId: Yup.number().required(t("validators.required")),
    })
    .noUnknown(true)
    .strict()
    .required();
