import { exerciseSubcategory } from "../../../../../../../../hooks/api/Subcategories";
import { useExerciseBank } from "../../../../../../../../hooks/useExerciseBank";
import {
  ExerciseSubcategoryItem,
  ListLoader,
} from "../../../../../../../../components";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";

interface SubcategoryListProps {
  subcategories: exerciseSubcategory[];
}

export function SubcategoryList({ subcategories }: SubcategoryListProps) {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");

  const {
    selectedSubcategoryId,
    setSelectedSubcategoryId,
    setSelectedSubsubcategoryId,
  } = useExerciseBank();

  return (
    <ListLoader
      items={subcategories}
      renderItem={(item) => (
        <ExerciseSubcategoryItem
          key={item.id}
          name={item.name}
          description={item.description}
          isActive={selectedSubcategoryId === item.id}
          imageUrl={item.imageUrl}
          onClick={() => {
            setSelectedSubcategoryId(item.id);
            setSelectedSubsubcategoryId(null);
          }}
          subsubCategories={item.exerciseSubsubcategories}
        />
      )}
      emptyArrayText={t("noSubcategories")}
    />
  );
}
