import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useCallback,
} from "react";
import { storage } from "../services/storage";
import { AuthStatus } from "../constants/enums";
import "antd/dist/antd.css";
import { AuthUser } from "../hooks/api/Auth";
import { SpinnerLoader } from "../components";

interface ContextProps extends State {
  isPending: boolean;
  isError: boolean;
  isSuccess: boolean;
  isAuthenticated: boolean;
  logout: () => void;
}

interface State {
  status: AuthStatus;
  error: string | null;
  user: AuthUser | null;
}

export const AuthContext = createContext<ContextProps | null>(null);

export function AuthProvider({ children }: React.PropsWithChildren<unknown>) {
  const [state, setState] = useState<State>({
    status: AuthStatus.Pending,
    error: null,
    user: null,
  });

  useEffect(() => {
    const user = storage.getUser();
    setState({ status: AuthStatus.Success, error: null, user });
  }, []);

  const logout = useCallback(() => {
    setState({ status: AuthStatus.Pending, error: null, user: null });
    storage.clearStorage();
    window.location.reload();
  }, []);

  const isPending = useMemo(() => state.status === AuthStatus.Pending, [state]);
  const isError = useMemo(() => state.status === AuthStatus.Error, [state]);
  const isSuccess = useMemo(() => state.status === AuthStatus.Success, [state]);
  const isAuthenticated = useMemo(
    () => !!state.user && isSuccess,
    [state, isSuccess]
  );

  const authContext = useMemo(
    () => ({
      ...state,
      isPending,
      isError,
      isSuccess,
      isAuthenticated,
      logout,
    }),
    [isPending, isError, isSuccess, isAuthenticated, state, logout]
  );
  return (
    <AuthContext.Provider value={authContext}>
      {state.status === AuthStatus.Pending ? (
        <div
          style={{
            position: "absolute",
            top: "48%",
            left: "48%",
          }}
        >
          <SpinnerLoader />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
