import { Row, Typography } from "antd";
import { MyProgramItem } from "./MyProgramItem";
import {
  programTemplateExerciseTemplate,
  ProgramTemplateWithExerciseTemplates,
} from "../../../../../../../../hooks/api/ProgramTemplates";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { ExerciseWithParametersItem } from "../../../../../../../../components/listItems/ExerciseWithParametersItem";
import { useMyProgramsContext } from "../../../../../../../../hooks/useMyPrograms";

interface ProgramInfoProps {
  program: ProgramTemplateWithExerciseTemplates;
}

const { Text } = Typography;

let exerciseLetter = "@";
let exerciseNumber = 0;

const getExerciseName = (
  item: programTemplateExerciseTemplate,
  nextItem: programTemplateExerciseTemplate,
  index: number
) => {
  if (item.breakBefore || index === 0) {
    exerciseLetter = String.fromCharCode(exerciseLetter.charCodeAt(0) + 1);
  }

  if (nextItem?.breakBefore) {
    const exerciseName = exerciseNumber
      ? `${exerciseLetter}${exerciseNumber + 1}: ${item.name}`
      : `${exerciseLetter}: ${item.name}`;
    exerciseNumber = 0;

    return exerciseName;
  } else {
    exerciseNumber++;
  }

  if (!nextItem) {
    if (item?.breakBefore) {
      return `${exerciseLetter}: ${item.name}`;
    }
  }

  return exerciseNumber
    ? `${exerciseLetter}${exerciseNumber}: ${item.name}`
    : `${exerciseLetter}: ${item.name}`;
};

export function ProgramInfo({ program }: ProgramInfoProps) {
  const { t } = useTranslation("programsBankPage.programsBank");
  const { selectedExercise, setSelectedExercise } = useMyProgramsContext();

  exerciseLetter = "@";
  exerciseNumber = 0;

  return (
    <Row>
      <MyProgramItem key={program.id} program={program} />
      <Row style={{ marginTop: "10px", width: "100%" }}>
        <Text style={{ color: "black", marginLeft: "20px", width: "100%" }}>
          {t("exercises")}
        </Text>
        <Row style={{ flexDirection: "column", minWidth: "100%" }}>
          {program.exerciseTemplate.map((item, index) => (
            <div
              key={item.uuid}
              style={
                program.exerciseTemplate[index + 1]?.breakBefore ||
                index === program.exerciseTemplate.length - 1
                  ? { borderBottomWidth: "1px" }
                  : { borderBottomWidth: 0 }
              }
              className={`exerciseDefaultStyles
                ${
                  item.breakBefore || index === 0
                    ? "exerciseConditionTrue"
                    : "exerciseConditionFalse"
                }`}
            >
              <ExerciseWithParametersItem
                isActive={item === selectedExercise}
                onClick={() => setSelectedExercise(item)}
                exercise={{
                  ...item,
                  name: getExerciseName(
                    item,
                    program.exerciseTemplate[index + 1],
                    index
                  ),
                }}
              />
            </div>
          ))}
        </Row>
      </Row>
    </Row>
  );
}
