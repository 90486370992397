import { QueryLoader } from "../../../../../../../../components";
import { useGetSubSubcategory } from "../../../../../../../../hooks/api/SubSubcategories";
import { ExerciseSubcategoryList } from "./ExerciseSubcategoryList";

interface SubsubcategoryProps {
  id: number;
}

export function SubsubcategoryExercisesList({ id }: SubsubcategoryProps) {
  const { data: subsubcategory, isLoading, isError } = useGetSubSubcategory(id);

  return (
    <QueryLoader
      isError={isError}
      isLoading={isLoading}
      item={subsubcategory}
      component={(subsubcategory) => (
        <ExerciseSubcategoryList
          exerciseTemplates={subsubcategory.exerciseTemplates}
        />
      )}
    />
  );
}
