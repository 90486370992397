import { Row, Typography } from "antd";
import { ExistWrapper } from "../../../../../../../components";
import { useMyProgramsContext } from "../../../../../../../hooks/useMyPrograms";
import { MyProgramsFooter } from "./components/MyProgramsFooter";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ProgramsLoader } from "./components/ProgramsLoader";

const { Text } = Typography;

export function MyProgramsColumn() {
  const { t } = useTranslation("myProgramsPage");
  const { selectedFolderId } = useMyProgramsContext();

  return (
    <div className="program-bank-container">
      <ExistWrapper
        item={selectedFolderId}
        renderItem={(folderId) => (
          <>
            <ProgramsLoader id={folderId} />
            <MyProgramsFooter folderId={folderId} />
          </>
        )}
        renderNullItem={
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Text>{t("selectFolder")}</Text>
          </Row>
        }
      />
    </div>
  );
}
