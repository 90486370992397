import { Typography, Col, Row } from "antd";
import { Formik, Form } from "formik";
import { LabelInput, RoundButton } from "../../../components";
import { colors } from "../../../constants/colors";
import { useForgotPassword } from "../../../hooks/api/Auth";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import { useNavigate } from "react-router";
import { LogoBlack } from "../../../assets/index";
import { useTranslation } from "../../../hooks/useTranslation";
import {
  forgotPasswordInitValues,
  ForgotPasswordTypes,
  forgotPasswordValidationSchema,
} from "./forgotPassword.utils";
import { AccountType } from "../../../constants/enums";

export function ForgotPasswordForm() {
  const { Title, Text } = Typography;
  const { t: tNotification } = useTranslation("notificationText");
  const { t } = useTranslation("forgotPasswordPage");

  const navigate = useNavigate();

  const { mutate, isLoading } = useForgotPassword();

  const navigateToLogin = () => {
    navigate("/login");
  };

  const forgotPassword = (values: ForgotPasswordTypes) => {
    mutate(
      { ...values, role: AccountType.Trainer },
      {
        onSuccess: (data) => {
          DisplaySuccessNotification(tNotification("successForgotPassword"));
          navigateToLogin();
        },
        onError: (error) => {
          DisplayErrorNotification(tNotification("wrongEmailOrPassword"));
        },
      }
    );
  };

  return (
    <Col style={{ maxWidth: "500px", padding: "60px 0 0", width: "100%" }}>
      <div style={{ textAlign: "center" }}>
        <LogoBlack />
      </div>
      <Title className="public-title">{t("title")}</Title>
      <Formik
        initialValues={forgotPasswordInitValues}
        validationSchema={forgotPasswordValidationSchema}
        onSubmit={forgotPassword}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <Form>
            <LabelInput
              title={t("email")}
              onChange={handleChange("email")}
              value={values.email}
              error={errors.email && touched.email ? errors.email : undefined}
            />
            <RoundButton
              title={t("submit")}
              style={{ marginTop: "25px", marginBottom: "50px" }}
              onClick={() => handleSubmit()}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>

      <Row style={{ paddingBottom: "60px" }}>
        <Col span={15}>
          <Text className="bold-500">{t("loginDescribe")}</Text>
        </Col>
        <Col offset={6}>
          <Text
            className="login-signup-text"
            style={{ color: colors.primary }}
            onClick={navigateToLogin}
          >
            {t("loginWithSpace").toUpperCase()}
          </Text>
        </Col>
      </Row>
    </Col>
  );
}
