import * as React from "react";
import { ProgramBankContext } from "../containers/Logged/ExerciseBankPage/components/ProgramBank";

export const useProgramBankContext = () => {
  const context = React.useContext(ProgramBankContext);
  if (!context) {
    throw new Error("ProgramsBankContext must be within ProgramsBankContext");
  }

  return context;
};
