import { QueryLoader } from "../../../../../../../components";
import { ProgramsList } from "./components/ProgramsList";
import { useGetBoughtProgramTemplates } from "../../../../../../../hooks/api/ProgramTemplates";
import { useBoughtProgramsBank } from "../../../../../../../hooks/useBoughtProgramsBank";
import { useMemo } from "react";

export function ProgramsColumn() {
  const { selectedPackId } = useBoughtProgramsBank();

  const { data: programs, isLoading, isError } = useGetBoughtProgramTemplates();

  const filteredPrograms = useMemo(() => {
    if (programs && selectedPackId)
      return programs.filter(
        (program) => program.programSubcategoryId === selectedPackId
      );

    return [];
  }, [programs, selectedPackId]);

  return (
    <>
      <div className="program-bank-container">
        <QueryLoader
          isError={isError}
          isLoading={isLoading}
          item={filteredPrograms}
          component={(programs) => <ProgramsList programs={programs} />}
        />
      </div>
    </>
  );
}
