import "antd/dist/antd.css";
import "../../../../css/mainContainer.css";
import { Layout, Menu } from "antd";
import { useAuthState } from "../../../../hooks/useAuthState";
import { ExercisesIcon, HomeIcon, LogoutIcon } from "../../../../assets";
import { Link, useLocation } from "react-router-dom";
import { useSubscriptionState } from "../../../../hooks/useSubscriptionState";
import { useTranslation } from "../../../../hooks/useTranslation";

const { Sider } = Layout;

interface SideBarProps {
  collapsed: boolean;
}

export function SideBar({ collapsed }: SideBarProps) {
  const { t } = useTranslation("navigation");
  const { logout } = useAuthState();
  const { hasSubscription } = useSubscriptionState();
  const location = useLocation();

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <Menu
        defaultSelectedKeys={[location.pathname.substring(1)]}
        mode="inline"
        theme="light"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Menu.Item key="home" icon={<HomeIcon />}>
          <Link to={"/home"}>{t("home").toUpperCase()}</Link>
        </Menu.Item>
        {/* <Menu.Item
          key="clients"
          icon={<ClientsIcon />}
          disabled={!hasSubscription}
        >
          <Link to={"/clients"}>{t("myClients").toUpperCase()}</Link>
        </Menu.Item> */}
        {/* <Menu.Item
          key="messages"
          icon={<MessageIcon />}
          disabled={!hasSubscription}
        >
          <Link to={"/messages"}>{t("messages").toUpperCase()}</Link>
        </Menu.Item> */}
        <Menu.Item
          key="exercise-bank"
          icon={<ExercisesIcon />}
          disabled={!hasSubscription}
        >
          <Link to={"/exercise-bank"}>{t("exerciseBank").toUpperCase()}</Link>
        </Menu.Item>
        {/* <Menu.Item
          key="settings"
          icon={<SettingsIcon />}
          style={{
            position: "absolute",
            bottom: 130,
          }}
        >
          <Link to={"/settings"}>{t("settings").toUpperCase()}</Link>
        </Menu.Item>
        <Menu.Item
          key="help"
          icon={<HelpIcon />}
          style={{
            position: "absolute",
            bottom: 80,
          }}
        >
          <Link to={"/help"}>{t("help").toUpperCase()}</Link>
        </Menu.Item> */}
        <Menu.Item
          icon={<LogoutIcon />}
          style={{ marginTop: "auto" }}
          onClick={logout}
        >
          {t("logout").toUpperCase()}
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
