import { Row, Typography } from "antd";
import { ExistWrapper } from "../../../../../../../components";
import { useFreeProgramsBank } from "../../../../../../../hooks/useFreeProgramsBank";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ProgramLoader } from "./components/ProgramLoader";

const { Text } = Typography;

export function ProgramInfoColumn() {
  const { selectedProgramId } = useFreeProgramsBank();
  const { t } = useTranslation("programsBankPage.programsBank");

  return (
    <div className="program-bank-container">
      <ExistWrapper
        item={selectedProgramId}
        renderItem={(subcategoryId) => <ProgramLoader id={subcategoryId} />}
        renderNullItem={
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Text>{t("selectProgram")}</Text>
          </Row>
        }
      />
    </div>
  );
}
