import { Col, Modal, Popconfirm, Row } from "antd";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { useState } from "react";
import {
  useDeleteSubcategory,
  useEditSubcategory,
  useGetSubcategory,
} from "../../../../../../../../hooks/api/Subcategories";
import { useFormik } from "formik";
import { LabelInfoEdit } from "../../ExerciseColumn/components/LabelInfoEdit";
import { ExistWrapper, RoundButton } from "../../../../../../../../components";
import {
  EditSubcategoryFormData,
  EditSubcategorySchema,
  initEditSubcategoryFormData,
} from "./EditMySubcategory.utils";
import { useRefreshQuery } from "../../../../../../../../hooks/useRefreshQuery";
import { useExerciseBank } from "../../../../../../../../hooks/useExerciseBank";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../../../../../services/notificationService";

interface MySubcategoriesFooterProps {
  subcategoryId: number;
}

export function MySubcategoriesFooter({
  subcategoryId,
}: MySubcategoriesFooterProps) {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");
  const { t: tNotification } = useTranslation("notificationText");

  const { data: subcategory } = useGetSubcategory(subcategoryId);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { onRefresh } = useRefreshQuery(["getMySubcategories"]);
  const { setSelectedExerciseId } = useExerciseBank();

  const { mutate } = useDeleteSubcategory();

  const deleteMySubcategory = () => {
    mutate(
      { id: subcategoryId },
      {
        onSuccess: () => {
          setSelectedExerciseId(null);
          onRefresh();
          DisplaySuccessNotification(
            `${tNotification("successDeleteSubcategory")}`
          );
        },
        onError: () => {
          DisplayErrorNotification(tNotification("errorDeleteSubcategory"));
        },
      }
    );
  };

  return (
    <div>
      <ExistWrapper
        item={subcategory}
        renderItem={(subcategory) => (
          <EditModal
            subcategoryId={subcategoryId}
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            initSubcategory={initEditSubcategoryFormData(subcategory)}
          />
        )}
      />

      <Row
        style={{
          justifyContent: "center",
          backgroundColor: "white",
          position: "absolute",
          bottom: "10px",
          left: "10px",
          right: "10px",
        }}
      >
        <Col span={12} style={{ padding: "5px" }}>
          <Popconfirm
            title={t("deleteSubcategoryTitle")}
            onConfirm={deleteMySubcategory}
            okText={t("removeConfirm")}
            cancelText={t("cancel")}
            icon={false}
          >
            <RoundButton title={t("removeSubcategory")} typeColor="secondary" />
          </Popconfirm>
        </Col>
        <Col span={12} style={{ padding: "5px" }}>
          <RoundButton
            title={t("editSubcategory")}
            onClick={() => setIsModalVisible(true)}
          />
        </Col>
      </Row>
    </div>
  );
}

interface EditModalProps {
  initSubcategory: EditSubcategoryFormData;
  subcategoryId: number;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditModal = ({
  initSubcategory,
  subcategoryId,
  isModalVisible,
  setIsModalVisible,
}: EditModalProps) => {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");
  const { t: tNotification } = useTranslation("notificationText");

  const { onRefresh } = useRefreshQuery(["getMySubcategories"]);

  const { mutate, isLoading } = useEditSubcategory();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initSubcategory,
      validationSchema: EditSubcategorySchema,
      enableReinitialize: true,
      onSubmit: (values: EditSubcategoryFormData) => {
        mutate(
          { subcategoryId, reqData: values },
          {
            onSuccess: () => {
              onRefresh();
              DisplaySuccessNotification(
                `${tNotification("successUpdateSubcategory")}`
              );
              setIsModalVisible(false);
            },
            onError: () => {
              DisplayErrorNotification(tNotification("errorUpdateSubcategory"));
            },
          }
        );
      },
    });

  return (
    <Modal
      title={t("editSubcategory")}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <RoundButton
          title={t("cancel")}
          typeColor="secondary"
          onClick={() => setIsModalVisible(false)}
          style={{ width: "150px" }}
        />,
        <RoundButton
          title={t("save")}
          loading={isLoading}
          onClick={() => handleSubmit()}
          style={{ width: "150px" }}
        />,
      ]}
    >
      <LabelInfoEdit
        label={t("name")}
        value={values.name}
        onChange={handleChange("name")}
        onBlur={handleBlur("name")}
        errorText={errors.name && touched.name ? errors.name : undefined}
      />
      <LabelInfoEdit
        label={t("description")}
        value={values.description}
        onChange={handleChange("description")}
        onBlur={handleBlur("description")}
        errorText={
          errors.description && touched.description
            ? errors.description
            : undefined
        }
      />
    </Modal>
  );
};
