import { Col, Row } from "antd";
import { createContext, useEffect, useMemo, useState } from "react";
import { PacksColumn } from "./components/PacksColumn";
import { ProgramsColumn } from "./components/ProgramsColumn";
import { ProgramInfoColumn } from "./components/ProgramInfoColumn";
import { ExerciseColumn } from "./components/ExerciseColumn";
interface ContextProps {
  selectedPackId: number | null;
  setSelectedPackId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedProgramId: number | null;
  setSelectedProgramId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedExerciseId: number | null;
  setSelectedExerciseId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const BoughtProgramsBankContext = createContext<ContextProps | null>(
  null
);

export function BoughtProgramsBank() {
  const [selectedPackId, setSelectedPackId] = useState<number | null>(null);

  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    null
  );

  const [selectedExerciseId, setSelectedExerciseId] = useState<number | null>(
    null
  );

  useEffect(() => {
    setSelectedProgramId(null);
  }, [selectedPackId]);

  useEffect(() => {
    setSelectedExerciseId(null);
  }, [selectedProgramId]);

  const contextValue = useMemo(
    () => ({
      selectedPackId,
      setSelectedPackId,
      selectedProgramId,
      setSelectedProgramId,
      selectedExerciseId,
      setSelectedExerciseId,
    }),
    [
      selectedPackId,
      setSelectedPackId,
      selectedProgramId,
      setSelectedProgramId,
      selectedExerciseId,
      setSelectedExerciseId,
    ]
  );

  return (
    <BoughtProgramsBankContext.Provider value={contextValue}>
      <Row>
        <Col span={6}>
          <PacksColumn />
        </Col>
        <Col span={6}>
          <ProgramsColumn />
        </Col>
        <Col span={6}>
          <ProgramInfoColumn />
        </Col>
        <Col span={6}>
          <ExerciseColumn />
        </Col>
      </Row>
    </BoughtProgramsBankContext.Provider>
  );
}
