import * as React from "react";
import { ExerciseBankContext } from "../containers/Logged/ExerciseBankPage/components/ExerciseBank";

export const useExerciseBank = () => {
  const context = React.useContext(ExerciseBankContext);
  if (!context) {
    throw new Error(
      "ExerciseBankContext must be within ExerciseBankContextProvider"
    );
  }

  return context;
};
