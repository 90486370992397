import { AxiosResponse } from "axios";
import { useInfiniteQuery, useQuery } from "react-query";
import { Level, ProgramCategory } from "../../constants/enums";
import { API } from "../../services/API";

export type programSubcategory = {
  id: number;
  createdAt: Date;
  name: string;
  imageUrl: string | null;
  description: string | null;
  equipmentNeeded: string | null;
  length: number | null;
  level: Level;
  isDeleted: boolean;
  buyLink: string | null;
  isFreeForClients: boolean;
  isFreeForTrainers: boolean;
  category: ProgramCategory;
};

const SIZE_PER_PAGE = 40;

const getPacks = async ({
  pageParam = 0,
  category,
}: {
  pageParam: number;
  category: any;
}) => {
  const url = `/packs`;
  const { data } = await API.get<{}, AxiosResponse<Array<programSubcategory>>>(
    url,
    { params: { page: pageParam, category } }
  );
  return data;
};

export function useGetPacks(category: any) {
  return useInfiniteQuery(
    ["getPacks", category],
    ({ pageParam }) => getPacks({ pageParam, category }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === SIZE_PER_PAGE ? pages.length : false,
    }
  );
}

const getPack = async (id: number) => {
  const url = `packs/${id}`;
  const { data } = await API.get(url);
  return data;
};

export function useGetPack(id: number) {
  return useQuery(["getPack", id], () => getPack(id), { enabled: !!id });
}

const getPacksList = async (name: string) => {
  const url = `/packs/list?name=${name}`;
  const { data } = await API.get<{}, AxiosResponse<Array<programSubcategory>>>(
    url
  );
  return data;
};

export function useGetPacksList(name: string = "") {
  return useQuery(["getPacksList"], () => getPacksList(name));
}
