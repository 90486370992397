import { AxiosResponse } from "axios";
import { useQuery, useMutation } from "react-query";
import { Country, Gender } from "../../constants/enums";
import { API } from "../../services/API";

interface Trainer {
  _count: { clients: number };
  clients: 2;
  aboutMe: string;
  birthDate: Date;
  city: string;
  country: string;
  createdAt: Date;
  description: string;
  discipline: string;
  email: string;
  gender: Gender;
  id: 18;
  imageUrl: string;
  isActive: true;
  isAdmin: false;
  languages: string;
  name: string;
  surname: string;
  phone: string;
  price: number;
  subscriptions: Subscription[];
}

export interface Subscription {
  clientsCount: string;
  endAt: Date;
  id: string;
  startAt: Date;
  createdAt: Date;
  stripeSubscriptionId: string;
  price: number;
  currency: string;
}

const getTrainer = async (id: number) => {
  const url = `/trainers/${id}`;
  const { data } = await API.get<{}, AxiosResponse<Trainer>>(url);
  return data;
};

export function useGetTrainer(id: number) {
  return useQuery(["getTrainer", id], () => getTrainer(id), { enabled: !!id });
}

export interface TrainerDto {
  name: string;
  surname: string;
  email: string;
  password: string;
  confirmPassword: string | undefined;
  phone: string;
  gender: Gender;
  birthDate: Date;
  city: string;
  country: Country;
  discipline: string;
  price: number;
  languages: string;
  description: string;
  aboutMe: string;
  policy: boolean | undefined;
  termsOfUse: boolean | undefined;
  trainerRegulations: boolean | undefined;
}

const addTrainer = async (reqData: TrainerDto) => {
  const url = `/trainers`;
  const { data } = await API.post<TrainerDto>(url, reqData);
  return data;
};

export function useAddTrainer() {
  return useMutation(addTrainer);
}
