import { Input, Typography, Row, InputProps } from "antd";

interface LabelInputProps extends InputProps {
  title: string;
  error: string | undefined;
  onChange: (e: string | React.ChangeEvent<any>) => void;
}

export function LabelTextArea({
  title,
  onChange,
  value,
  error,
}: LabelInputProps) {
  const { Text } = Typography;
  const { TextArea } = Input;
  return (
    <Row className="component-row">
      <Text type="secondary">{title}</Text>
      <TextArea rows={3} onChange={onChange} value={value} />
      {error && <Text type="danger">{error}</Text>}
    </Row>
  );
}
