import React from "react";
import { BreakIcon } from "../../../../../../../../assets";
import { EditableExerciseItem } from "./EditableExerciseItem";
import { programTemplateExerciseTemplateEdit } from "../../../../../../../../hooks/useProgramExercises";
import { RoundButton } from "../../../../../../../../components";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { useMyProgramsContext } from "../../../../../../../../hooks/useMyPrograms";

interface EditableExerciseListProps {
  exercises: Array<programTemplateExerciseTemplateEdit>;
  changeBreak: (index: number) => void;
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
}

let exerciseLetter = "@";
let exerciseNumber = 0;

const getExerciseName = (
  item: programTemplateExerciseTemplateEdit,
  nextItem: programTemplateExerciseTemplateEdit,
  index: number
) => {
  if (item.breakBefore || index === 0) {
    exerciseLetter = String.fromCharCode(exerciseLetter.charCodeAt(0) + 1);
  }

  if (nextItem?.breakBefore) {
    const exerciseName = exerciseNumber
      ? `${exerciseLetter}${exerciseNumber + 1}: ${item.name}`
      : `${exerciseLetter}: ${item.name}`;
    exerciseNumber = 0;
    return exerciseName;
  } else {
    exerciseNumber++;
  }

  if (!nextItem) {
    if (item?.breakBefore) {
      return `${exerciseLetter}: ${item.name}`;
    }
  }

  return exerciseNumber
    ? `${exerciseLetter}${exerciseNumber}: ${item.name}`
    : `${exerciseLetter}: ${item.name}`;
};

export function EditableExerciseList({
  exercises,
  changeBreak,
  moveUp,
  moveDown,
}: EditableExerciseListProps) {
  const { t } = useTranslation("createProgram");
  const { setSelectedExerciseId, setSelectedEditExercise } =
    useMyProgramsContext();

  exerciseLetter = "@";
  exerciseNumber = 0;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FCFCFC",
        width: "100%",
      }}
    >
      {exercises.map((item, index) => (
        <div key={item.id}>
          {index !== 0 && (
            <div
              style={
                item.breakBefore
                  ? {
                      alignItems: "center",
                      borderColor: "#1BA8F2",
                      padding: "7px",
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      backgroundColor: "transparent",
                    }
                  : {
                      alignItems: "center",
                      padding: "4px",
                      borderLeftWidth: "2px",
                      borderRightWidth: "2px",
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderStyle: "solid",
                      borderColor: "#1BA8F2",
                      backgroundColor: "#1BA8F21A",
                    }
              }
            >
              <RoundButton
                onClick={() => changeBreak(index)}
                icon={<BreakIcon />}
                style={{
                  margin: "20px auto",
                  width: item.breakBefore ? "120px" : "95px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                title={item.breakBefore ? t("remove") : t("add")}
                typeColor={item.breakBefore ? "primary" : "secondary"}
              />
            </div>
          )}
          <div
            className={`exerciseItem ${
              item.breakBefore || index === 0
                ? "exerciseItemTopWithBreak"
                : "exerciseItemTopWithOutBreak"
            }`}
            style={
              exercises[index + 1]?.breakBefore ||
              index === exercises.length - 1
                ? { borderBottomWidth: "2px" }
                : { borderBottomWidth: 0 }
            }
          >
            <EditableExerciseItem
              key={item.uuid}
              exercise={{
                ...item,

                name: getExerciseName(item, exercises[index + 1], index),
              }}
              onClickUp={index !== 0 ? () => moveUp(index) : null}
              onClickDown={
                index !== exercises.length - 1 ? () => moveDown(index) : null
              }
              onClick={() => {
                setSelectedExerciseId(null);
                setSelectedEditExercise(item);
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}
