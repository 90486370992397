import { Result, Button } from "antd/lib";
import { useNavigate } from "react-router";
import { useTranslation } from "../../../hooks/useTranslation";

export function NotFound() {
  const navigate = useNavigate();
  const { t } = useTranslation("notFoundPage");
  return (
    <Result
      status="404"
      title="404"
      subTitle={t("title")}
      extra={
        <Button type="primary" onClick={() => navigate("/")}>
          {t("button")}
        </Button>
      }
    />
  );
}
