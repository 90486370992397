import { Col, Row } from "antd";
import { ProgramsColumn } from "./components/ProgramsColumn";
import { CategoryColumn } from "./components/CategoryColumn";
import { createContext, useMemo, useState } from "react";

interface ContextProps {
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
}

export const ProgramBankContext = createContext<ContextProps | null>(null);

export function ProgramBank() {
  const [selectedCategory, setSelectedCategory] = useState<string>("all");

  const contextValue = useMemo(
    () => ({
      selectedCategory,
      setSelectedCategory,
    }),
    [selectedCategory, setSelectedCategory]
  );

  return (
    <ProgramBankContext.Provider value={contextValue}>
      <Row>
        <Col span={6}>
          <CategoryColumn />
        </Col>
        <Col span={6}>
          <ProgramsColumn />
        </Col>
      </Row>
    </ProgramBankContext.Provider>
  );
}
