import { AddIcon } from "../../../../../../../../assets";
import { Modal, Row, Typography } from "antd";
import { colors } from "../../../../../../../../constants/colors";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { useState } from "react";
import { useAddSubcategory } from "../../../../../../../../hooks/api/Subcategories";
import { useFormik } from "formik";
import { LabelInfoEdit } from "../../ExerciseColumn/components/LabelInfoEdit";
import { RoundButton } from "../../../../../../../../components";
import {
  initAddSubcategoryFormData,
  AddSubcategorySchema,
  AddSubcategoryFormData,
} from "./AddMySubcategory.utils";
import { useRefreshQuery } from "../../../../../../../../hooks/useRefreshQuery";

const { Text } = Typography;

export function MySubcategoriesHeader() {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { onRefresh } = useRefreshQuery(["getMySubcategories"]);
  const { mutate, isLoading } = useAddSubcategory();

  const { values, errors, touched, handleChange, handleBlur, submitForm } =
    useFormik({
      initialValues: initAddSubcategoryFormData,
      validationSchema: AddSubcategorySchema,
      onSubmit: (values: AddSubcategoryFormData, { resetForm }) => {
        mutate(
          { ...values, order: 0 },
          {
            onSuccess: () => {
              onRefresh();
              resetForm();
              setIsModalVisible(false);
            },
          }
        );
      },
    });

  return (
    <div>
      <Modal
        title={t("addNewFolder")}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <RoundButton
            title={t("cancel")}
            typeColor="secondary"
            onClick={() => setIsModalVisible(false)}
            style={{ width: "150px" }}
          />,
          <RoundButton
            title={t("create")}
            loading={isLoading}
            onClick={submitForm}
            style={{ width: "150px" }}
          />,
        ]}
      >
        <LabelInfoEdit
          label={t("name")}
          value={values.name}
          onChange={handleChange("name")}
          onBlur={handleBlur("name")}
          errorText={errors.name && touched.name ? errors.name : undefined}
        />
        <LabelInfoEdit
          label={t("description")}
          value={values.description}
          onChange={handleChange("description")}
          onBlur={handleBlur("description")}
          errorText={
            errors.description && touched.description
              ? errors.description
              : undefined
          }
        />
      </Modal>
      <Row
        style={{
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: "white",
          position: "absolute",
          top: "10px",
          left: "10px",
          right: "10px",
          zIndex: "500",
          padding: "20px 20px",
        }}
        onClick={() => setIsModalVisible(true)}
      >
        <AddIcon width={40} height={40} />
        <Text
          style={{
            marginLeft: "20px",
            color: colors.primary,
            fontWeight: "bold",
          }}
        >
          {t("addFolder").toUpperCase()}
        </Text>
      </Row>
    </div>
  );
}
