import { useMemo } from "react";

import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from "react-query";

type ListPages<T> = InfiniteData<T>;

export function useFlatListPages<T>(
  listPages: ListPages<T> | undefined,
  hasNextPage: boolean | undefined,
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<T, unknown>>
) {
  const data = useMemo(() => {
    return listPages?.pages.flat() ?? [];
  }, [listPages]);

  const fetchNext = () => {
    if (hasNextPage) {
      fetchNextPage().catch(console.error);
    }
  };

  return { data, fetchNext };
}
