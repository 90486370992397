import { Col, Tabs } from "antd";
import { useTranslation } from "../../../hooks/useTranslation";
import { ExerciseBank } from "./components/ExerciseBank";
import { ProgramBank } from "./components/ProgramBank";
import { FreeProgramsBank } from "./components/FreeProgramsBank";
import { BoughtProgramsBank } from "./components/BoughtProgramsBank";
import { MyPrograms } from "./components/MyPrograms";
import "../../../css/exerciseBank.css";
import "../../../css/programBank.css";

const { TabPane } = Tabs;

export function ExerciseBankPage() {
  const { t } = useTranslation("exerciseBankPage.navigation");
  return (
    <Col style={{ marginTop: "20px" }}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={t("exerciseBank").toUpperCase()} key="1">
          <ExerciseBank />
        </TabPane>
        <TabPane tab={t("myPrograms").toUpperCase()} key="2">
          <MyPrograms />
        </TabPane>
        <TabPane tab={t("programBank").toUpperCase()} key="3">
          <ProgramBank />
        </TabPane>
        <TabPane tab={t("boughtPrograms").toUpperCase()} key="4">
          <BoughtProgramsBank />
        </TabPane>
        <TabPane tab={t("free").toUpperCase()} key="5">
          <FreeProgramsBank />
        </TabPane>
      </Tabs>
    </Col>
  );
}
