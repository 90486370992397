import { useExerciseBank } from "../../../../../../../hooks/useExerciseBank";
import { DefaultSubcategoryList } from "./components/DefaultSubcategoryList";
import { MySubcategoryList } from "./components/MySubcategoryList";
import { MySubcategoriesHeader } from "./components/MySubcategoriesHeader";
import { Conditional } from "../../../../../../../components";

export function SubcategoriesColumn() {
  const { selectedCategory } = useExerciseBank();

  return (
    <div className="exercise-bank-container">
      <Conditional
        condition={selectedCategory === "myExercise"}
        trueRender={
          <>
            <MySubcategoriesHeader />
            <MySubcategoryList />
          </>
        }
        falseRender={<DefaultSubcategoryList />}
      />
    </div>
  );
}
