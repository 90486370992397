import { Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  LabelInput,
  LabelSelect,
  QueryLoader,
} from "../../../../../../../../components";
import { exerciseCategoryOptions } from "../../../../../../../../constants/selectOptions";
import { useGetExerciseTemplates } from "../../../../../../../../hooks/api/ExerciseTemplates";
import {
  useGetMySubcategories,
  useGetSubcategories,
} from "../../../../../../../../hooks/api/Subcategories";
import { useGetSubSubcategories } from "../../../../../../../../hooks/api/SubSubcategories";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { GroupedExerciseTemplates } from "./GroupedExerciseTemplates";

export function Exercises() {
  const { t } = useTranslation("createProgram");
  const [name, setName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [subcategory, setSubcategory] = useState<string>("");
  const [subcategoriesOptions, setSubcategoriesOptions] = useState<
    { label: string; value: any }[]
  >([]);
  const [subsubcategory, setSubsubcategory] = useState<string>("");
  const [subsubcategoriesOptions, setSubsubcategoriesOptions] = useState<
    { label: string; value: any }[]
  >([]);

  const {
    data: exercises,
    isLoading,
    isError,
  } = useGetExerciseTemplates(name, category, subcategory, subsubcategory);

  const { data: subsubcategories } = useGetSubSubcategories({
    name: "",
    subcategory: subcategory,
  });
  const { data: mySubcategories } = useGetMySubcategories();

  useEffect(() => {
    setSubsubcategoriesOptions([]);

    if (subsubcategories?.length > 0) {
      const subsubcategoriesOptions = subsubcategories
        // @ts-ignore
        .sort((a, b) => (a.order < b.order ? -1 : 1))
        // @ts-ignore
        .map((subsubcategory) => ({
          label: subsubcategory.name,
          value: subsubcategory.name,
        }));

      setSubsubcategoriesOptions([
        { label: "All", value: "" },
        ...subsubcategoriesOptions,
      ]);
    }
  }, [subcategory, subsubcategories]);

  const categoryOptions = useMemo(() => {
    return [...exerciseCategoryOptions, { label: "My Exercises", value: null }];
  }, []);

  const { data: subcategories } = useGetSubcategories();

  useEffect(() => {
    if (category === null) {
      setSubcategoriesOptions([
        { label: "ALL", value: "" },
        ...[
          // @ts-ignore
          ...new Set(
            mySubcategories?.map((subcategory) => ({
              label: subcategory.name,
              value: subcategory.name,
            }))
          ),
        ],
      ]);
    } else if (category !== "") {
      setSubcategoriesOptions([
        { label: "ALL", value: "" },
        ...[
          // @ts-ignore
          ...new Set(
            subcategories
              ?.sort((a, b) => (a.order < b.order ? -1 : 1))
              ?.filter((subcategory) => subcategory.category === category)
              ?.map((subcategory) => ({
                label: subcategory.name,
                value: subcategory.name,
              }))
          ),
        ],
      ]);
    }
  }, [category, subcategories, mySubcategories]);

  return (
    <Row style={{ flexDirection: "column", margin: "10px 10px 0" }}>
      <LabelInput
        title={t("exerciseName")}
        onChange={(e) => setName(e.currentTarget.value)}
        error={undefined}
      />
      <LabelSelect
        // @ts-ignore
        items={categoryOptions}
        title={t("category")}
        error={undefined}
        onChange={(e) => setCategory(e)}
      />
      {category !== "" && (
        <LabelSelect
          items={subcategoriesOptions}
          title={t("subcategory")}
          error={undefined}
          onChange={(e) => setSubcategory(e)}
        />
      )}
      {subcategory !== "" && subsubcategoriesOptions?.length > 0 && (
        <LabelSelect
          items={subsubcategoriesOptions}
          title={t("subSubcategory")}
          error={undefined}
          onChange={(e) => setSubsubcategory(e)}
        />
      )}
      <QueryLoader
        item={exercises}
        isLoading={isLoading}
        isError={isError}
        component={(exercises) => (
          <GroupedExerciseTemplates exercises={exercises} />
        )}
      />
    </Row>
  );
}
