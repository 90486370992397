import { useSubscriptionState } from "../../hooks/useSubscriptionState";

import { Navigate, Outlet } from "react-router";

export function PrivateRoute() {
  const { hasSubscription } = useSubscriptionState();

  if (hasSubscription) {
    return <Outlet />;
  } else {
    return <Navigate to={{ pathname: "/home" }} />;
  }
}
