import { Row, Typography } from "antd";
import { ExistWrapper } from "../../../../../../../components";
import { exerciseTemplate } from "../../../../../../../hooks/api/ExerciseTemplates";
import { AdditionalParameters } from "../../../../../../../hooks/api/ProgramTemplates";
import { useMyProgramsContext } from "../../../../../../../hooks/useMyPrograms";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { Exercise } from "./components/Exercise";

const { Text } = Typography;

export function EditExerciseDetailsColumn({
  editExercise,
  removeExercise,
}: {
  editExercise: (
    exercise: exerciseTemplate,
    additionalParameters: AdditionalParameters,
    uuid: string
  ) => void;
  removeExercise: (uuid: string) => void;
}) {
  const { t } = useTranslation("programsBankPage.programsBank");
  const { selectedEditExercise } = useMyProgramsContext();

  return (
    <div className="exercise-bank-container">
      <ExistWrapper
        item={selectedEditExercise}
        renderItem={(exercise) => (
          <Exercise
            editExercise={editExercise}
            removeExercise={removeExercise}
            exercise={exercise}
          />
        )}
        renderNullItem={
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            <Text>{t("selectExercise")}</Text>
          </Row>
        }
      />
    </div>
  );
}
