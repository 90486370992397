export const minutesToHoursWithMinutes = (minutes: number): string => {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  return !m ? `${h}h` : !h ? `${m}min` : `${h}h ${m}min`;
};

export const calcualteRestTime = (restTime: number): string => {
  const secondsOfRestTime =
    restTime % 60 >= 0 && restTime % 60 <= 9
      ? `0${restTime % 60}`
      : restTime % 60;

  const minutesOfRestTime =
    parseInt((restTime / 60).toString()) >= 0 &&
    parseInt((restTime / 60).toString()) <= 9
      ? `0${parseInt((restTime / 60).toString())}`
      : parseInt((restTime / 60).toString());

  return `${minutesOfRestTime}:${secondsOfRestTime}`;
};

export const restTimeInSeconds = (restTime?: string): number => {
  return restTime
    ? parseInt(restTime.toString().slice(0, 2)) * 60 +
        parseInt(restTime.toString().slice(3, 5))
    : 0;
};
