import { Row, Typography } from "antd";
import { useExerciseBank } from "../../../../../../../hooks/useExerciseBank";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ExerciseEdit } from "./components/ExerciseEdit";
import { ExerciseAdd } from "./components/ExerciseAdd";
import { ExerciseDetails } from "./components/ExerciseDetails";
import { Conditional, ExistWrapper } from "../../../../../../../components";

const { Text } = Typography;

export function ExerciseColumn() {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");
  const {
    selectedExerciseId,
    selectedCategory,
    isCreatingNewExercise,
    selectedSubcategoryId,
  } = useExerciseBank();

  return (
    <div className="exercise-bank-container">
      <Conditional
        condition={isCreatingNewExercise}
        trueRender={
          <ExistWrapper
            item={selectedSubcategoryId}
            renderItem={(subcategoryId) => (
              <ExerciseAdd subcategoryId={subcategoryId} />
            )}
          />
        }
        falseRender={
          <ExistWrapper
            item={selectedExerciseId}
            renderItem={(selectedExerciseId) => (
              <Conditional
                condition={selectedCategory === "myExercise"}
                trueRender={<ExerciseEdit id={selectedExerciseId} />}
                falseRender={<ExerciseDetails id={selectedExerciseId} />}
              />
            )}
            renderNullItem={
              <Row style={{ justifyContent: "center", alignItems: "center" }}>
                <Text>{t("selectExercise")}</Text>
              </Row>
            }
          />
        }
      />
    </div>
  );
}
