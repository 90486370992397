import { Button } from "antd";
import { QueryLoader } from "../../../../../../../components";
import { useGetPacks } from "../../../../../../../hooks/api/Packs";
import { ProgramsList } from "./components/ProgramsList";
import { useFlatListPages } from "../../../../../../../hooks/useFlatListPage";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { useProgramBankContext } from "../../../../../../../hooks/useProgramBank";

export function ProgramsColumn() {
  const { t } = useTranslation("programsBankPage.programsBank");
  const { selectedCategory } = useProgramBankContext();

  const {
    data: programs,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
  } = useGetPacks(selectedCategory === "all" ? null : selectedCategory);

  const { data, fetchNext } = useFlatListPages(
    programs,
    hasNextPage,
    fetchNextPage
  );

  return (
    <>
      <div className="program-bank-container">
        <QueryLoader
          isError={isError}
          isLoading={isLoading}
          item={data}
          component={(programs) => <ProgramsList programs={programs} />}
        />
      </div>
      {hasNextPage && (
        <Button
          style={{
            width: "calc(100% - 18px)",
            margin: "-10px 10px 0",
            color: "#1ba8f2",
            padding: "5px 0",
            height: "45px",
          }}
          onClick={fetchNext}
        >
          {t("more")}
        </Button>
      )}
    </>
  );
}
