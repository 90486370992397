import { useExerciseBank } from "../../../../../../../hooks/useExerciseBank";
import { Row, Typography } from "antd";
import { SubcategoryExercisesList } from "./components/SubcategoryExercisesList";
import { SubsubcategoryExercisesList } from "./components/SubsubcategoryExercisesList";
import { MyExercisesHeader } from "./components/MyExercisesHeader";
import { MySubcategoriesFooter } from "./components/MySubcategoriesFooter";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { Conditional, ExistWrapper } from "../../../../../../../components";

const { Text } = Typography;

export function ExercisesColumn() {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");

  const { selectedSubcategoryId, selectedSubsubcategoryId, selectedCategory } =
    useExerciseBank();

  return (
    <div className="exercise-bank-container">
      <Conditional
        condition={!selectedSubcategoryId && !selectedSubsubcategoryId}
        trueRender={
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            <Text>{t("selectSubcategory")}</Text>
          </Row>
        }
        falseRender={
          <Conditional
            condition={selectedCategory === "myExercise"}
            trueRender={<MyExercisesHeader />}
          />
        }
      />

      <ExistWrapper
        item={selectedSubsubcategoryId}
        renderItem={(selectedSubsubcategoryId) => (
          <SubsubcategoryExercisesList id={selectedSubsubcategoryId} />
        )}
        renderNullItem={
          <ExistWrapper
            item={selectedSubcategoryId}
            renderItem={(selectedSubcategoryId) => (
              <SubcategoryExercisesList id={selectedSubcategoryId} />
            )}
          />
        }
      />
      <Conditional
        condition={
          selectedCategory === "myExercise" &&
          (!!selectedSubcategoryId || !!selectedSubsubcategoryId)
        }
        trueRender={
          <ExistWrapper
            item={selectedSubcategoryId}
            renderItem={(subcategoryId) => (
              <MySubcategoriesFooter subcategoryId={subcategoryId} />
            )}
          />
        }
      />
    </div>
  );
}
