import * as React from "react";
import { FreeProgramsBankContext } from "../containers/Logged/ExerciseBankPage/components/FreeProgramsBank";

export const useFreeProgramsBank = () => {
  const context = React.useContext(FreeProgramsBankContext);
  if (!context) {
    throw new Error(
      "FreeProgramsBankContext must be within FreeProgramsBankContextProvider"
    );
  }

  return context;
};
