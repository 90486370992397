import { AuthUser } from "../hooks/api/Auth";

interface Tokens {
  token: string | null;
  refreshToken: string | null;
}

class Storage {
  public setAuth(token: string, refreshToken: string): void {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
  }

  public getAuth(): Tokens {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");

    if (token && refreshToken) return { token, refreshToken };
    return { token: null, refreshToken: null };
  }

  public getUser(): AuthUser | null {
    const user = localStorage.getItem("user");
    if (user) return JSON.parse(user);
    else return null;
  }

  public setUser(user: AuthUser): void {
    localStorage.setItem("user", JSON.stringify(user));
  }

  public clearStorage() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
  }
}

export const storage = new Storage();
