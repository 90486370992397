import { AxiosResponse } from "axios";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { Level, ProgramCategory } from "../../constants/enums";
import { API } from "../../services/API";
import { exerciseTemplate } from "./ExerciseTemplates";

const SIZE_PER_PAGE = 40;

export type programTemplate = {
  id: number;
  name: string;
  category: ProgramCategory;
  level: Level;
  imageUrl: string | null;
  length: number;
  equipmentNeeded: string | null;
  description: string | null;
  folderId: number;
  isPublic: boolean;
  createdAt: Date;
  isDeleted: boolean;
  programSubcategoryId: number | null;
  programSubcategory?: programSubcategory;
};

export interface AddProgramTemplateEntry {
  name: string;
  category: ProgramCategory;
  level: Level;
  length: number;
  equipmentNeeded: string;
  description: string;
  folderId: number;
  price?: number;
  exercises?: Array<{ id: number; order: number; breakBefore: boolean }>;
}

export type programSubcategory = {
  id: number;
  createdAt: Date;
  name: string;
  imageUrl: string | null;
  description: string | null;
  equipmentNeeded: string | null;
  length: number | null;
  level: Level | null;
  isDeleted: boolean;
  buyLink: string | null;
  isFreeForClients: boolean;
  isFreeForTrainers: boolean;
  category: ProgramCategory;
};

export interface AdditionalParameters {
  [x: string]: any;
  repetitions?: string | null;
  sets?: number | null;
  time?: string | null;
  equipment?: string | null;
  weight?: number | null;
  tempo1?: string | null;
  tempo2?: string | null;
  tempo3?: string | null;
  tempo4?: string | null;
  repetitionMaximum?: number | null;
  restTime?: number | null;
}

export interface UpdateProgramTemplateEntry {
  name: string;
  category: ProgramCategory;
  level: Level;
  length: number;
  equipmentNeeded: string;
  description: string;
  folderId: number;
  price?: number;
  isPublic?: boolean;
  exercises?: Array<{ id: number; order: number; breakBefore: boolean }>;
  programSubcategoryId?: number | null;
}

export interface programTemplateExerciseTemplate extends exerciseTemplate {
  order: number;
  breakBefore: boolean;
  exerciseParameters: AdditionalParameters;
}

export interface ProgramTemplateWithExerciseTemplates extends programTemplate {
  exerciseTemplate: programTemplateExerciseTemplate[];
}

export const getProgramTemplate = async (id: number | null) => {
  const url = `/program-templates/${id}`;
  const { data } = await API.get<
    {},
    AxiosResponse<ProgramTemplateWithExerciseTemplates>
  >(url);
  return data;
};

export function useGetProgramTemplate(id: number | null) {
  return useQuery(["getProgramTemplate", id], () => getProgramTemplate(id), {
    enabled: !!id,
  });
}

export function useGetMutateProgramTemplate() {
  return useMutation(getProgramTemplate);
}

const getFreeProgramTemplates = async ({
  pageParam = 0,
  category,
}: {
  pageParam: number;
  category: ProgramCategory;
}) => {
  const url = `/program-templates/free`;
  const { data } = await API.get<{}, AxiosResponse<Array<programTemplate>>>(
    url,
    { params: { page: pageParam, category } }
  );
  return data;
};

export function useGetFreeProgramTemplates(category: ProgramCategory) {
  return useInfiniteQuery(
    ["getFreeProgramTemplates", category],
    ({ pageParam }) => getFreeProgramTemplates({ pageParam, category }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === SIZE_PER_PAGE ? pages.length : false,
    }
  );
}

const getBoughtProgramTemplates = async () => {
  const url = `/program-templates/bought`;
  const { data } = await API.get<{}, AxiosResponse<Array<programTemplate>>>(
    url
  );
  return data;
};

export function useGetBoughtProgramTemplates() {
  return useQuery(["getBoughtProgramTemplates"], getBoughtProgramTemplates);
}

const getProgramTemplates = async ({
  pageParam = 0,
  category,
}: {
  pageParam: number;
  category: string;
}) => {
  const url = `/program-templates`;
  const { data } = await API.get<{}, AxiosResponse<Array<programTemplate>>>(
    url,
    {
      params: {
        page: pageParam,
        category: category === "" ? null : category,
      },
    }
  );
  return data;
};

export function useGetProgramTemplates(category: string) {
  return useInfiniteQuery(
    ["getProgramTemplates", category],
    ({ pageParam }) => getProgramTemplates({ pageParam, category }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === SIZE_PER_PAGE ? pages.length : false,
    }
  );
}

const getProgramTemplatesPackId = async ({
  pageParam = 0,
  category,
  packId,
  filterSearch,
}: {
  pageParam: number;
  category: string;
  packId: number | null;
  filterSearch: string;
}) => {
  const url = `/program-templates` + (packId === null ? "" : `/pack`);

  const { data } = await API.get<{}, AxiosResponse<Array<programTemplate>>>(
    url,
    {
      params: {
        page: pageParam,
        category: category === "" ? null : category,
        packId,
        search: filterSearch,
      },
    }
  );
  return data;
};

export function useGetProgramTemplatesPackId(
  category: string,
  packId: number | null,
  filterSearch: string
) {
  return useInfiniteQuery(
    ["getProgramTemplatesPackId", category, packId, filterSearch],
    ({ pageParam }) =>
      getProgramTemplatesPackId({ pageParam, category, packId, filterSearch }),
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length === SIZE_PER_PAGE ? pages.length : false,
    }
  );
}

const addProgramTemplate = async (reqData: AddProgramTemplateEntry) => {
  const url = `/program-templates`;
  const { data } = await API.post(url, reqData);
  return data;
};

export function useAddProgramTemplate() {
  return useMutation(addProgramTemplate);
}

const updateProgramTemplate = async ({
  id,
  reqData,
}: {
  id: number;
  reqData: UpdateProgramTemplateEntry;
}) => {
  const url = `/program-templates/${id}`;
  const { data } = await API.put(url, reqData);
  return data;
};

export function useUpdateProgramTemplate() {
  return useMutation(updateProgramTemplate);
}

const addProgramTemplateImage = async ({
  id,
  reqData,
}: {
  id: number;
  reqData: FormData;
}) => {
  const url = `/program-templates/${id}/image`;
  const { data } = await API.post<{ imageUrl: string }>(url, reqData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

export function useAddProgramTemplateImage() {
  return useMutation(addProgramTemplateImage);
}

const deleteProgramTemplate = async (id: number) => {
  const url = `/program-templates/${id}`;
  const { data } = await API.delete(url);
  return data;
};

export function useDeleteProgramTemplate() {
  return useMutation(deleteProgramTemplate);
}
