import { Col, Row, Typography, Image } from "antd";
import { ArrowIcon } from "../../assets";
import { ExerciseItem } from "./ExerciseItem";
import { useExerciseBank } from "../../hooks/useExerciseBank";
import { useSubcategoryImage } from "../../hooks/useSubcategoryImage";

const { Title, Text } = Typography;

interface ExerciseSubcategoryItemProps {
  name: string;
  description: string | null;
  imageUrl?: string | null;
  isActive: boolean;
  onClick: () => void;
  subsubCategories?: subSubcategory[];
}

type subSubcategory = {
  description: string | null;
  id: number;
  imageUrl: string | null;
  name: string;
};

export function ExerciseSubcategoryItem({
  name,
  description,
  imageUrl,
  isActive,
  onClick,
  subsubCategories,
}: ExerciseSubcategoryItemProps) {
  const { setSelectedSubsubcategoryId, selectedSubsubcategoryId } =
    useExerciseBank();

  const selectedshowExerciseSubcategory =
    isActive &&
    !selectedSubsubcategoryId &&
    (subsubCategories?.length ?? 0) !== 0;

  const { image, fallback } = useSubcategoryImage(imageUrl);

  return (
    <div
      style={{
        borderColor: "black",
        borderWidth: isActive ? "1px" : "0px",
        borderStyle: "solid",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px 0",
        padding: "5px 10px",
        backgroundColor: isActive ? "#f5f5f5" : "",
        cursor: "pointer",
      }}
    >
      <Row
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          borderWidth: selectedshowExerciseSubcategory ? "1px" : "0px",
          backgroundColor: selectedshowExerciseSubcategory ? "#E4E4E4" : "",
          borderStyle: "solid",
          borderColor: "black",
          padding: "5px",
        }}
        onClick={onClick}
      >
        <Col span={6} style={{ display: "flex" }}>
          <Image
            width={60}
            height={60}
            src={image}
            preview={false}
            fallback={fallback}
          />
        </Col>
        <Col span={16}>
          <Title
            ellipsis={{
              //@ts-ignore
              rows: 1,
            }}
            level={5}
          >
            {name}
          </Title>
          <Text
            ellipsis={{
              //@ts-ignore
              rows: 2,
            }}
          >
            {description}
          </Text>
        </Col>
        <Col span={2}>
          <ArrowIcon
            style={{
              rotate: isActive && subsubCategories ? "90deg" : "0deg",
              transition: "rotate 500ms",
            }}
          />
        </Col>
      </Row>
      <div
        style={
          isActive && subsubCategories
            ? {
                overflow: "hidden",
                maxHeight: "1000px",
                transition: "max-height 1s ease-in-out",
              }
            : {
                maxHeight: "0px",
                transition: "max-height 1.5s cubic-bezier(0, 1, 0, 1)",
              }
        }
      >
        {isActive && subsubCategories ? (
          <>
            {subsubCategories.map((item) => (
              <ExerciseItem
                id={item.id}
                name={item.name}
                description={item.description}
                isActive={item.id === selectedSubsubcategoryId}
                onClick={() => setSelectedSubsubcategoryId(item.id)}
              />
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}
