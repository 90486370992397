import { Button, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  LabelInput,
  LabelSelect,
  QueryLoader,
} from "../../../../../../../components";
import { programCategoryOptionsCreateProgram } from "../../../../../../../constants/selectOptions";
import { exerciseTemplate } from "../../../../../../../hooks/api/ExerciseTemplates";
import {
  Folder,
  useGetFolder,
  useGetFolders,
} from "../../../../../../../hooks/api/Folders";
import {
  AdditionalParameters,
  programTemplate,
  programTemplateExerciseTemplate,
  useGetProgramTemplatesPackId,
} from "../../../../../../../hooks/api/ProgramTemplates";
import { useGetPacksList } from "../../../../../../../hooks/api/Packs";
import { useFlatListPages } from "../../../../../../../hooks/useFlatListPage";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ProgramsList } from "./components/ProgramsList";

interface PropsCopyExerciseColumn {
  addExercise: (
    exercise: exerciseTemplate,
    additionalParameters?: AdditionalParameters | undefined
  ) => void;
  copyExercises: (exerciseTemplates: programTemplateExerciseTemplate[]) => void;
}

export function CopyExerciseColumn({
  addExercise,
  copyExercises,
}: PropsCopyExerciseColumn) {
  const { t } = useTranslation("createProgram");
  const [filterCategory, setFilterCategory] = useState<string>("");
  const [filterSearch, setFilterSearch] = useState<string>("");
  const [isMyProgramsActive, setIsMyProgramsActive] = useState<boolean>(false);
  const [folderId, setFolderId] = useState<number>(0);
  const [filteredData, setFilteredData] = useState<programTemplate[]>();
  const [packId, setPackId] = useState<number | null>(null);

  const { data: packList } = useGetPacksList();

  const packListOptions = useMemo(() => {
    const packs =
      packList?.map((pack) => ({
        label: pack.name,
        value: pack.id.toString(),
      })) ?? [];
    return [
      { label: "All", value: null },
      ...packs.sort((a, b) =>
        a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase() ? 1 : -1
      ),
    ];
  }, [packList]);

  const {
    data: programsPages,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
  } = useGetProgramTemplatesPackId(filterCategory, packId, filterSearch);

  const { data: folders } = useGetFolders();
  const { data: folder, isLoading: isLoadingFolder } = useGetFolder(folderId);

  const { data, fetchNext } = useFlatListPages(
    programsPages,
    hasNextPage,
    fetchNextPage
  );

  const foldersOptions = useMemo(
    () =>
      folders
        ?.map((folder: Folder) => ({ label: folder.name, value: folder.id }))
        .sort((a: any, b: any) => (a.label < b.label ? -1 : 1)) ?? [],
    [folders]
  );

  const checkFilterCategory = (category: string) => {
    if (category !== "MyPrograms") {
      setIsMyProgramsActive(false);
      setFilterCategory(category);
    } else {
      setIsMyProgramsActive(true);
    }
  };

  useEffect(() => {
    if (isMyProgramsActive) setFolderId(folders[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMyProgramsActive]);

  useEffect(() => {
    if (!isMyProgramsActive && !isLoading) {
      setFilteredData(data);
    } else if (isMyProgramsActive && !isLoadingFolder) {
      setFilteredData(folder?.programTemplates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    isLoadingFolder,
    isMyProgramsActive,
    filterSearch,
    filterCategory,
    folderId,
    packId,
  ]);

  return (
    <>
      <div
        style={hasNextPage ? { height: "70vh" } : { height: "75vh" }}
        className="program-bank-container"
      >
        <Row style={{ flexDirection: "column", margin: "10px 10px" }}>
          <LabelInput
            title={t("programName")}
            error={undefined}
            onChange={(e) => setFilterSearch(e.currentTarget.value)}
          />
          <LabelSelect
            title={t("category")}
            error={undefined}
            items={[
              { label: "All", value: "" },
              ...programCategoryOptionsCreateProgram,
              { label: "My Programs", value: "MyPrograms" },
            ]}
            onChange={(category) => checkFilterCategory(category)}
          />
          <LabelSelect
            title={t("pack")}
            error={undefined}
            items={packListOptions}
            onChange={(id) => setPackId(id ? Number(id) : null)}
          />
          {isMyProgramsActive && (
            <LabelSelect
              title={t("folder")}
              items={foldersOptions}
              error={undefined}
              onChange={(id) => setFolderId(Number(id))}
            />
          )}
        </Row>
        <QueryLoader
          isError={isError}
          isLoading={isLoading}
          item={filteredData}
          component={(programs) => (
            <ProgramsList
              copyExercises={copyExercises}
              addExercise={addExercise}
              programs={programs}
            />
          )}
        />
      </div>
      {hasNextPage && (
        <Button
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "calc(100% - 18px)",
            margin: "0 8px 9px",
            color: "#1ba8f2",
            height: "5vh",
            zIndex: 2,
          }}
          onClick={fetchNext}
        >
          more
        </Button>
      )}
    </>
  );
}
