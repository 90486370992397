import React from "react";
import { Conditional } from "./Conditional";
import { QueryLoader } from "./QueryLoader";
import { Row, Typography } from "antd";
import { SpinnerLoader } from "./SpinnerLoader";
const { Text } = Typography;

interface ListLoaderProps<T> {
  items: T[];
  renderItem: (item: T) => JSX.Element;
  emptyArrayText: string;
  isLoading?: boolean;
  isError?: boolean;
  isFetching?: boolean;
}

export function ListLoader<T>(props: ListLoaderProps<T>) {
  const {
    emptyArrayText,
    isLoading = false,
    isError = false,
    isFetching = false,
    items,
    renderItem,
  } = props;

  return (
    <>
      <QueryLoader
        isLoading={isLoading}
        isError={isError}
        item={items}
        component={(items) => (
          <Conditional
            condition={items.length > 0}
            trueRender={<>{items.map(renderItem)}</>}
            falseRender={
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <Text>{emptyArrayText}</Text>
              </Row>
            }
          />
        )}
      />
      <Conditional condition={isFetching} trueRender={<SpinnerLoader />} />
    </>
  );
}
