import { useGetSubcategory } from "../../../../../../../../hooks/api/Subcategories";
import { QueryLoader } from "../../../../../../../../components";
import { ExerciseSubcategoryList } from "./ExerciseSubcategoryList";

interface SubcategoryProps {
  id: number;
}

export function SubcategoryExercisesList({ id }: SubcategoryProps) {
  const { data: subcategory, isLoading, isError } = useGetSubcategory(id);

  return (
    <QueryLoader
      isError={isError}
      isLoading={isLoading}
      item={subcategory}
      component={(subcategory) => (
        <ExerciseSubcategoryList
          exerciseTemplates={subcategory.exerciseTemplates}
        />
      )}
    />
  );
}
