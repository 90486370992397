import { Row, Image, Typography, Col } from "antd";
import { RoundButton } from "../buttons/RoundButton";
import { programSubcategory } from "../../hooks/api/Packs";
import { useTranslation } from "../../hooks/useTranslation";
import { useGenericProgramImage } from "../../hooks/useGenericProgramImage";
import { useLevel } from "../../hooks/useLevel";
import { ClockIcon, LevelIcon } from "../../assets";
import { minutesToHoursWithMinutes } from "../../utils/timeConverter";

interface ProgramItemProps {
  program: programSubcategory;
}

const { Title, Text } = Typography;

export function ProgramItem({ program }: ProgramItemProps) {
  const {
    imageUrl,
    category,
    buyLink,
    equipmentNeeded,
    name,
    description,
    length,
  } = program;
  const { t } = useTranslation("programsBankPage.programsBank");
  const { getImage } = useGenericProgramImage();
  const level = useLevel(program.level);

  return (
    <Row style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(255,255,255,0) 100%)",
          zIndex: 1,
        }}
      />
      <Image
        style={{ height: "100%", position: "relative" }}
        src={getImage(imageUrl, category)}
        preview={false}
      />

      <RoundButton
        title={t("seeMore")}
        onClick={() => buyLink && window.open(buyLink, "_blank")}
        style={{
          position: "absolute",
          top: "6%",
          right: "3%",
          zIndex: 1,
          width: "100px",
        }}
      />
      <Row
        style={{
          position: "absolute",
          bottom: 0,
          left: "5%",
          flexDirection: "column",
          width: "95%",
          zIndex: 2,
        }}
      >
        <Title
          ellipsis={{ rows: 2 }}
          style={{ color: "white", marginBottom: 0 }}
          level={5}
        >
          {name}
        </Title>
        <Text strong style={{ color: "white", fontSize: "9px" }}>
          {`EQ: ${
            equipmentNeeded ? equipmentNeeded.toUpperCase() : t("noEqNeeded")
          }`}
        </Text>
        <Title
          ellipsis={{ rows: 2 }}
          style={{
            color: "white",
            fontWeight: "normal",
            fontSize: "10px",
            marginTop: "5px",
          }}
        >
          {description}
        </Title>
        <Row style={{ height: "35px" }}>
          <Col style={{ display: "flex", alignItems: "center" }} span={12}>
            <LevelIcon color="white" />
            <Text style={{ color: "white", marginLeft: "20px" }}>{level}</Text>
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }} span={12}>
            <ClockIcon color="white" />
            <Text style={{ color: "white", marginLeft: "20px" }}>
              {length && minutesToHoursWithMinutes(length)}
            </Text>
          </Col>
        </Row>
      </Row>
    </Row>
  );
}
