import * as React from "react";
import { MyProgramsContext } from "../containers/Logged/ExerciseBankPage/components/MyPrograms";

export const useMyProgramsContext = () => {
  const context = React.useContext(MyProgramsContext);
  if (!context) {
    throw new Error(
      "MyProgramsContext must be within MyProgramsContext"
    );
  }

  return context;
};
