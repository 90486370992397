import { Row, Typography } from "antd";
import { ExistWrapper } from "../../../../../../../components";
import { useFreeProgramsBank } from "../../../../../../../hooks/useFreeProgramsBank";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ExerciseDetails } from "./components/ExerciseDetails";

const { Text } = Typography;

export function ExerciseColumn() {
  const { selectedExercise } = useFreeProgramsBank();
  const { t } = useTranslation("programsBankPage.programsBank");
  return (
    <div className="exercise-bank-container">
      <ExistWrapper
        item={selectedExercise}
        renderItem={(exercise) => <ExerciseDetails exercise={exercise} />}
        renderNullItem={
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            <Text>{t("selectExercise")}</Text>
          </Row>
        }
      />
    </div>
  );
}
