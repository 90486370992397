import * as Yup from "yup";
import { i18n } from "../../../../../../i18n";

export interface AddFolderTypes {
  name: string;
}

export const initAddFolderValues = {
  name: "",
};

export const AddFolderSchema: Yup.SchemaOf<AddFolderTypes> = Yup.object().shape(
  {
    name: Yup.string().required(
      i18n.t("validators.required", { ns: "translation" })
    ),
  }
);
