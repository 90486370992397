import React from "react";
import { SpinnerLoader } from "./SpinnerLoader";
import { useTranslation } from "../hooks/useTranslation";
import { Typography } from "antd";
import { ExistWrapper } from "./ExistWrapper";
const { Text } = Typography;

interface QueryLoaderProps<T> {
  isLoading?: boolean;
  isError?: boolean;
  item?: T | null;
  component?: (item: T) => JSX.Element;
  renderNullItem?: JSX.Element | null;
}

export function QueryLoader<T>({
  isLoading = false,
  isError = false,
  component,
  item,
  renderNullItem = null,
}: QueryLoaderProps<T>): JSX.Element | null {
  const { t } = useTranslation();

  if (isLoading)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SpinnerLoader />
      </div>
    );

  if (isError)
    return (
      <div>
        <Text>{t("somethingWentWrong")}</Text>
      </div>
    );

  if (!!component) {
    return ExistWrapper({ item, renderItem: component, renderNullItem });
  }

  return renderNullItem;
}
