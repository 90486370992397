import { Col, Row, Typography, Image } from "antd";
import { ArrowIcon } from "../../assets";
import { useExerciseImage } from "../../hooks/useExerciseImage";

const { Title, Text } = Typography;

interface ExerciseItemProps {
  id: number;
  name: string;
  description: string | null;
  imageUrl?: string | null;
  isActive: boolean;
  onClick: () => void;
}

export function ExerciseItem({
  id,
  name,
  description,
  imageUrl,
  isActive,
  onClick,
}: ExerciseItemProps) {
  const { image, fallback } = useExerciseImage(id, imageUrl);
  return (
    <Row
      style={{
        borderColor: "black",
        borderWidth: isActive ? "1px" : "0px",
        borderStyle: "solid",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px 0",
        padding: "5px 10px",
        backgroundColor: isActive ? "#E4E4E4" : "",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Col span={6} style={{ display: "flex", alignItems: "center" }}>
        <Image
          width={60}
          height={60}
          src={image}
          preview={false}
          fallback={fallback}
        />
      </Col>
      <Col span={16}>
        <Title
          ellipsis={{
            rows: 1,
          }}
          level={5}
        >
          {name}
        </Title>
        <Text
          ellipsis={{
            //@ts-ignore
            rows: 2,
          }}
        >
          {description}
        </Text>
      </Col>
      <Col span={2}>
        <ArrowIcon />
      </Col>
    </Row>
  );
}
