import { Button, ButtonProps } from "antd";
import { colors } from "../../constants/colors";

export interface RoundButtonProps extends ButtonProps {
  title: string;
  typeColor?: "primary" | "secondary";
}

export function RoundButton({
  title,
  style,
  onClick,
  loading,
  typeColor = "primary",
  icon,
}: RoundButtonProps) {
  return (
    <Button
      style={{
        backgroundColor: typeColor === "primary" ? colors.primary : "white",
        borderColor: colors.primary,
        color: typeColor === "primary" ? "white" : colors.primary,
        width: "100%",
        ...style,
      }}
      type="primary"
      shape="round"
      htmlType="submit"
      loading={loading}
      onClick={onClick}
      icon={icon}
    >
      {title.toUpperCase()}
    </Button>
  );
}
