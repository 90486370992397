import { Col, Row, Typography } from "antd";
import { QueryLoader } from "../../../../components";
import { useGetTrainer } from "../../../../hooks/api/Trainers";
import { useAuthState } from "../../../../hooks/useAuthState";
import { useTranslation } from "../../../../hooks/useTranslation";

const { Title } = Typography;

export function Profile() {
  const { t } = useTranslation("loginPage");
  const { user } = useAuthState();
  const { data: trainer, isLoading, isError } = useGetTrainer(user?.id ?? 0);

  return (
    <QueryLoader
      item={trainer}
      isError={isError}
      isLoading={isLoading}
      component={(realTrainer) => (
        <Row gutter={[16, 16]}>
          <Col span={10}>{t("name")}</Col>
          <Col span={14}>{`${realTrainer.name} ${realTrainer.surname}`}</Col>
          <Col span={10}>{t("address")}</Col>
          <Col span={14}>{`${realTrainer.city}, ${realTrainer.country}`}</Col>
          <Col span={10}>{t("languages")}</Col>
          <Col span={14}>{realTrainer.languages}</Col>
          <Col span={10}>{t("discipline")}</Col>
          <Col span={14}>{realTrainer.discipline}</Col>
          <Col span={10}>{t("price")}</Col>
          <Col span={14}>{`from ${realTrainer.price} SEK/h`}</Col>
          <Col span={10}>{t("description")}</Col>
          <Col span={14}>
            <Title
              style={{ fontSize: "14px", fontWeight: "normal" }}
              ellipsis={{ rows: 5 }}
            >
              {realTrainer.description}
            </Title>
          </Col>
          <Col span={10}>{t("aboutMe")}</Col>
          <Col span={14}>
            <Title
              style={{ fontSize: "14px", fontWeight: "normal" }}
              ellipsis={{ rows: 5 }}
            >
              {realTrainer.aboutMe}
            </Title>
          </Col>
        </Row>
      )}
    />
  );
}
