import { useMemo } from "react";
import { Row } from "antd";
import { ExerciseCategoryItem } from "../../../../../../../components";
import { useFreeProgramsBank } from "../../../../../../../hooks/useFreeProgramsBank";
import { ProgramCategory } from "../../../../../../../constants/enums";
import { useTranslation } from "../../../../../../../hooks/useTranslation";

export function CategoriesColumn() {
  const { selectedCategory, setSelectedCategory } = useFreeProgramsBank();
  const { t } = useTranslation("programCategories");

  const options = useMemo((): ProgramCategory[] => {
    return [...Object.values(ProgramCategory)];
  }, []);

  return (
    <Row className="exercise-bank-categories-container">
      {options.map((item) => (
        <ExerciseCategoryItem
          key={item}
          name={t(item)}
          isActive={selectedCategory === item}
          onClick={() => {
            setSelectedCategory(item);
          }}
        />
      ))}
    </Row>
  );
}
