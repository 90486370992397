import { Row, Typography } from "antd";
import { ExistWrapper } from "../../../../../../../components";
import { useMyProgramsContext } from "../../../../../../../hooks/useMyPrograms";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ProgramLoader } from "./components/ProgramLoader";

const { Text } = Typography;

export function MyProgramInfoColumn() {
  const { selectedProgramId } = useMyProgramsContext();
  const { t } = useTranslation("programsBankPage.programsBank");

  return (
    <div className="program-bank-container">
      <ExistWrapper
        item={selectedProgramId}
        renderItem={(programId) => <ProgramLoader id={programId} />}
        renderNullItem={
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Text>{t("selectProgram")}</Text>
          </Row>
        }
      />
    </div>
  );
}
