import { useGetStatue } from "../../../hooks/api/Statutes";
import { useParams } from "react-router";
import { DisplayErrorNotification } from "../../../services/notificationService";
import { Spin } from "antd";
import { useTranslation } from "../../../hooks/useTranslation";

export function Statutes() {
  const { t } = useTranslation("notificationText");
  const { statute } = useParams();

  const { data, isLoading, isError } = useGetStatue(statute ?? "");

  return (
    <>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <div
          style={{ padding: "0 10px" }}
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      )}
      {isError && DisplayErrorNotification(t("errorLoadingStatutes"))}
    </>
  );
}
