import { Gender, Country, Level, ProgramCategory, ExerciseCategory } from "./enums";
import { i18n } from "../i18n";

const t = (key: string) => i18n.t(key, { ns: "translation" })

export const genderOptions = [
  { label: t("selectOptions.gender.male"), value: Gender.Male },
  { label: t("selectOptions.gender.female"), value: Gender.Female },
  { label: t("selectOptions.gender.other"), value: Gender.Helicopter },
];

export const programCategoryOptionsCreateProgram = [
  { label: t("selectOptions.programCategory.mobility"), value: ProgramCategory.Mobility },
  { label: t("selectOptions.programCategory.strength"), value: ProgramCategory.Strength },
  { label: t("selectOptions.programCategory.endurance"), value: ProgramCategory.Endurance },
  { label: t("selectOptions.programCategory.speed"), value: ProgramCategory.Speed },
  { label: t("selectOptions.programCategory.coordinationBalance"), value: ProgramCategory.CoordinationBalance },
  { label: t("selectOptions.programCategory.workingCapacity"), value: ProgramCategory.WorkingCapacity },
  { label: t("selectOptions.programCategory.athleticCapacity"), value: ProgramCategory.AthleticCapacity },
  { label: t("selectOptions.programCategory.specificGoal"), value: ProgramCategory.SpecificGoal },
  { label: t("selectOptions.programCategory.sportsMedicine"), value: ProgramCategory.SportsMedicine },
];

export const exerciseCategoryOptions = [
  { label: t("selectOptions.exerciseCategory.all"), value: "" },
  { label: t("selectOptions.exerciseCategory.mobility"), value: ExerciseCategory.Mobility },
  { label: t("selectOptions.exerciseCategory.strength"), value: ExerciseCategory.Strength },
  { label: t("selectOptions.exerciseCategory.endurance"), value: ExerciseCategory.Endurance },
  { label: t("selectOptions.exerciseCategory.speed"), value: ExerciseCategory.Speed },
  { label: t("selectOptions.exerciseCategory.coordinationBalance"), value: ExerciseCategory.CoordinationBalance },
  { label: t("selectOptions.exerciseCategory.sportsMedicine"), value: ExerciseCategory.SportsMedicine },
];

export const levelOptions = [
  { label: t("selectOptions.level.beginner"), value: Level.Beginner },
  { label: t("selectOptions.level.intermediate"), value: Level.Intermediate },
  { label: t("selectOptions.level.advanced"), value: Level.Advanced },
];

export const countryOptions = [
  { label: "Albania", value: Country.Albania },
  { label: "Andorra", value: Country.Andorra },
  { label: "Armenia", value: Country.Armenia },
  { label: "Austria", value: Country.Austria },
  { label: "Azerbaijan", value: Country.Azerbaijan },
  { label: "Belarus", value: Country.Belarus },
  { label: "Belgium", value: Country.Belgium },
  { label: "Bosnia and Herzegovina", value: Country.BosniaAndHerzegovina },
  { label: "Bulgaria", value: Country.Bulgaria },
  { label: "Croatia", value: Country.Croatia },
  { label: "Cyprus", value: Country.Cyprus },
  { label: "Czech Republic", value: Country.CzechRepublic },
  { label: "Denmark", value: Country.Denmark },
  { label: "Estonia", value: Country.Estonia },
  { label: "Finland", value: Country.Finland },
  { label: "France", value: Country.France },
  { label: "Georgia", value: Country.Georgia },
  { label: "Germany", value: Country.Germany },
  { label: "Greece", value: Country.Greece },
  { label: "Hungary", value: Country.Hungary },
  { label: "Iceland", value: Country.Iceland },
  { label: "Ireland", value: Country.Ireland },
  { label: "Italy", value: Country.Italy },
  { label: "Kazakhstan", value: Country.Kazakhstan },
  { label: "Kosovo", value: Country.Kosovo },
  { label: "Latvia", value: Country.Latvia },
  { label: "Liechtenstein", value: Country.Liechtenstein },
  { label: "Lithuania", value: Country.Lithuania },
  { label: "Luxembourg", value: Country.Luxembourg },
  { label: "Malta", value: Country.Malta },
  { label: "Moldova", value: Country.Moldova },
  { label: "Monaco", value: Country.Monaco },
  { label: "Montenegro", value: Country.Montenegro },
  { label: "Netherlands", value: Country.Netherlands },
  { label: "North Macedonia", value: Country.NorthMacedonia },
  { label: "Norway", value: Country.Norway },
  { label: "Poland", value: Country.Poland },
  { label: "Portugal", value: Country.Portugal },
  { label: "Romania", value: Country.Romania },
  { label: "Russia", value: Country.Russia },
  { label: "San Marino", value: Country.SanMarino },
  { label: "Serbia", value: Country.Serbia },
  { label: "Slovakia", value: Country.Slovakia },
  { label: "Slovenia", value: Country.Slovenia },
  { label: "Spain", value: Country.Spain },
  { label: "Sweden", value: Country.Sweden },
  { label: "Switzerland", value: Country.Switzerland },
  { label: "Turkey", value: Country.Turkey },
  { label: "Ukraine", value: Country.Ukraine },
  { label: "United Kingdom", value: Country.UnitedKingdom },
  { label: "Vatican City", value: Country.VaticanCity },
];
