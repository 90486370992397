import { Row, Typography } from "antd";
import { useState } from "react";
import { AddIcon } from "../../../../../../assets";
import { RoundButton } from "../../../../../../components";
import { AddFolderModal } from "../components/AddFolderModal";
import { useTranslation } from "../../../../../../hooks/useTranslation";
import { useMyProgramsContext } from "../../../../../../hooks/useMyPrograms";

const { Text } = Typography;

export function MyProgramsHeader() {
  const { t } = useTranslation("myProgramsPage");

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { editingProgramId, isCreatingNewProgram, setIsCreatingNewProgram } =
    useMyProgramsContext();

  const createProgram = () => {
    if (!isCreatingNewProgram && !editingProgramId)
      setIsCreatingNewProgram(true);
  };

  return (
    <Row style={{ marginBottom: "10px" }}>
      <Row
        style={{ alignItems: "center", marginRight: "55px", cursor: "pointer" }}
        onClick={() => setIsModalOpen(true)}
      >
        <AddIcon width={40} height={40} />
        <Text
          style={{
            color: "#1ba8f2",
            fontWeight: "bold",
            marginLeft: "15px",
          }}
        >
          {t("addFolder").toUpperCase()}
        </Text>
      </Row>
      <RoundButton
        title={t("createProgram")}
        typeColor="primary"
        onClick={() => createProgram()}
        style={
          isCreatingNewProgram || editingProgramId
            ? {
                width: "180px",
                height: "40px",
                backgroundColor: "lightGray",
                borderColor: "lightGray",
              }
            : { width: "180px", height: "40px" }
        }
      />
      <AddFolderModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Row>
  );
}
