import { Row, Typography } from "antd";
import { ExistWrapper } from "../../../../../../../components";
import { exerciseTemplate } from "../../../../../../../hooks/api/ExerciseTemplates";
import { AdditionalParameters } from "../../../../../../../hooks/api/ProgramTemplates";
import { useMyProgramsContext } from "../../../../../../../hooks/useMyPrograms";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ExerciseLoader } from "./components/ExerciseLoader";

const { Text } = Typography;

export function ExerciseDetailsColumn({
  addExercise,
}: {
  addExercise: (
    exercise: exerciseTemplate,
    additionalParameters?: AdditionalParameters | undefined
  ) => void;
}) {
  const { t } = useTranslation("programsBankPage.programsBank");
  const { selectedExerciseId } = useMyProgramsContext();

  return (
    <div className="exercise-bank-container">
      <ExistWrapper
        item={selectedExerciseId}
        renderItem={(id) => (
          <ExerciseLoader addExercise={addExercise} exerciseId={id} />
        )}
        renderNullItem={
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            <Text>{t("selectExercise")}</Text>
          </Row>
        }
      />
    </div>
  );
}
