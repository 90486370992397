import { QueryLoader } from "../../../../../../../components";
import { ProgramsList } from "./components/ProgramsList";
import { useGetFreeProgramTemplates } from "../../../../../../../hooks/api/ProgramTemplates";
import { useFreeProgramsBank } from "../../../../../../../hooks/useFreeProgramsBank";
import { useFlatListPages } from "../../../../../../../hooks/useFlatListPage";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { Button } from "antd";

export function ProgramsColumn() {
  const { t } = useTranslation("programsBankPage.programsBank");
  const { selectedCategory } = useFreeProgramsBank();

  const {
    data: programsTemplatePages,
    isLoading,
    isError,
    hasNextPage,
    fetchNextPage,
  } = useGetFreeProgramTemplates(selectedCategory);

  const { data, fetchNext } = useFlatListPages(
    programsTemplatePages,
    hasNextPage,
    fetchNextPage
  );

  return (
    <>
      <div className="program-bank-container">
        <QueryLoader
          isError={isError}
          isLoading={isLoading}
          item={data}
          component={(programs) => <ProgramsList programs={programs} />}
        />
      </div>
      {hasNextPage && (
        <Button
          style={{
            width: "calc(100% - 18px)",
            margin: "-10px 10px 0",
            color: "#1ba8f2",
            padding: "5px 0",
            height: "45px",
          }}
          onClick={fetchNext}
        >
          {t("more")}
        </Button>
      )}
    </>
  );
}
