import React, { useState } from "react";
import "antd/dist/antd.css";
import "../../../css/mainContainer.css";
import { Layout } from "antd";
import { TopBar } from "./components/TopBar";
import { SideBar } from "./components/SideBar";

const { Content } = Layout;

export function MainContainer({ children }: React.PropsWithChildren<unknown>) {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  return (
    <Layout className="main-layout">
      <TopBar toggleCollapsed={toggleCollapsed} />
      <Layout>
        <SideBar collapsed={collapsed} />
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
}
