import { Row } from "antd";
import { QueryLoader } from "../../../../../../../components";
import { useGetFolders } from "../../../../../../../hooks/api/Folders";
import { FoldersList } from "./components/FoldersList";

export function MyFoldersColumn() {
  const { data, isLoading, isError } = useGetFolders();

  return (
    <Row className="exercise-bank-categories-container">
      <QueryLoader
        isError={isError}
        isLoading={isLoading}
        item={data}
        component={(folders) => <FoldersList folders={folders} />}
      />
    </Row>
  );
}
