import { Row, Image, Typography, Col } from "antd";
import { useTranslation } from "../../hooks/useTranslation";
import { useGenericProgramImage } from "../../hooks/useGenericProgramImage";
import { useLevel } from "../../hooks/useLevel";
import { ClockIcon, LevelIcon } from "../../assets";
import { minutesToHoursWithMinutes } from "../../utils/timeConverter";
import { programTemplate } from "../../hooks/api/ProgramTemplates";
import { useBoughtProgramsBank } from "../../hooks/useBoughtProgramsBank";

interface BoughtProgramItemProps {
  program: programTemplate;
  clickable?: boolean;
  isAssignable?: boolean;
}

const { Title, Text } = Typography;

export function BoughtProgramItem({
  program,
  clickable,
  isAssignable = true,
}: BoughtProgramItemProps) {
  const { imageUrl, category, equipmentNeeded, name, description, length, id } =
    program;
  const { setSelectedProgramId } = useBoughtProgramsBank();
  const { t } = useTranslation("programsBankPage.programsBank");
  const { getImage } = useGenericProgramImage();
  const level = useLevel(program.level);

  return (
    <Row
      onClick={() => clickable && setSelectedProgramId(id)}
      style={
        clickable
          ? { position: "relative", cursor: "pointer" }
          : { position: "relative" }
      }
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background:
            "linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(255,255,255,0) 100%)",
          zIndex: 1,
        }}
      />
      <Image
        style={{ height: "100%", position: "relative" }}
        src={getImage(imageUrl, category)}
        preview={false}
      />
      {/* {isAssignable && (
        <div
          style={{
            position: "absolute",
            top: "6%",
            right: "3%",
            width: "30%",
            zIndex: 1,
            minWidth: "100px",
          }}
        >
          <RoundButton
            title={t("setTo")}
            icon={<ClientIcon width={"18px"} />}
            style={{
              marginBottom: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              flexDirection: "row-reverse",
            }}
          />
          <RoundButton
            title={t("setTo")}
            icon={<ClientsIcon width={"18px"} />}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              flexDirection: "row-reverse",
            }}
          />
        </div>
      )} */}
      <Row
        style={{
          position: "absolute",
          bottom: 0,
          left: "5%",
          flexDirection: "column",
          width: "95%",
          zIndex: 2,
        }}
      >
        <Title
          ellipsis={{ rows: 2 }}
          style={{ color: "white", marginBottom: 0 }}
          level={5}
        >
          {name}
        </Title>
        <Text strong style={{ color: "white", fontSize: "9px" }}>
          {`EQ: ${
            equipmentNeeded ? equipmentNeeded.toUpperCase() : t("noEqNeeded")
          }`}
        </Text>
        <Title
          ellipsis={{ rows: 2 }}
          style={{
            color: "white",
            fontWeight: "normal",
            fontSize: "10px",
            marginTop: "5px",
          }}
        >
          {description}
        </Title>
        <Row style={{ height: "35px" }}>
          <Col style={{ display: "flex", alignItems: "center" }} span={12}>
            <LevelIcon color="white" />
            <Text style={{ color: "white", marginLeft: "20px" }}>{level}</Text>
          </Col>
          <Col style={{ display: "flex", alignItems: "center" }} span={12}>
            <ClockIcon color="white" />
            <Text style={{ color: "white", marginLeft: "20px" }}>
              {length && minutesToHoursWithMinutes(length)}
            </Text>
          </Col>
        </Row>
      </Row>
    </Row>
  );
}
