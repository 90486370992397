import { useCallback } from "react";
import { QueryKey, useQueryClient } from "react-query";

export const useRefreshQuery = (queryKeys?: Array<QueryKey>) => {
  const queryClient = useQueryClient();

  const onRefresh = useCallback(() => {
    queryClient
      .invalidateQueries(queryKeys)
      .catch((error) => console.error(error));
  }, [queryClient, queryKeys]);

  return { onRefresh };
};
