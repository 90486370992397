import { createContext, useEffect, useMemo, useState } from "react";
import { Row, Col } from "antd";
import { MyProgramsHeader } from "./components/MyProgramsHeader";
import { MyFoldersColumn } from "./components/MyFoldersColumn";
import { MyProgramsColumn } from "./components/MyProgramsColumn";
import { MyProgramInfoColumn } from "./components/MyProgramInfoColumn";
import { MyExerciseColumn } from "./components/MyExerciseColumn";
import { CreateNewProgramColumn } from "./components/CreateNewProgramColumn";
import { AddExerciseToProgramColumn } from "./components/AddExerciseToProgramColumn";
import { ExerciseDetailsColumn } from "./components/ExerciseDetailsColumn";
import { CopyExerciseColumn } from "./components/CopyExerciseColumn";
import { EditExerciseDetailsColumn } from "./components/EditExerciseDetailsColumn";
import {
  programTemplateExerciseTemplateEdit,
  useProgramExercises,
} from "../../../../../hooks/useProgramExercises";
import {
  programTemplateExerciseTemplate,
  ProgramTemplateWithExerciseTemplates,
  useGetProgramTemplate,
} from "../../../../../hooks/api/ProgramTemplates";
import { EditProgramColumn } from "./EditProgramColumn";

interface ContextProps {
  selectedFolderId: number | null;
  setSelectedFolderId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedProgramId: number | null;
  setSelectedProgramId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedExerciseId: number | null;
  setSelectedExerciseId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedEditExercise: programTemplateExerciseTemplateEdit | null;
  setSelectedEditExercise: React.Dispatch<
    React.SetStateAction<programTemplateExerciseTemplateEdit | null>
  >;
  isCreatingNewProgram: boolean;
  setIsCreatingNewProgram: React.Dispatch<React.SetStateAction<boolean>>;
  isAddingExercises: boolean;
  setIsAddingExercises: React.Dispatch<React.SetStateAction<boolean>>;
  isCopypingFromProgram: boolean;
  setIsCopyingFromProgram: React.Dispatch<React.SetStateAction<boolean>>;
  selectedExercise: programTemplateExerciseTemplate | null;
  setSelectedExercise: React.Dispatch<
    React.SetStateAction<programTemplateExerciseTemplate | null>
  >;
  editingProgramId: number | null;
  setEditingProgramId: React.Dispatch<React.SetStateAction<number | null>>;
}

export const MyProgramsContext = createContext<ContextProps | null>(null);

export function MyPrograms() {
  const [selectedFolderId, setSelectedFolderId] = useState<number | null>(null);

  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    null
  );

  const [selectedExerciseId, setSelectedExerciseId] = useState<number | null>(
    null
  );

  const [selectedExercise, setSelectedExercise] =
    useState<programTemplateExerciseTemplate | null>(null);

  const [selectedEditExercise, setSelectedEditExercise] =
    useState<programTemplateExerciseTemplateEdit | null>(null);

  const [isCreatingNewProgram, setIsCreatingNewProgram] =
    useState<boolean>(false);

  const [editingProgram, setEditingProgram] = useState<
    ProgramTemplateWithExerciseTemplates | null | undefined
  >(null);

  const [editingProgramId, setEditingProgramId] = useState<number | null>(null);

  const [isCopypingFromProgram, setIsCopyingFromProgram] =
    useState<boolean>(false);

  const [isAddingExercises, setIsAddingExercises] = useState<boolean>(false);

  const { data, isLoading } = useGetProgramTemplate(editingProgramId);

  useEffect(() => {
    if (editingProgramId && !isLoading) setEditingProgram(data);
    else {
      setIsAddingExercises(false);
      setEditingProgram(null);
      setSelectedExerciseId(null);
      setSelectedEditExercise(null);
      setSelectedExercise(null);
      setSelectedProgramId(null);
      setIsCopyingFromProgram(false);
      clearArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingProgramId, isLoading]);

  const {
    exercises,
    addExercise,
    editExercise,
    removeExercise,
    moveDown,
    moveUp,
    clearArray,
    copyExercises,
    changeBreak,
  } = useProgramExercises(
    editingProgram ? editingProgram.exerciseTemplate : null
  );

  useEffect(() => {
    if (!isCreatingNewProgram) {
      setIsAddingExercises(false);
      setIsCopyingFromProgram(false);
      setSelectedExerciseId(null);
      setSelectedEditExercise(null);
      setSelectedExercise(null);
      clearArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingNewProgram]);

  useEffect(() => {
    setSelectedProgramId(null);
    setSelectedExerciseId(null);
    setSelectedExercise(null);
    setIsCreatingNewProgram(false);
    setEditingProgramId(null);
  }, [selectedFolderId]);

  useEffect(() => {
    setSelectedExerciseId(null);
  }, [selectedProgramId]);

  const contextValue = useMemo(
    () => ({
      selectedFolderId,
      setSelectedFolderId,
      selectedProgramId,
      setSelectedProgramId,
      selectedExerciseId,
      setSelectedExerciseId,
      selectedEditExercise,
      setSelectedEditExercise,
      isCreatingNewProgram,
      setIsCreatingNewProgram,
      isAddingExercises,
      setIsAddingExercises,
      isCopypingFromProgram,
      setIsCopyingFromProgram,
      selectedExercise,
      setSelectedExercise,
      editingProgramId,
      setEditingProgramId,
    }),
    [
      selectedFolderId,
      setSelectedFolderId,
      selectedProgramId,
      setSelectedProgramId,
      selectedExerciseId,
      setSelectedExerciseId,
      selectedEditExercise,
      setSelectedEditExercise,
      isCreatingNewProgram,
      setIsCreatingNewProgram,
      isAddingExercises,
      setIsAddingExercises,
      isCopypingFromProgram,
      setIsCopyingFromProgram,
      selectedExercise,
      setSelectedExercise,
      editingProgramId,
      setEditingProgramId,
    ]
  );

  return (
    <MyProgramsContext.Provider value={contextValue}>
      <MyProgramsHeader />
      <Row>
        <Col span={6}>
          <MyFoldersColumn />
        </Col>
        {isCreatingNewProgram || editingProgram ? (
          <>
            <Col span={6}>
              {editingProgram ? (
                <EditProgramColumn
                  exercises={exercises}
                  editingProgram={editingProgram}
                  moveDown={moveDown}
                  moveUp={moveUp}
                  changeBreak={changeBreak}
                />
              ) : (
                <CreateNewProgramColumn
                  exercises={exercises}
                  moveDown={moveDown}
                  moveUp={moveUp}
                  changeBreak={changeBreak}
                />
              )}
            </Col>
            <Col span={6}>
              {isCopypingFromProgram ? (
                <CopyExerciseColumn
                  copyExercises={copyExercises}
                  addExercise={addExercise}
                />
              ) : (
                <AddExerciseToProgramColumn />
              )}
            </Col>
            <Col span={6}>
              {selectedEditExercise ? (
                <EditExerciseDetailsColumn
                  editExercise={editExercise}
                  removeExercise={removeExercise}
                />
              ) : (
                <ExerciseDetailsColumn addExercise={addExercise} />
              )}
            </Col>
          </>
        ) : (
          <>
            <Col span={6}>
              <MyProgramsColumn />
            </Col>
            <Col span={6}>
              <MyProgramInfoColumn />
            </Col>
            <Col span={6}>
              <MyExerciseColumn />
            </Col>
          </>
        )}
      </Row>
    </MyProgramsContext.Provider>
  );
}
