import React from "react";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { LoginImg } from "../../../assets/index";
import { Row } from "antd";
import "../../../css/public.css";

export function ForgotPassword() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          overflowY: "auto",
          height: "100vh",
        }}
        className="row-public-index"
      >
        <ForgotPasswordForm />
      </Row>
      <img style={{ height: "100vh" }} src={LoginImg} alt="logo" />
    </div>
  );
}
