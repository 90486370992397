import { ExerciseItem, ListLoader } from "../../../../../../../../components";
import { useExerciseBank } from "../../../../../../../../hooks/useExerciseBank";
import { exerciseTemplate } from "../../../../../../../../hooks/api/ExerciseTemplates";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";

interface ExerciseSubcategoryListProps {
  exerciseTemplates: exerciseTemplate[];
}

export function ExerciseSubcategoryList({
  exerciseTemplates,
}: ExerciseSubcategoryListProps) {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");

  const { selectedExerciseId, setSelectedExerciseId, selectedCategory } =
    useExerciseBank();

  return (
    <div
      style={
        selectedCategory === "myExercise"
          ? { marginTop: "70px", marginBottom: "40px" }
          : {}
      }
    >
      <ListLoader
        items={exerciseTemplates}
        renderItem={(item) => (
          <ExerciseItem
            id={item.id}
            key={item.id}
            name={item.name}
            description={item.description}
            isActive={selectedExerciseId === item.id}
            imageUrl={item.imageUrl}
            onClick={() => {
              setSelectedExerciseId(item.id);
            }}
          />
        )}
        emptyArrayText={t("noExercises")}
      />
    </div>
  );
}
