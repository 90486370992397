import { Row, Typography } from "antd";
import { useGetExerciseTemplate } from "../../../../../../../../hooks/api/ExerciseTemplates";
import {
  FrontalPlaneIcon,
  SagittalPlaneIcon,
  TransversePlaneIcon,
} from "../../../../../../../../assets";
import { calcualteRestTime } from "../../../../../../../../utils/timeConverter";
import { QueryLoader } from "../../../../../../../../components";
import { LabelInfo } from "./LabelInfo";
import { PlaneSwitch } from "./PlaneSwitch";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import ReactPlayer from "react-player";

const { Title, Text } = Typography;

interface ExerciseDetailsProps {
  id: number;
}

export function ExerciseDetails({ id }: ExerciseDetailsProps) {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");

  const { data: exercise, isLoading, isError } = useGetExerciseTemplate(id);

  return (
    <QueryLoader
      isError={isError}
      isLoading={isLoading}
      item={exercise}
      component={(exercise) => (
        <div>
          <Title level={3}>{exercise.name}</Title>

          {exercise.videoUrl ? (
            <ReactPlayer
              controls
              width="100%"
              height="auto"
              url={exercise.videoUrl}
            />
          ) : (
            <video width={"100%"} controls>
              <source />
              Your browser does not support the video tag.
            </video>
          )}

          <Row
            style={{
              justifyContent: "space-between",
            }}
          >
            <PlaneSwitch
              label={"sagittal Plane"}
              icon={FrontalPlaneIcon}
              checked={exercise.sagittalPlane}
            />
            <PlaneSwitch
              label={"frontal Plane"}
              icon={SagittalPlaneIcon}
              checked={exercise.frontalPlane}
            />
            <PlaneSwitch
              label={"transversal Plane"}
              icon={TransversePlaneIcon}
              checked={exercise.transversePlane}
            />
          </Row>

          <Title level={5}>{t("description")}</Title>
          <Text>{exercise.description}</Text>

          <Title level={5}>{t("instructions")}</Title>
          <Text>{exercise.instructions}</Text>

          <Row style={{ padding: "10px 0px" }}>
            <LabelInfo
              label={t("reps")}
              text={exercise.repetitions}
              show={!!exercise.repetitions}
            />
            <LabelInfo
              label={t("sets")}
              text={exercise.sets}
              show={!!exercise.sets}
            />
          </Row>
          <Row style={{ padding: "10px 0px" }}>
            <LabelInfo
              label={t("time")}
              text={exercise.time}
              show={!!exercise.time}
            />
          </Row>
          <Row style={{ padding: "10px 0px" }}>
            <LabelInfo
              label={t("equipment")}
              text={exercise.equipment}
              show={!!exercise.equipment}
            />
            <LabelInfo
              label={t("kg")}
              text={`${exercise.weight}kg`}
              show={!!exercise.weight}
            />
          </Row>
          <Row style={{ padding: "10px 0px" }}>
            <LabelInfo
              label={t("tempo")}
              text={`@ ${exercise.tempo1 !== null ? exercise.tempo1 : " "}, ${
                exercise.tempo2 !== null ? exercise.tempo2 : " "
              }, ${exercise.tempo3 !== null ? exercise.tempo3 : " "}, ${
                exercise.tempo4 !== null ? exercise.tempo4 : " "
              }`}
              show={
                exercise.tempo1 !== null ||
                exercise.tempo2 !== null ||
                exercise.tempo3 !== null ||
                exercise.tempo4 !== null
              }
            />
            <LabelInfo
              label={t("rmSets")}
              text={exercise.repetitionMaximum}
              show={!!exercise.restTime}
            />
          </Row>
          <Row style={{ padding: "10px 0px" }}>
            <LabelInfo
              label={t("rest")}
              text={`${calcualteRestTime(exercise.restTime ?? 0)} min`}
              show={!!exercise.restTime}
            />
          </Row>
        </div>
      )}
    />
  );
}
