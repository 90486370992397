import { Col, Divider, Row, Typography, Image, Popconfirm } from "antd";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { AddIcon, CopyButtonIcon } from "../../../../../../assets";
import {
  LabelInput,
  LabelNumberInput,
  LabelSelect,
  LabelTextArea,
  RoundButton,
  UploadButton,
} from "../../../../../../components";
import { useGetFolders } from "../../../../../../hooks/api/Folders";
import {
  ProgramTemplateWithExerciseTemplates,
  useAddProgramTemplateImage,
  useDeleteProgramTemplate,
  useUpdateProgramTemplate,
} from "../../../../../../hooks/api/ProgramTemplates";
import { useGenericProgramImage } from "../../../../../../hooks/useGenericProgramImage";
import { useMyProgramsContext } from "../../../../../../hooks/useMyPrograms";
import { useRefreshQuery } from "../../../../../../hooks/useRefreshQuery";
import { useTranslation } from "../../../../../../hooks/useTranslation";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../../../services/notificationService";
import { EditableExerciseList } from "./components/EditableExerciseList";
import {
  initEditProgramFormData,
  editProgramSchema,
} from "./components/EditProgram.utils";
import {
  levelOptions,
  programCategoryOptionsCreateProgram,
} from "../../../../../../constants/selectOptions";
import { colors } from "../../../../../../constants/colors";
import { programTemplateExerciseTemplateEdit } from "../../../../../../hooks/useProgramExercises";

const { Text } = Typography;

interface PropsEditProgram {
  exercises: Array<programTemplateExerciseTemplateEdit>;
  editingProgram: ProgramTemplateWithExerciseTemplates;
  moveDown: (index: number) => void;
  moveUp: (index: number) => void;
  changeBreak: (index: number) => void;
}

export function EditProgramColumn({
  exercises,
  editingProgram,
  moveDown,
  moveUp,
  changeBreak,
}: PropsEditProgram) {
  const [image, setImage] = useState<FormData | null>(null);
  const [imageSrc, setImageSrc] = useState<string>("");
  const { t } = useTranslation("createProgram");
  const { t: tNotification } = useTranslation("notificationText");

  useEffect(() => {
    if (editingProgram.imageUrl) setImageSrc(editingProgram.imageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { setEditingProgramId, setIsAddingExercises, setIsCopyingFromProgram } =
    useMyProgramsContext();

  const { getImage } = useGenericProgramImage();
  const { onRefresh } = useRefreshQuery(["getFolders"]);

  const { mutate: editProgram } = useUpdateProgramTemplate();
  const { mutate: deleteProgram } = useDeleteProgramTemplate();
  const { data, refetch } = useGetFolders();

  const foldersOptions = data.map((folder: any) => ({
    label: folder.name,
    value: folder.id,
  }));

  const { mutate: uploadImage } = useAddProgramTemplateImage();

  const removeProgram = () => {
    deleteProgram(editingProgram.id, {
      onSuccess: () => {
        onRefresh();
        setEditingProgramId(null);
        DisplaySuccessNotification(tNotification("successDeleteProgram"));
      },
      onError: () => {
        DisplayErrorNotification(tNotification("errorDeleteProgram"));
      },
    });
  };

  // @ts-ignore
  const editExistingProgram = (values: CreateProgramFormData) => {
    editProgram(
      {
        id: editingProgram.id,
        reqData: {
          ...values,
          exercises: exercises.map((e) => {
            return {
              id: e.id,
              order: e.order,
              breakBefore: e.breakBefore,
              exerciseParameters: {
                equipment: e.exerciseParameters?.equipment,
                repetitionMaximum: e.exerciseParameters?.repetitionMaximum,
                repetitions: e.exerciseParameters?.repetitions,
                restTime: e.exerciseParameters?.restTime,
                sets: e.exerciseParameters?.sets,
                tempo1: e.exerciseParameters?.tempo1,
                tempo2: e.exerciseParameters?.tempo2,
                tempo3: e.exerciseParameters?.tempo3,
                tempo4: e.exerciseParameters?.tempo4,
                time: e.exerciseParameters?.time,
                weight: e.exerciseParameters?.weight,
              },
            };
          }),
        },
      },
      {
        onSuccess: (data) => {
          image ? uploadImageFile(data.id) : onRefresh();
          setEditingProgramId(null);
          DisplaySuccessNotification(tNotification("successEditProgram"));
        },
        onError: () => {
          DisplayErrorNotification(tNotification("errorEditProgram"));
        },
      }
    );
  };

  const uploadImageFile = (id: number) => {
    image &&
      uploadImage(
        {
          id,
          reqData: image,
        },
        {
          onSuccess: async () => {
            await refetch();
            onRefresh();
            DisplaySuccessNotification(
              `${tNotification("successUpdateProgramImg")}`
            );
          },
          onError: () => {
            DisplayErrorNotification(tNotification("errorUpdateProgramImg"));
          },
        }
      );
  };

  return (
    <div className="program-bank-container">
      <Formik
        initialValues={initEditProgramFormData(editingProgram)}
        validationSchema={editProgramSchema}
        onSubmit={editExistingProgram}
      >
        {({ handleChange, setFieldValue, handleSubmit, values, errors }) => (
          <>
            <Row style={{ position: "relative" }}>
              <Image
                preview={false}
                src={getImage(imageSrc, values.category)}
              />
              <div
                style={{ position: "absolute", right: "10px", bottom: "10px" }}
              >
                <UploadButton
                  type={"image"}
                  text={t("editPhoto")}
                  uploadFile={(c, f) => {
                    setImage(f);
                    // @ts-ignore
                    setImageSrc(URL.createObjectURL(f.get("image")));
                  }}
                />
              </div>
            </Row>
            <Row style={{ flexDirection: "column", margin: "20px 20px 15px" }}>
              <LabelInput
                title={t("programName")}
                value={values.name}
                onChange={handleChange("name")}
                error={errors.name}
              />
              <LabelSelect
                items={programCategoryOptionsCreateProgram}
                defaultValue={values.category}
                onChange={(category) =>
                  setFieldValue("category", category)
                }
                title={t("category")}
                error={errors.category}
              />
              <LabelSelect
                items={foldersOptions}
                // @ts-ignore
                defaultValue={values.folderId}
                onChange={(id) => setFieldValue("folderId", id)}
                title={t("folders")}
                error={errors.folderId}
                placeholder={
                  foldersOptions.length === 0 ? t("addFirstFolder") : ""
                }
              />
              <LabelSelect
                items={levelOptions}
                defaultValue={values.level}
                onChange={(level) => setFieldValue("level", level)}
                title={t("level")}
                error={errors.level}
              />
              <LabelNumberInput
                title={t("length")}
                value={values.length}
                onChange={(e) => setFieldValue("length", e)}
                error={errors.length}
              />
              <LabelInput
                title={t("eq")}
                value={String(values.equipmentNeeded)}
                onChange={handleChange("equipmentNeeded")}
                error={errors.equipmentNeeded}
              />
              <LabelTextArea
                title={t("description")}
                value={String(values.description)}
                onChange={handleChange("description")}
                error={errors.description}
              />
              <Row style={{ marginBottom: "30px" }}>
                <Col
                  onClick={() => {
                    setIsAddingExercises(true);
                    setIsCopyingFromProgram(false);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <AddIcon width={30} height={30} />
                  <Text
                    style={{
                      marginLeft: "20px",
                      color: colors.primary,
                      fontWeight: "bold",
                    }}
                  >
                    {t("addExercise")}
                  </Text>
                </Col>
              </Row>
              <Row style={{ marginBottom: "30px" }}>
                <Col
                  onClick={() => {
                    setIsCopyingFromProgram(true);
                    setIsAddingExercises(false);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <CopyButtonIcon width={30} height={30} />
                  <Text
                    style={{
                      marginLeft: "20px",
                      color: colors.primary,
                      fontWeight: "bold",
                    }}
                  >
                    {t("copyProgramFrom")}
                  </Text>
                </Col>
              </Row>
              <EditableExerciseList
                exercises={exercises}
                changeBreak={changeBreak}
                moveUp={moveUp}
                moveDown={moveDown}
              />
              <Divider style={{ height: "2px", backgroundColor: "darkgray" }} />
              <Row
                style={{ alignItems: "center", justifyContent: "space-around" }}
              >
                <Text
                  style={{
                    color: "#1ba8f2",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => setEditingProgramId(null)}
                >
                  {t("cancel")}
                </Text>
                <Popconfirm
                  title={t("deleteProgramTitle")}
                  onConfirm={removeProgram}
                  okText={t("delete")}
                  cancelText={t("cancelButton")}
                  icon={false}
                >
                  <RoundButton
                    style={{ width: "90px" }}
                    title={t("delete")}
                    typeColor="secondary"
                  />
                </Popconfirm>
                <RoundButton
                  style={{ width: "80px" }}
                  title={t("save")}
                  typeColor="primary"
                  onClick={() => handleSubmit()}
                />
              </Row>
            </Row>
          </>
        )}
      </Formik>
    </div>
  );
}
