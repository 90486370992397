import "antd/dist/antd.css";
import "../../../../css/mainContainer.css";
import { UserOutlined, MenuOutlined } from "@ant-design/icons";
import { Layout, Button, Avatar, Typography } from "antd";
import { useAuthState } from "../../../../hooks/useAuthState";
import { LogoutIcon, LogoWhite } from "../../../../assets";

const { Header } = Layout;

interface TopBarProps {
  toggleCollapsed: () => void;
}

export function TopBar({ toggleCollapsed }: TopBarProps) {
  const { user, logout } = useAuthState();

  return (
    <Header className="header">
      <Button type="text" onClick={toggleCollapsed} style={{ marginRight: 14 }}>
        <MenuOutlined className="top-bar-icon" />
      </Button>
      <LogoWhite />

      <div className="top-bar">
        <Avatar src={user?.imageUrl} icon={<UserOutlined />} />
        <Typography className="top-bar-user-name">
          {`${user?.name} ${user?.surname}`}
        </Typography>
        {/* <Button type="text">
          <Link to={"/settings"}>
            <SettingsIcon className="top-bar-icon" />
          </Link>
        </Button>
        <Button type="text">
          <Link to={"/help"}>
            <HelpIcon className="top-bar-icon" />
          </Link>
        </Button> */}
        <Button type="text" onClick={logout}>
          <LogoutIcon className="top-bar-icon" />
        </Button>
      </div>
    </Header>
  );
}
