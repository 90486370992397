import { UploadOutlined } from "@ant-design/icons";
import { Button, Progress } from "antd";
import "antd/dist/antd.css";
import Upload, { RcFile } from "antd/lib/upload";
import {
  UploadRequestOption,
  UploadProgressEvent,
} from "rc-upload/lib/interface";
import { useState } from "react";
import { useTranslation } from "../hooks/useTranslation";
import { DisplayErrorNotification } from "../services/notificationService";

const IMAGE_SIZE_LIMIT_IN_MB = 25;
const VIDEO_SIZE_LIMIT_IN_MB = 512;

const IMAGE_FILE_TYPES = ["image/jpg", "image/png", "image/jpeg"];
const VIDEO_FILE_TYPES = ["video/mp4"];

const IMAGE_ACCEPT_TYPE = "image/*";
const VIDEO_ACCEPT_TYPE = "video/*";

const FILE_SIZES = {
  image: IMAGE_SIZE_LIMIT_IN_MB,
  video: VIDEO_SIZE_LIMIT_IN_MB,
};
const FILE_TYPES = {
  image: IMAGE_FILE_TYPES,
  video: VIDEO_FILE_TYPES,
};
const ACCEPT_TYPES = {
  image: IMAGE_ACCEPT_TYPE,
  video: VIDEO_ACCEPT_TYPE,
};

export type UploadFileConfig = {
  timeout: number;
  onUploadProgress: (event: UploadProgressEvent) => void;
};

export interface UploadButtonProps {
  type: "image" | "video";
  text: string;
  uploadFile: (config: UploadFileConfig, formData: FormData) => void;
}

export function UploadButton({ type, uploadFile, text }: UploadButtonProps) {
  const { t } = useTranslation();
  const [progress, setProgress] = useState<number>(0);

  const beforeUpload = (file: RcFile) => {
    const fileSizeInMB = file.size / (1024 * 1024);
    const fileTypeCondition = FILE_TYPES[type].includes(file.type);
    const fileSizeCondition = fileSizeInMB < FILE_SIZES[type];

    if (fileTypeCondition && fileSizeCondition) {
      return true;
    } else {
      DisplayErrorNotification(`${t("notificationText.errorType")}`);
      return false;
    }
  };

  const handleUpload = (options: UploadRequestOption) => {
    const { file } = options;
    const formData = new FormData();
    formData.append(type, file);
    const config = {
      timeout: 100000, //100s
      onUploadProgress: (event: UploadProgressEvent) => {
        const percent =
          event && event?.loaded && event?.total
            ? Math.floor((event.loaded / event.total) * 100)
            : 0;
        setProgress(percent);
      },
    };

    uploadFile(config, formData);
  };

  return (
    <Upload
      beforeUpload={beforeUpload}
      accept={ACCEPT_TYPES[type]}
      customRequest={handleUpload}
      showUploadList={false}
      maxCount={1}
    >
      <Button icon={<UploadOutlined />}>{text}</Button>
      {progress > 0 ? (
        <Progress style={{ width: "80%" }} percent={progress} />
      ) : null}
    </Upload>
  );
}
