import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { AccountType, Gender } from "../../constants/enums";
import { API } from "../../services/API";

export interface LoginDto {
  email: string;
  password: string;
}

export interface LoginViewer {
  firebaseToken: string;
  user: AuthUser;
  token: string;
  refreshToken: string;
}

export interface AuthUser {
  id: number;
  role: AccountType;
  email: string;
  name: string;
  surname: string;
  gender: Gender;
  birthDate: Date;
  phone: string;
  city: string;
  country: string;
  imageUrl: string;
  createdAt: Date;
}

const login = async (reqData: LoginDto) => {
  const url = `/auth/trainer/login`;
  const { data } = await API.post<LoginDto, AxiosResponse<LoginViewer>>(
    url,
    reqData
  );
  return data;
};

export function useLogin() {
  return useMutation(login);
}

export interface ForgotPasswordDto {
  email: string;
  role: AccountType;
}

const forgotPassword = async (reqData: ForgotPasswordDto) => {
  const url = `/auth/forgot-password`;
  const { data } = await API.post<ForgotPasswordDto, AxiosResponse>(
    url,
    reqData
  );
  return data;
};

export function useForgotPassword() {
  return useMutation(forgotPassword);
}
