import * as Yup from "yup";
import { Folder } from "../../../../../../../../hooks/api/Folders";
import { i18n } from "../../../../../../../../i18n";

export interface EditFolderTypes {
  name: string;
}

export const initEditFolderFormData = (folder: Folder) => ({
  name: folder.name,
});

export const EditFolderSchema: Yup.SchemaOf<EditFolderTypes> =
  Yup.object().shape({
    name: Yup.string().required(
      i18n.t("validators.required", { ns: "translation" })
    ),
  });
