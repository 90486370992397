import { Row, Typography } from "antd";
import { ExistWrapper } from "../../../../../../../components";
import { useBoughtProgramsBank } from "../../../../../../../hooks/useBoughtProgramsBank";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { ExerciseDetails } from "../../../ExerciseBank/components/ExerciseColumn/components/ExerciseDetails";

const { Text } = Typography;

export function ExerciseColumn() {
  const { selectedExerciseId } = useBoughtProgramsBank();
  const { t } = useTranslation("programsBankPage.programsBank");
  return (
    <div className="exercise-bank-container">
      <ExistWrapper
        item={selectedExerciseId}
        renderItem={(exerciseId) => <ExerciseDetails id={exerciseId} />}
        renderNullItem={
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            <Text>{t("selectExercise")}</Text>
          </Row>
        }
      />
    </div>
  );
}
