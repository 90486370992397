import { useExerciseBank } from "../../../../../../../../hooks/useExerciseBank";
import { Row, Typography } from "antd";
import { AddIcon } from "../../../../../../../../assets";
import { colors } from "../../../../../../../../constants/colors";

import { useTranslation } from "../../../../../../../../hooks/useTranslation";

const { Text } = Typography;

export function MyExercisesHeader() {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");

  const { setIsCreatingNewExercise, setSelectedExerciseId } = useExerciseBank();

  return (
    <Row
      style={{
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: "white",
        position: "absolute",
        top: "10px",
        left: "10px",
        right: "10px",
        zIndex: "500",
        padding: "20px 20px",
      }}
      onClick={() => {
        setSelectedExerciseId(null);
        setIsCreatingNewExercise(true);
      }}
    >
      <AddIcon width={40} height={40} />
      <Text
        style={{
          marginLeft: "20px",
          color: colors.primary,
          fontWeight: "bold",
        }}
      >
        {t("addNewExercise").toUpperCase()}
      </Text>
    </Row>
  );
}
