import { QueryLoader } from "../../../../../../../../components";
import { useGetExerciseTemplate } from "../../../../../../../../hooks/api/ExerciseTemplates";
import { AdditionalParameters } from "../../../../../../../../hooks/api/ProgramTemplates";
import { exerciseTemplate } from "../../../../../../../../hooks/api/ExerciseTemplates";
import { Exercise } from "./Exercise";

export function ExerciseLoader({
  exerciseId,
  addExercise
}: {
  exerciseId: number;
  addExercise: (
    exercise: exerciseTemplate,
    additionalParameters?: AdditionalParameters | undefined
  ) => void;
}) {
  const {
    data: exerciseTemplate,
    isLoading,
    isError,
  } = useGetExerciseTemplate(exerciseId);

  return (
    <QueryLoader
      isError={isError}
      isLoading={isLoading}
      item={exerciseTemplate}
      component={(exercise) => <Exercise addExercise={addExercise} exercise={exercise} />}
    />
  );
}
