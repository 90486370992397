import * as React from "react";
import { AuthContext } from "../context/AuthProvider";

export const useAuthState = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("AuthContext must be within AuthContextProvider");
  }

  return context;
};
