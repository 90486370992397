import { ListLoader, ProgramItem } from "../../../../../../../../components";
import { programSubcategory } from "../../../../../../../../hooks/api/Packs";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";

interface PropsProgramsList {
  programs: programSubcategory[];
}

export function ProgramsList({ programs }: PropsProgramsList) {
  const { t } = useTranslation("programsBankPage.programsBank");

  return (
    <ListLoader
      items={programs}
      renderItem={(item) => <ProgramItem key={item.id} program={item} />}
      emptyArrayText={t("noPrograms")}
    />
  );
}
