import { Col } from "antd";
import { colors } from "../../../../../../../../constants/colors";

interface LabelInfoProps {
  label: string;
  text?: string | null | number;
  show: boolean;
}

export function LabelInfo({ label, text, show }: LabelInfoProps) {
  if (!show) {
    return null;
  }

  return (
    <Col span={12}>
      <div style={{ color: colors.textSecondary }}>{label}</div>
      <div style={{ fontSize: "16px" }}>{text}</div>
    </Col>
  );
}
