import { Select, Row, Typography, SelectProps } from "antd";

interface ItemsTypes {
  label: string;
  value: string | null;
}

interface LabelSelectProps extends SelectProps<string> {
  title: string;
  error: string | undefined;
  items: ItemsTypes[];
  placeholder?: string;
}

export function LabelSelect({
  title,
  onChange,
  error,
  items,
  defaultValue,
  placeholder
}: LabelSelectProps) {
  const { Text } = Typography;
  const { Option } = Select;
  return (
    <Row className="component-row">
      <Text type="secondary">{title}</Text>
      <Select
        className="select"
        defaultValue={defaultValue || items[0]?.value}
        onChange={onChange}
        placeholder={placeholder}
      >
        {items?.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
      {error && <Text type="danger">{error}</Text>}
    </Row>
  );
}
