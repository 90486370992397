interface ExistWrapperProps<T> {
  item?: T | null;
  renderItem: (item: T) => JSX.Element;
  renderNullItem?: JSX.Element | null;
}

export function ExistWrapper<T>(props: ExistWrapperProps<T>) {
  const { item, renderItem, renderNullItem = null } = props;

  if (!item) return renderNullItem;

  return renderItem(item);
}
