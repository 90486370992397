import { Col, Row, Tabs, Typography } from "antd";
import { useAuthState } from "../../../hooks/useAuthState";
import "../../../css/home.css";
import { RoundButton } from "../../../components";
import { CartIcon } from "../../../assets";
import { Subscription } from "./components/Subscription";
import { Profile } from "./components/Profile";
import { useSubscriptionState } from "../../../hooks/useSubscriptionState";
import { useTranslation } from "../../../hooks/useTranslation";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

export function Home() {
  const { t } = useTranslation("homePage");

  const { user } = useAuthState();
  const { hasSubscription } = useSubscriptionState();

  return (
    <Col style={{ marginTop: "20px" }}>
      <Title level={3} className="bold-500">
        {t("welcome", { name: user?.name })}
      </Title>
      <Row>
        <Text className="bold-500">{t("description1")}</Text>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Text className="bold-500">
          {hasSubscription
            ? t("description2HasSubscription")
            : t("description2HasntSubscription")}
        </Text>
      </Row>
      <Row>
        <Text className="bold-500">
          {!hasSubscription && t("description3HasntSubscription")}
        </Text>
      </Row>
      <Row style={{ marginBottom: "15px" }}>
        <Text className="bold-500">{t("description4")}</Text>
      </Row>
      <Col style={{ marginBottom: "30px", width: "200px" }}>
        <RoundButton
          typeColor={hasSubscription ? "primary" : "secondary"}
          title={t("fit4livingShop")}
          onClick={() =>
            window.open(
              "https://fit4living.se/applikation/abonnemang-i-coach-buddy.html",
              "_blank"
            )
          }
          icon={
            <CartIcon
              width={15}
              style={{ marginRight: "7px", paddingTop: "3px" }}
            />
          }
        />
      </Col>
      <Col style={{ width: "500px" }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("profileDetails").toUpperCase()} key="1">
            <Profile />
          </TabPane>
          <TabPane tab={t("subsription").toUpperCase()} key="2">
            <Subscription />
          </TabPane>
        </Tabs>
      </Col>
    </Col>
  );
}
