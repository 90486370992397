import { Typography, Col } from "antd";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

const listData = [
  { text: "All exercises will be deleted" },
  { text: "Exercises history will be deleted" },
  { text: "All information about your account will be deleted" },
];

export function DeleteAccountInstruction() {
  return (
    <Col
      style={{
        width: "fit-content",
        margin: "0 auto",
        padding: "20px 20px 0",
        display: "flex",
        flexDirection: "column",
        rowGap: "20px",
      }}
    >
      <Title>How to delete your account</Title>
      <Text>
        To delete your account please contact:
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = "mailto:app@coach-buddy.se";
            e.preventDefault();
          }}
        >
          {" app@coach-buddy.se"}
        </Link>
      </Text>
      <Text>When you delete your account:</Text>
      <ul>
        {listData.map(({ text }) => (
          <li key={text}>{text}</li>
        ))}
      </ul>
    </Col>
  );
}


