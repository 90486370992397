import { signupInitValues, signupSchema } from "./signup.utils";
import { Typography, Row, Col } from "antd";
import { Formik, Form } from "formik";
import {
  DateInput,
  LinkCheckbox,
  LabelInput,
  LabelNumberInput,
  LabelSelect,
  LabelTextArea,
  PasswordInput,
  RoundButton,
} from "../../../components";
import { TrainerDto, useAddTrainer } from "../../../hooks/api/Trainers";
import { colors } from "../../../constants/colors";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../services/notificationService";
import {
  countryOptions,
  genderOptions,
} from "../../../constants/selectOptions";
import { LogoBlack } from "../../../assets/index";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../hooks/useTranslation";

export function SignupForm() {
  const { Title, Text } = Typography;
  const { t: tNotification } = useTranslation("notificationText");
  const { t } = useTranslation("loginPage");

  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/login");
  };

  const { mutate, isLoading } = useAddTrainer();

  const signup = (values: TrainerDto) => {
    delete values.policy;
    delete values.termsOfUse;
    delete values.trainerRegulations;
    delete values.confirmPassword;
    mutate(values, {
      onSuccess: () => {
        DisplaySuccessNotification(tNotification("successSignup"));
        navigateToLogin();
      },
      onError: (error: any) => {
        if (error.message.includes("409"))
          DisplayErrorNotification(tNotification("errorConflict"));
        else {
          DisplayErrorNotification(tNotification("errorSignup"));
        }
      },
    });
  };

  return (
    <Col style={{ padding: "60px 130px 0", maxWidth: "1000px" }}>
      <div style={{ textAlign: "center" }}>
        <LogoBlack />
      </div>
      <Title className="public-title">{t("signupTitle")}</Title>
      <Formik
        initialValues={signupInitValues}
        validationSchema={signupSchema}
        onSubmit={signup}
      >
        {({
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          values,
          touched,
        }) => (
          <Form className="signup-form-container">
            <LabelInput
              title={t("email")}
              onChange={handleChange("email")}
              value={values.email}
              error={errors.email && touched.email ? errors.email : undefined}
            />
            <PasswordInput
              title={t("password")}
              onChange={handleChange("password")}
              value={values.password}
              error={
                errors.password && touched.password
                  ? errors.password
                  : undefined
              }
            />
            <PasswordInput
              title={t("confirmPassword")}
              onChange={handleChange("confirmPassword")}
              value={values.confirmPassword}
              error={
                errors.confirmPassword && touched.confirmPassword
                  ? errors.confirmPassword
                  : undefined
              }
            />
            <LabelInput
              title={t("name")}
              onChange={handleChange("name")}
              value={values.name}
              error={errors.name && touched.name ? errors.name : undefined}
            />
            <LabelInput
              title={t("surname")}
              onChange={handleChange("surname")}
              value={values.surname}
              error={
                errors.surname && touched.surname ? errors.surname : undefined
              }
            />
            <LabelSelect
              title={t("gender")}
              onChange={(e) => setFieldValue("gender", e)}
              items={genderOptions}
              error={
                errors.gender && touched.gender ? errors.gender : undefined
              }
            />
            <DateInput
              title={t("birthDate")}
              onChange={(e: Date | null) => setFieldValue("birthDate", e)}
              value={values.birthDate}
              error={
                errors.birthDate && touched.birthDate
                  ? errors.birthDate
                  : undefined
              }
            />
            <LabelInput
              title={t("phone")}
              onChange={handleChange("phone")}
              value={values.phone}
              error={errors.phone && touched.phone ? errors.phone : undefined}
            />
            <LabelInput
              title={t("city")}
              onChange={handleChange("city")}
              value={values.city}
              error={errors.city && touched.city ? errors.city : undefined}
            />
            <LabelSelect
              title={t("country")}
              onChange={(e) => setFieldValue("country", e)}
              items={countryOptions}
              error={
                errors.country && touched.country ? errors.country : undefined
              }
            />
            <LabelInput
              title={t("discipline")}
              onChange={handleChange("discipline")}
              value={values.discipline}
              error={
                errors.discipline && touched.discipline
                  ? errors.discipline
                  : undefined
              }
            />
            <LabelInput
              title={t("languages")}
              onChange={handleChange("languages")}
              value={values.languages}
              error={
                errors.languages && touched.languages
                  ? errors.languages
                  : undefined
              }
            />
            <LabelNumberInput
              title={t("price")}
              onChange={(e) => setFieldValue("price", e)}
              value={values.price}
              error={errors.price && touched.price ? errors.price : undefined}
            />
            <Col className="signup-area-inputs-container">
              <LabelTextArea
                title={t("description")}
                onChange={handleChange("description")}
                value={values.description}
                error={
                  errors.description && touched.description
                    ? errors.description
                    : undefined
                }
              />
              <LabelTextArea
                title={t("aboutMe")}
                onChange={handleChange("aboutMe")}
                value={values.aboutMe}
                error={
                  errors.aboutMe && touched.aboutMe ? errors.aboutMe : undefined
                }
              />
            </Col>
            <Row style={{ flexBasis: "100%", flexDirection: "column" }}>
              <LinkCheckbox
                checked={values.policy}
                text={t("readAndAccept")}
                path="/statutes/privacy-policy"
                textLink={t("privacyPolicy")}
                onChange={() => setFieldValue("policy", !values.policy)}
                error={
                  errors.policy && touched.policy ? errors.policy : undefined
                }
              />
              <LinkCheckbox
                checked={values.termsOfUse}
                text={t("readAndAccept")}
                path="/statutes/terms-of-use"
                textLink={t("termsOfUse")}
                onChange={() => setFieldValue("termsOfUse", !values.termsOfUse)}
                error={
                  errors.termsOfUse && touched.termsOfUse
                    ? errors.termsOfUse
                    : undefined
                }
              />
              <LinkCheckbox
                checked={values.trainerRegulations}
                text={t("readAndAccept")}
                path="/statutes/trainer-regulations"
                textLink={t("trainerRegulations")}
                onChange={() =>
                  setFieldValue(
                    "trainerRegulations",
                    !values.trainerRegulations
                  )
                }
                error={
                  errors.trainerRegulations && touched.trainerRegulations
                    ? errors.trainerRegulations
                    : undefined
                }
              />
            </Row>
            <RoundButton
              title={t("signup")}
              style={{ marginTop: "25px", marginBottom: "40px" }}
              onClick={() => handleSubmit()}
              loading={isLoading}
            />
          </Form>
        )}
      </Formik>
      <Row style={{ justifyContent: "space-between", paddingBottom: "40px" }}>
        <Text className="bold-500">{t("loginDescribe")}</Text>
        <Text
          className="login-signup-text"
          style={{ color: colors.primary }}
          onClick={navigateToLogin}
        >
          {t("loginWithSpace").toUpperCase()}
        </Text>
      </Row>
    </Col>
  );
}
