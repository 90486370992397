import exercise1 from "../assets/images/Exercise1.jpg";
import exercise2 from "../assets/images/Exercise2.jpg";
import exercise3 from "../assets/images/Exercise3.jpg";
import exercise4 from "../assets/images/Exercise4.jpg";

const defaultImages = [exercise1, exercise2, exercise3, exercise4];

export const useExerciseImage = (
  exerciseId: number,
  imageUrl?: string | null
) => {
  const randomImageIndex = exerciseId % 4;
  const randomImage = defaultImages[randomImageIndex];

  return { image: imageUrl ?? randomImage, fallback: randomImage };
};
