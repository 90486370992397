import { useMemo } from "react";
import { useGetSubcategoriesByCategory } from "../../../../../../../../hooks/api/Subcategories";
import { useExerciseBank } from "../../../../../../../../hooks/useExerciseBank";
import { QueryLoader } from "../../../../../../../../components";
import { SubcategoryList } from "./SubcategoryList";

export function DefaultSubcategoryList() {
  const { selectedCategory } = useExerciseBank();

  const {
    data: categories,
    isLoading,
    isError,
  } = useGetSubcategoriesByCategory();

  const subcategories = useMemo(() => {
    return (
      categories?.find((item) => item.category === selectedCategory)
        ?.subcategories ?? []
    );
  }, [categories, selectedCategory]);

  return (
    <QueryLoader
      isError={isError}
      isLoading={isLoading}
      item={subcategories}
      component={(subcategories) => (
        <SubcategoryList subcategories={subcategories} />
      )}
    />
  );
}
