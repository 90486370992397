import { Button, Modal } from "antd";
import "../../../../css/home.css";
import { useCancelSubscription } from "../../../../hooks/api/Payments";
import { useRefreshQuery } from "../../../../hooks/useRefreshQuery";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../services/notificationService";
import { useSubscriptionState } from "../../../../hooks/useSubscriptionState";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../../constants/date";
import { useTranslation } from "../../../../hooks/useTranslation";

interface CancelSubscriptionModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export function CancelSubscriptionModal(props: CancelSubscriptionModalProps) {
  const { visible, setVisible } = props;
  const { t } = useTranslation("homePage");
  const { lastSubscription } = useSubscriptionState();

  const { mutate, isLoading } = useCancelSubscription();
  const { onRefresh } = useRefreshQuery(["getSubscription"]);

  const cancelSubscription = () => {
    mutate(undefined, {
      onSuccess: () => {
        DisplaySuccessNotification(t("cancelSubscriptionSuccess"));
        onRefresh();
        setVisible(false);
      },
      onError: () => {
        DisplayErrorNotification(t("notificationService.error"));
        setVisible(false);
      },
    });
  };

  return (
    <Modal
      visible={visible}
      title={t("cancelSubscriptionConfirmation")}
      onCancel={() => setVisible(false)}
      footer={[
        <Button onClick={() => setVisible(false)}>{t("no")}</Button>,
        <Button type="primary" loading={isLoading} onClick={cancelSubscription}>
          {t("yes")}
        </Button>,
      ]}
    >
      <p>
        {t("cancelSubscriptionDescription", {
          date: dayjs(lastSubscription?.endAt).format(DATE_FORMAT),
        })}
      </p>
    </Modal>
  );
}
