import { Modal } from "antd";
import { useFormik } from "formik";
import { RoundButton } from "../../../../../../components";
import { useTranslation } from "../../../../../../hooks/useTranslation";
import { useAddFolder } from "../../../../../../hooks/api/Folders";
import { useRefreshQuery } from "../../../../../../hooks/useRefreshQuery";
import { LabelInfoEdit } from "../../ExerciseBank/components/ExerciseColumn/components/LabelInfoEdit";
import {
  initAddFolderValues,
  AddFolderSchema,
  AddFolderTypes,
} from "../components/AddFolderUtils";

interface PropsAddFolderModal {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddFolderModal({
  isModalOpen,
  setIsModalOpen,
}: PropsAddFolderModal) {
  const { t } = useTranslation("myProgramsPage");

  const { mutate, isLoading } = useAddFolder();

  const { onRefresh } = useRefreshQuery(["getFolders"]);

  const { values, errors, touched, handleChange, handleBlur, submitForm } =
    useFormik({
      initialValues: initAddFolderValues,
      validationSchema: AddFolderSchema,
      onSubmit: (values: AddFolderTypes, { resetForm }) => {
        mutate(
          { ...values },
          {
            onSuccess: () => {
              onRefresh();
              resetForm();
              setIsModalOpen(false);
            },
          }
        );
      },
    });

  return (
    <Modal
      title={t("addNewFolder")}
      visible={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={[
        <RoundButton
          title={t("cancel")}
          typeColor="secondary"
          onClick={() => setIsModalOpen(false)}
          style={{ width: "150px" }}
        />,
        <RoundButton
          title={t("create")}
          loading={isLoading}
          onClick={submitForm}
          style={{ width: "150px" }}
        />,
      ]}
    >
      <LabelInfoEdit
        label={t("name")}
        value={values.name}
        onChange={handleChange("name")}
        onBlur={handleBlur("name")}
        errorText={errors.name && touched.name ? errors.name : undefined}
      />
    </Modal>
  );
}
