import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { NotFound } from "../containers/Public/NotFound";
import { Home } from "../containers/Logged/Home";
import { Messages } from "../containers/Logged/Messages";
import { Clients } from "../containers/Logged/Clients";
import { ExerciseBankPage } from "../containers/Logged/ExerciseBankPage";
import { Help } from "../containers/Logged/Help";
import { Settings } from "../containers/Logged/Settings";
import { PrivateRoute } from "./components/PrivateRoute";

export function LoggedRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/help" element={<Help />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/clients" element={<Clients />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/exercise-bank" element={<ExerciseBankPage />} />
      </Route>
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
