import { Row, Typography } from "antd";
import { ExistWrapper } from "../../../../../../../components";
import { useMyProgramsContext } from "../../../../../../../hooks/useMyPrograms";
import { Exercises } from "./components/Exercises";
import { useTranslation } from "../../../../../../../hooks/useTranslation";

const { Text } = Typography;

export function AddExerciseToProgramColumn() {
  const { t } = useTranslation("createProgram");
  const { isAddingExercises } = useMyProgramsContext();

  return (
    <div className="program-bank-container">
      <ExistWrapper
        item={isAddingExercises}
        renderItem={() => <Exercises />}
        renderNullItem={
          <Row
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Text>{t("startAddingExercisesToProgram")}</Text>
          </Row>
        }
      />
    </div>
  );
}
