import { Input, Typography, Row, InputProps } from "antd";

interface LabelInputProps extends InputProps {
  title: string;
  error: string | undefined;
}

export function LabelInput({ title, onChange, value, error }: LabelInputProps) {
  const { Text } = Typography;
  return (
    <Row className="component-row">
      <Text type="secondary">{title}</Text>
      <Input
        onChange={onChange}
        value={value}
        style={{ borderRadius: "25px" }}
      />
      {error && <Text type="danger">{error}</Text>}
    </Row>
  );
}
