import { i18n } from "../../../i18n";
import * as Yup from "yup";
import { LoginDto } from "../../../hooks/api/Auth";

export const loginInitValues: LoginDto = {
  email: "",
  password: "",
};

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("validators.email", { ns: "translation" }))
    .required(i18n.t("validators.required", { ns: "translation" })),
  password: Yup.string()
    .min(8, i18n.t("validators.tooShort", { ns: "translation" }))
    .required(i18n.t("validators.required", { ns: "translation" })),
});
