import { ListLoader, QueryLoader } from "../../../../../../../../components";
import { useGetFolder } from "../../../../../../../../hooks/api/Folders";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { ProgramItem } from "./ProgramItem";

export function ProgramsLoader({ id }: { id: number }) {
  const { t } = useTranslation("myProgramsPage");
  const { data, isLoading, isError } = useGetFolder(id);

  return (
    <QueryLoader
      isError={isError}
      isLoading={isLoading}
      item={data}
      component={(folder) => (
        <ListLoader
          items={folder.programTemplates}
          renderItem={(item) => (
            <ProgramItem key={item.id} program={item} clickable />
          )}
          emptyArrayText={t("noPrograms")}
        />
      )}
    />
  );
}
