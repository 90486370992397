import { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
// import { AxiosResponse } from "axios";
import { API } from "../../services/API";
import { programTemplate } from "./ProgramTemplates";

export type Folder = {
  id: number;
  name: string;
  description: string | null;
  trainerId: number;
  createdAt: Date;
};

export type Folders = Folder & {
  programTemplates: {
    name: string;
  }[];
};

export type folderWithProgramTemplates = Folder & {
  programTemplates: programTemplate[];
};

export interface UpdateFolderEntry {
  name: string;
  description?: string;
}

export interface AddFolderEntry {
  name: string;
  description?: string;
}

const getFolder = async (id: number) => {
  const url = `/folders/${id}`;
  const { data } = await API.get<{}, AxiosResponse<folderWithProgramTemplates>>(
    url
  );
  return data;
};

export function useGetFolder(id: number) {
  return useQuery(["getFolder", id], () => getFolder(id), { enabled: !!id });
}

const getFolders = async () => {
  const url = `/folders`;
  const { data } = await API.get(url);
  return data;
};

export function useGetFolders() {
  return useQuery(["getFolders"], getFolders);
}

const addFolder = async (reqData: AddFolderEntry) => {
  const url = `/folders`;
  const { data } = await API.post(url, reqData);
  return data;
};

export function useAddFolder() {
  return useMutation(addFolder);
}

const updateFolder = async ({
  id,
  reqData,
}: {
  id: number;
  reqData: UpdateFolderEntry;
}) => {
  const url = `/folders/${id}`;
  const { data } = await API.put(url, reqData);
  return data;
};

export function useUpdateFolder() {
  return useMutation(updateFolder);
}

const deleteFolder = async (id: number) => {
  const url = `/folders/${id}`;
  const { data } = await API.delete(url);
  return data;
};

export function useDeleteFolder() {
  return useMutation(deleteFolder);
}
