import {
  CopyProgramItem,
  ListLoader,
} from "../../../../../../../../components";
import { exerciseTemplate } from "../../../../../../../../hooks/api/ExerciseTemplates";
import { AdditionalParameters, programTemplate, programTemplateExerciseTemplate } from "../../../../../../../../hooks/api/ProgramTemplates";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";

interface PropsProgramsList {
  programs: programTemplate[];
  addExercise: (
    exercise: exerciseTemplate,
    additionalParameters?: AdditionalParameters | undefined
  ) => void;
  copyExercises: (exerciseTemplates: programTemplateExerciseTemplate[]) => void
}

export function ProgramsList({ programs, addExercise, copyExercises }: PropsProgramsList) {
  const { t } = useTranslation("programsBankPage.programsBank");

  programs.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <ListLoader
      items={programs}
      renderItem={(item) => <CopyProgramItem copyExercises={copyExercises} addExercise={addExercise} key={item.id} program={item} />}
      emptyArrayText={t("noPrograms")}
    />
  );
}
