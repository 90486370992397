import { Col, Modal, Popconfirm, Row } from "antd";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { useState } from "react";
import { useFormik } from "formik";
import { LabelInfoEdit } from "../../../../ExerciseBank/components/ExerciseColumn/components/LabelInfoEdit";
import { ExistWrapper, RoundButton } from "../../../../../../../../components";
import {
  EditFolderTypes,
  EditFolderSchema,
  initEditFolderFormData,
} from "./EditFolder.utils";
import { useRefreshQuery } from "../../../../../../../../hooks/useRefreshQuery";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../../../../../services/notificationService";
import { useMyProgramsContext } from "../../../../../../../../hooks/useMyPrograms";
import {
  useDeleteFolder,
  useGetFolder,
  useUpdateFolder,
} from "../../../../../../../../hooks/api/Folders";

interface MyProgramsFooterProps {
  folderId: number;
}

export function MyProgramsFooter({ folderId }: MyProgramsFooterProps) {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");
  const { t: tNotification } = useTranslation("notificationText");

  const { data: folder } = useGetFolder(folderId);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const { onRefresh } = useRefreshQuery(["getFolders"]);
  const { setSelectedFolderId } = useMyProgramsContext();

  const { mutate } = useDeleteFolder();

  const deleteMyFolder = () => {
    mutate(folderId, {
      onSuccess: () => {
        setSelectedFolderId(null);
        onRefresh();
        DisplaySuccessNotification(`${tNotification("successDeleteFolder")}`);
      },
      onError: () => {
        DisplayErrorNotification(tNotification("errorDeleteFolder"));
      },
    });
  };

  return (
    <div style={{ marginTop: "42px" }}>
      <ExistWrapper
        item={folder}
        renderItem={(existingFolder) => (
          <EditModal
            folderId={folderId}
            setIsModalVisible={setIsModalVisible}
            isModalVisible={isModalVisible}
            initSubcategory={initEditFolderFormData(existingFolder)}
          />
        )}
      />

      <Row
        style={{
          justifyContent: "center",
          backgroundColor: "white",
          position: "absolute",
          bottom: "10px",
          left: "10px",
          right: "10px",
          zIndex: 2,
        }}
      >
        <Col span={12} style={{ padding: "5px" }}>
          <Popconfirm
            title={t("deleteFolderTitle")}
            onConfirm={deleteMyFolder}
            okText={t("removeConfirm")}
            cancelText={t("cancel")}
            icon={false}
          >
            <RoundButton title={t("removeSubcategory")} typeColor="secondary" />
          </Popconfirm>
        </Col>
        <Col span={12} style={{ padding: "5px" }}>
          <RoundButton
            title={t("editSubcategory")}
            onClick={() => setIsModalVisible(true)}
          />
        </Col>
      </Row>
    </div>
  );
}

interface EditModalProps {
  initSubcategory: EditFolderTypes;
  folderId: number;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditModal = ({
  initSubcategory,
  folderId,
  isModalVisible,
  setIsModalVisible,
}: EditModalProps) => {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");
  const { t: tNotification } = useTranslation("notificationText");

  const { onRefresh } = useRefreshQuery(["getFolders"]);

  const { mutate, isLoading } = useUpdateFolder();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initSubcategory,
      validationSchema: EditFolderSchema,
      enableReinitialize: true,
      onSubmit: (values: EditFolderTypes) => {
        mutate(
          { id: folderId, reqData: values },
          {
            onSuccess: () => {
              onRefresh();
              DisplaySuccessNotification(
                `${tNotification("successUpdateFolder")}`
              );
              setIsModalVisible(false);
            },
            onError: () => {
              DisplayErrorNotification(tNotification("errorUpdateFolder"));
            },
          }
        );
      },
    });

  return (
    <Modal
      title={t("editSubcategory")}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={[
        <RoundButton
          title={t("cancel")}
          typeColor="secondary"
          onClick={() => setIsModalVisible(false)}
          style={{ width: "150px" }}
        />,
        <RoundButton
          title={t("save")}
          loading={isLoading}
          onClick={() => handleSubmit()}
          style={{ width: "150px" }}
        />,
      ]}
    >
      <LabelInfoEdit
        label={t("name")}
        value={values.name}
        onChange={handleChange("name")}
        onBlur={handleBlur("name")}
        errorText={errors.name && touched.name ? errors.name : undefined}
      />
    </Modal>
  );
};
