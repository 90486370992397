import { Row, Typography } from "antd";
import { PackItem, QueryLoader } from "../../../../../../../components";
import { useBoughtProgramsBank } from "../../../../../../../hooks/useBoughtProgramsBank";
import { useGetPacksList } from "../../../../../../../hooks/api/Packs";
import { useTranslation } from "../../../../../../../hooks/useTranslation";

const { Title } = Typography;

export function PacksColumn() {
  const { selectedPackId, setSelectedPackId } = useBoughtProgramsBank();
  const { data: packList, isLoading, isError } = useGetPacksList();
  const { t } = useTranslation("programPacks");

  return (
    <Row className="exercise-bank-categories-container">
      <QueryLoader
        isError={isError}
        isLoading={isLoading}
        item={packList}
        component={(packList) => (
          <>
            {packList?.map((pack) => (
              <PackItem
                key={pack.id}
                name={pack.name}
                isActive={selectedPackId === pack.id}
                onClick={() => {
                  setSelectedPackId(pack.id);
                }}
              />
            ))}
            {(!packList || packList?.length === 0) && (
              <Title level={4} style={{ margin: 10 }}>
                {t("noPacks")}
              </Title>
            )}
          </>
        )}
      />
    </Row>
  );
}
