import { Row, Col, Typography, Image } from "antd";
import { useMemo } from "react";
import { ArrowIcon } from "../../assets";
import { exerciseTemplate } from "../../hooks/api/ExerciseTemplates";
import { useExerciseImage } from "../../hooks/useExerciseImage";
import { calcualteRestTime } from "../../utils/timeConverter";

interface PropsExerciseWithParametersItem {
  exercise: exerciseTemplate;
  isActive: boolean;
  onClick: () => void;
}

const { Title } = Typography;

export function ExerciseWithParametersItem({
  exercise,
  isActive,
  onClick,
}: PropsExerciseWithParametersItem) {
  const {
    id,
    name,
    imageUrl,
    repetitions,
    sets,
    time,
    equipment,
    weight,
    tempo1,
    tempo2,
    tempo3,
    tempo4,
    repetitionMaximum,
    restTime,
  } = exercise;

  const { image, fallback } = useExerciseImage(id, imageUrl);
  const calculatedRestTime = useMemo(
    () => restTime && calcualteRestTime(restTime),
    [restTime]
  );

  return (
    <Row
      style={{
        borderColor: "black",
        borderWidth: isActive ? "1px" : "0px",
        borderStyle: "solid",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "10px 0",
        padding: "5px 10px",
        backgroundColor: isActive ? "#E4E4E4" : "",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Col span={5} style={{ display: "flex", alignItems: "center" }}>
        <Image
          width={60}
          height={60}
          src={image}
          preview={false}
          fallback={fallback}
        />
      </Col>
      <Col style={{ display: "flex", flexDirection: "column" }} span={16}>
        <Title
          level={5}
          ellipsis={{
            rows: 1,
          }}
          style={{ marginBottom: 0 }}
        >
          {name}
        </Title>
        <Col>
          {repetitions && sets ? `| ${repetitions} x ${sets}` : null}
          {time ? ` | ${time}` : null}
          {equipment ? ` | ${equipment}` : null}
          {weight ? ` | ${weight} kg` : null}
          {tempo1 || tempo2 || tempo3 || tempo4
            ? ` | @ ${tempo1 !== null ? tempo1 : " "} ${
                tempo2 !== null ? tempo2 : " "
              } ${tempo3 !== null ? tempo3 : " "} ${
                tempo4 !== null ? tempo4 : " "
              }`
            : null}
          {repetitionMaximum ? ` | ${repetitionMaximum}% of 1 RM` : null}
          {restTime ? ` | rest ${calculatedRestTime}` : null}
        </Col>
      </Col>
      <Col span={2}>
        <ArrowIcon />
      </Col>
    </Row>
  );
}
