import React from "react";
import { LoginForm } from "./LoginForm";
import { LoginImg } from "../../../assets/index";
import { Row } from "antd";
import "../../../css/public.css";

export function Login() {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img style={{ height: "100vh" }} src={LoginImg} alt="logo" />

      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          overflowY: "auto",
          height: "100vh",
        }}
        className="row-public-index"
      >
        <LoginForm />
      </Row>
    </div>
  );
}
