import { i18n } from "../../../../../../../../i18n";
import * as Yup from "yup";
import {
  EditExerciseTemplateEntry,
  exerciseTemplate,
} from "../../../../../../../../hooks/api/ExerciseTemplates";
import { calcualteRestTime } from "../../../../../../../../utils/timeConverter";

export type EditExerciseTemplateType = Omit<
  EditExerciseTemplateEntry,
  "restTime"
> & {
  restTime: string;
};

export const initEditExerciseFormData = (
  e: exerciseTemplate
): EditExerciseTemplateType => ({
  name: e.name,
  description: e.description,
  instructions: e.instructions,
  exerciseSubcategoryId: e.exerciseSubcategoryId,
  exerciseSubSubcategoryId: e.exerciseSubSubcategoryId,
  frontalPlane: e.frontalPlane,
  sagittalPlane: e.sagittalPlane,
  transversePlane: e.transversePlane,
  repetitions: e.repetitions,
  sets: e.sets,
  time: e.time,
  equipment: e.equipment,
  weight: e.weight ? Number(e.weight) : null,
  tempo1: e.tempo1,
  tempo2: e.tempo2,
  tempo3: e.tempo3,
  tempo4: e.tempo4,
  repetitionMaximum: e.repetitionMaximum,
  restTime: calcualteRestTime(e.restTime ?? 0),
});

export const EditExerciseSchema: Yup.SchemaOf<EditExerciseTemplateType> =
  Yup.object()
    .shape({
      name: Yup.string().required(
        i18n.t("validators.required", { ns: "translation" })
      ),
      description: Yup.string().nullable(),
      exerciseSubcategoryId: Yup.number().required(
        i18n.t("validators.required", { ns: "translation" })
      ),
      frontalPlane: Yup.boolean().required(
        i18n.t("validators.required", { ns: "translation" })
      ),
      sagittalPlane: Yup.boolean().required(
        i18n.t("validators.required", { ns: "translation" })
      ),
      transversePlane: Yup.boolean().required(
        i18n.t("validators.required", { ns: "translation" })
      ),
      instructions: Yup.string().nullable(),
      repetitions: Yup.string()
        .min(
          0,
          `${i18n.t("validators.minValueIs", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          100,
          `${i18n.t("validators.maxValueIs", {
            maxValue: 100,
            ns: "translation",
          })}`
        )
        .nullable(),
      sets: Yup.number()
        .typeError(i18n.t("validators.numberRequired", { ns: "translation" }))
        .min(
          0,
          `${i18n.t("validators.minValueIs", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          100000,
          `${i18n.t("validators.maxValueIs", {
            maxValue: 100000,
            ns: "translation",
          })}`
        )

        .integer(i18n.t("validators.integer", { ns: "translation" }))
        .nullable(),
      time: Yup.string()
        .min(
          0,
          `${i18n.t("validators.minValueIs", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          100,
          `${i18n.t("validators.maxValueIs", {
            maxValue: 100,
            ns: "translation",
          })}`
        )
        .nullable(),
      equipment: Yup.string()
        .min(
          0,
          `${i18n.t("validators.minValueIs", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          100,
          `${i18n.t("validators.maxValueIs", {
            maxValue: 100,
            ns: "translation",
          })}`
        )
        .nullable(),
      weight: Yup.number()
        .typeError(i18n.t("validators.numberRequired", { ns: "translation" }))
        .min(
          0,
          `${i18n.t("validators.minValueIs", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          1000000,
          `${i18n.t("validators.maxValueIs", {
            maxValue: 1000000,
            ns: "translation",
          })}`
        )
        .nullable(),

      tempo1: Yup.string()
        .min(
          0,
          `${i18n.t("validators.minCharacters", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          2,
          `${i18n.t("validators.maxCharacters", {
            maxValue: 2,
            ns: "translation",
          })}`
        )
        .nullable(),
      tempo2: Yup.string()
        .min(
          0,
          `${i18n.t("validators.minCharacters", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          2,
          `${i18n.t("validators.maxCharacters", {
            maxValue: 2,
            ns: "translation",
          })}`
        )
        .nullable(),
      tempo3: Yup.string()
        .min(
          0,
          `${i18n.t("validators.minCharacters", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          2,
          `${i18n.t("validators.maxCharacters", {
            maxValue: 2,
            ns: "translation",
          })}`
        )
        .nullable(),
      tempo4: Yup.string()
        .min(
          0,
          `${i18n.t("validators.minCharacters", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          2,
          `${i18n.t("validators.maxCharacters", {
            maxValue: 2,
            ns: "translation",
          })}`
        )
        .nullable(),

      repetitionMaximum: Yup.number()
        .typeError(i18n.t("validators.numberRequired", { ns: "translation" }))
        .min(
          0,
          `${i18n.t("validators.minValueIs", {
            minValue: 0,
            ns: "translation",
          })}`
        )
        .max(
          100,
          `${i18n.t("validators.maxValueIs", {
            maxValue: 100,
            ns: "translation",
          })}`
        )
        .integer(i18n.t("validators.integer", { ns: "translation" }))
        .nullable(),
      restTime: Yup.string()
        .test(
          "lenght",
          i18n.t("validators.fiveChars", { ns: "translation" }),
          (val?: string) => !val || val.length === 5
        )
        .test(
          "symbol",
          i18n.t("validators.symbol", { ns: "translation" }),
          (val?: string) => !val || val[2] === ":"
        )
        .test(
          "maxMinutes",
          i18n.t("validators.maxMinutes", { ns: "translation" }),
          (val?: string) => !val || parseInt(val.slice(0, 2)) <= 60
        )
        .test(
          "maxSeconds",
          i18n.t("validators.maxSeconds", { ns: "translation" }),
          (val?: string) => !val || parseInt(val.slice(3, 5)) <= 59
        )
        .test(
          "maxValue",
          "Max 60:00",
          (val?: string) =>
            !val ||
            parseInt(val.slice(0, 2)) * 60 + parseInt(val.slice(3, 5)) <= 3600
        )
        .test(
          "onlyNumbers",
          i18n.t("validators.onlyNumbers", { ns: "translation" }),
          (val?: string) => !val || !isNaN(Number(val.slice(0, 2)))
        )
        .test(
          "onlyNumbers",
          i18n.t("validators.onlyNumbers", { ns: "translation" }),
          (val?: string) => !val || !isNaN(Number(val.slice(3, 5)))
        )
        .nullable(),
    })
    .noUnknown(true)
    .strict()
    .required();
