import { InputNumber, Typography, Row, InputNumberProps } from "antd";

interface LabelInputProps extends InputNumberProps {
  title: string;
  error: string | undefined;
}

export function LabelNumberInput({
  title,
  onChange,
  value,
  error,
}: LabelInputProps) {
  const { Text } = Typography;
  return (
    <Row className="component-row">
      <Text type="secondary">{title}</Text>
      <InputNumber onChange={onChange} value={value} controls={false} />
      {error && <Text type="danger">{error}</Text>}
    </Row>
  );
}
