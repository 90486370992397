import { useMemo } from "react";
import { Row } from "antd";
import { ExerciseCategoryItem } from "../../../../../../../components";
import { ExerciseCategory as CategoryEnum } from "../../../../../../../constants/enums";
import { useExerciseBank } from "../../../../../../../hooks/useExerciseBank";
import { ExerciseBankCategories } from "../../index";
import { useTranslation } from "../../../../../../../hooks/useTranslation";

export function CategoriesColumn() {
  const { selectedCategory, setSelectedCategory } = useExerciseBank();
  const { t } = useTranslation("exerciseCategories");

  const options = useMemo((): ExerciseBankCategories[] => {
    return [...Object.values(CategoryEnum), "myExercise"];
  }, []);

  return (
    <Row className="exercise-bank-categories-container">
      {options.map((item) => (
        <ExerciseCategoryItem
          key={item}
          name={t(item)}
          isActive={selectedCategory === item}
          onClick={() => {
            setSelectedCategory(item);
          }}
        />
      ))}
    </Row>
  );
}
