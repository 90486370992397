import { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { ExerciseCategory } from "../../constants/enums";
import { exerciseTemplate } from "./ExerciseTemplates";
import { API } from "../../services/API";

export type exerciseSubSubcategory = {
  description: string | null;
  id: number;
  imageUrl: string | null;
  name: string;
};

export type exerciseSubcategory = {
  id: number;
  name: string;
  imageUrl: string | null;
  description: string | null;
  category?: ExerciseCategory | null;
  trainerId?: number | null;
  exerciseSubsubcategories?: Array<exerciseSubSubcategory>;
  order: number;
};

export interface AddSubcategoryEntry {
  name: string;
  description: string;
  // category: ExerciseCategory | null;
  order: number;
}

export interface UpdateSubcategoryEntry {
  name: string;
  description?: string;
}

interface SubcategoriesViewer {
  category: ExerciseCategory;
  subcategories: Array<exerciseSubcategory>;
}

export type SubcategoryWithPrograms = exerciseSubcategory & {
  exerciseTemplates: exerciseTemplate[];
};

const getSubcategoriesByCategory = async () => {
  const url = `/subcategories/by-category`;
  const { data } = await API.get<{}, AxiosResponse<SubcategoriesViewer[]>>(url);
  return data;
};

export function useGetSubcategoriesByCategory() {
  return useQuery(["getSubcategoriesByCategory"], getSubcategoriesByCategory);
}

const getSubcategories = async () => {
  const url = `/subcategories`;
  const { data } = await API.get<{}, AxiosResponse<exerciseSubcategory[]>>(url);
  return data;
};

export function useGetSubcategories() {
  return useQuery(["getSubcategories"], getSubcategories);
}

const getMySubcategories = async () => {
  const url = `/subcategories/my`;
  const { data } = await API.get<{}, AxiosResponse<exerciseSubcategory[]>>(url);
  return data;
};

export function useGetMySubcategories() {
  return useQuery(["getMySubcategories"], getMySubcategories);
}

const getSubcategory = async (id: number) => {
  const url = `/subcategories/${id}`;
  const { data } = await API.get<{}, AxiosResponse<SubcategoryWithPrograms>>(
    url
  );
  return data;
};

export function useGetSubcategory(id: number) {
  return useQuery(["getSubcategory", id], () => getSubcategory(id), {
    enabled: !!id,
  });
}

const addSubcategory = async (reqData: AddSubcategoryEntry) => {
  const url = `/subcategories`;
  const { data } = await API.post(url, reqData);
  return data;
};

export function useAddSubcategory() {
  return useMutation(addSubcategory);
}

const editSubcategory = async ({
  subcategoryId,
  reqData,
}: {
  subcategoryId: number;
  reqData: UpdateSubcategoryEntry;
}) => {
  const url = `/subcategories/${subcategoryId}`;
  const { data } = await API.put(url, reqData);
  return data;
};

export function useEditSubcategory() {
  return useMutation(editSubcategory);
}

const deleteSubcategory = async ({ id }: { id: number }) => {
  const url = `/subcategories/${id}`;
  const { data } = await API.delete(url);
  return data;
};

export function useDeleteSubcategory() {
  return useMutation(deleteSubcategory);
}

// const addSubcategoryImage = async ({
//   id,
//   reqData,
// }: {
//   id: number;
//   reqData: FormData;
// }) => {
//   const url = `/subcategories/${id}/image`;
//   const { data } = await API.post<{ imageUrl: string }>(url, reqData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//   });
//   return data;
// };

// export function useAddSubcategoryImage() {
//   return useMutation(addSubcategoryImage);
// }

// const deleteSubcategoryImage = async ({ id }: { id: number }) => {
//   const url = `/subcategories/${id}/image`;
//   const { data } = await API.delete<{ imageUrl: string }>(url);
//   return data;
// };

// export function useDeleteSubcategoryImage() {
//   return useMutation(deleteSubcategoryImage);
// }
