import React, { useState, useEffect, useMemo, createContext } from "react";
import "antd/dist/antd.css";
import { SpinnerLoader } from "../components";
import { useGetSubscription, ExtenedSubscription } from "../hooks/api/Payments";

interface ContextProps {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  hasSubscription: boolean;
  lastSubscription: ExtenedSubscription | null;
}

export const SubscriptionContext = createContext<ContextProps | null>(null);

export function SubscriptionProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const [lastSubscription, setLastSubscription] =
    useState<ExtenedSubscription | null>(null);
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);

  const {
    data: subscription,
    isLoading,
    isError,
    isSuccess,
  } = useGetSubscription();

  useEffect(() => {
    if (!subscription) {
      return;
    }

    setLastSubscription(subscription.lastSubscription);
    setHasSubscription(subscription.isvalid);
  }, [subscription]);

  const subscriptionContext = useMemo(
    () => ({
      lastSubscription,
      hasSubscription,
      isLoading,
      isError,
      isSuccess,
    }),
    [lastSubscription, hasSubscription, isLoading, isError, isSuccess]
  );

  return (
    <SubscriptionContext.Provider value={subscriptionContext}>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "48%",
            left: "48%",
          }}
        >
          <SpinnerLoader />
        </div>
      ) : (
        children
      )}
    </SubscriptionContext.Provider>
  );
}
