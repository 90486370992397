import { AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { SubscriptionStatus } from "../../constants/enums";
import { API } from "../../services/API";
import { Subscription } from "./Trainers";

export type ExtenedSubscription = Subscription & { status: SubscriptionStatus };

interface SubscriptionData {
  lastSubscription: ExtenedSubscription | null;
  subscriptionsHistory: Subscription[];
  isvalid: boolean;
}

const getSubscription = async () => {
  const url = `/payments/subscription`;
  const { data } = await API.get<{}, AxiosResponse<SubscriptionData>>(url);
  return data;
};

export function useGetSubscription() {
  return useQuery(["getSubscription"], getSubscription);
}

const cancelSubscription = async () => {
  const url = `/payments/cancel`;
  const { data } = await API.post(url);
  return data;
};

export function useCancelSubscription() {
  return useMutation(cancelSubscription);
}
