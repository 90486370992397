import * as React from "react";
import { BoughtProgramsBankContext } from "../containers/Logged/ExerciseBankPage/components/BoughtProgramsBank";

export const useBoughtProgramsBank = () => {
  const context = React.useContext(BoughtProgramsBankContext);
  if (!context) {
    throw new Error(
      "BoughtProgramsBankContext must be within BoughtProgramsBankContextProvider"
    );
  }

  return context;
};
