import { useMemo, useState } from "react";
import { Col, Row, Typography } from "antd";
import "../../../../css/home.css";
import {
  QueryLoader,
  RoundButton,
  SpinnerLoader,
} from "../../../../components";
import { CartIcon, UpgradeIcon } from "../../../../assets";
import { useAuthState } from "../../../../hooks/useAuthState";
import { useGetTrainer } from "../../../../hooks/api/Trainers";
import dayjs from "dayjs";
import { useSubscriptionState } from "../../../../hooks/useSubscriptionState";
import { CancelSubscriptionModal } from "./CancelSubscriptionModal";
import { DATE_FORMAT } from "../../../../constants/date";
import { StatusColors } from "../../../../constants/colors";
import { SubscriptionStatus } from "../../../../constants/enums";
import { useTranslation } from "../../../../hooks/useTranslation";
const { Text } = Typography;

export function Subscription() {
  const { t } = useTranslation("homePage");
  const { user } = useAuthState();
  const { data: trainer, isLoading } = useGetTrainer(user?.id ?? 0);
  const { hasSubscription, lastSubscription } = useSubscriptionState();
  const [cancelModalvisible, setCancelModalVisible] = useState<boolean>(false);

  const durationType = useMemo(() => {
    if (!lastSubscription) return "";

    const timeInMs =
      new Date(lastSubscription.endAt).getTime() -
      new Date(lastSubscription.startAt).getTime();

    return timeInMs / 1000 / 60 / 60 / 24 > 31 ? t("perYear") : t("perMonth");
  }, [lastSubscription, t]);

  if (isLoading) return <SpinnerLoader />;

  if (!lastSubscription || !hasSubscription || !trainer) {
    return (
      <Col style={{ marginTop: "15px" }}>
        {t("noSubscriptionDescription")}
        <Col style={{ marginTop: "15px", width: "200px" }}>
          <RoundButton
            title={t("buySubscription")}
            onClick={() =>
              window.open(
                "https://fit4living.se/applikation/abonnemang-i-coach-buddy.html",
                "_blank"
              )
            }
            icon={
              <CartIcon
                width={15}
                style={{ marginRight: "7px", paddingTop: "3px" }}
              />
            }
          />
        </Col>
      </Col>
    );
  }

  return (
    <QueryLoader
      isLoading={isLoading}
      item={trainer}
      component={(trainer) => (
        <Row gutter={[16, 16]}>
          <Col span={10}>{t("typeOfSubscription")}</Col>
          <Col span={14}>
            <Row>
              <Text>{"Fit4Living Coach Buddy"}</Text>
              {/* <Button
              type="link"
              style={{ padding: 0, height: 0, paddingLeft: "7px" }}
            >
              {t("showDetails")}
            </Button> */}
            </Row>
          </Col>
          <Col span={10}>{t("amount")}</Col>
          <Col span={14}>
            {`${lastSubscription.price} ${lastSubscription.currency} ${durationType}`}
          </Col>
          <Col span={10}>{t("reniewalPayment")}</Col>
          <Col
            span={14}
            style={{ color: StatusColors[lastSubscription.status] }}
          >
            {lastSubscription.status}
          </Col>
          <Col span={10}>{t("periodTime")}</Col>
          <Col span={14}>
            {`${dayjs(new Date(lastSubscription.startAt)).format(
              DATE_FORMAT
            )} - ${dayjs(new Date(lastSubscription.endAt)).format(
              DATE_FORMAT
            )}`}
          </Col>
          <Col span={10}>{t("chargingTheFee")}</Col>
          <Col span={14}>
            {dayjs(new Date(lastSubscription.endAt)).format(DATE_FORMAT)}
          </Col>
          <Col span={10}>{t("amountOfClients")}</Col>
          <Col span={14}>
            <Text>
              <Text style={{ fontWeight: "bold" }}>
                {`${lastSubscription.clientsCount}`}
              </Text>
              {`(${t("yourCurrentAmount")} `}
              <Text style={{ fontWeight: "bold" }}>
                {`${trainer._count.clients}`}
              </Text>
              {`)`}
            </Text>
          </Col>
          <Col span={10} style={{ marginTop: "15px" }}>
            {lastSubscription.status === SubscriptionStatus.Active ? (
              <>
                <RoundButton
                  title={t("cancel")}
                  typeColor="secondary"
                  onClick={() => setCancelModalVisible(true)}
                />
                <CancelSubscriptionModal
                  visible={cancelModalvisible}
                  setVisible={setCancelModalVisible}
                />
              </>
            ) : null}
          </Col>
          <Col span={102} style={{ marginTop: "15px" }}>
            <RoundButton
              title={t("upgradeSubscription")}
              onClick={() =>
                window.open(
                  "https://fit4living.se/applikation/abonnemang-i-coach-buddy.html",
                  "_blank"
                )
              }
              icon={
                <UpgradeIcon
                  width={15}
                  style={{ marginRight: "7px", paddingTop: "3px" }}
                />
              }
            />
          </Col>
        </Row>
      )}
    />
  );
}
