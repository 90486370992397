import { Col, Row } from "antd";
import { createContext, useEffect, useMemo, useState } from "react";
import { CategoriesColumn } from "./components/CategoriesColumn";
import { ProgramsColumn } from "./components/ProgramsColumn";
import { ProgramInfoColumn } from "./components/ProgramInfoColumn";
import { ExerciseColumn } from "./components/ExerciseColumn";
import { ProgramCategory } from "../../../../../constants/enums";
import { exerciseTemplate } from "../../../../../hooks/api/ExerciseTemplates";

interface ContextProps {
  selectedCategory: ProgramCategory;
  setSelectedCategory: React.Dispatch<React.SetStateAction<ProgramCategory>>;
  selectedProgramId: number | null;
  setSelectedProgramId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedExercise: exerciseTemplate | null;
  setSelectedExercise: React.Dispatch<React.SetStateAction<exerciseTemplate | null>>;
}

export const FreeProgramsBankContext = createContext<ContextProps | null>(null);

export function FreeProgramsBank() {
  const [selectedCategory, setSelectedCategory] = useState<ProgramCategory>(
    ProgramCategory.Mobility
  );

  const [selectedProgramId, setSelectedProgramId] = useState<number | null>(
    null
  );

  const [selectedExercise, setSelectedExercise] = useState<exerciseTemplate | null>(
    null
  );

  useEffect(() => {
    setSelectedProgramId(null);
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedExercise(null);
  }, [selectedProgramId]);

  const contextValue = useMemo(
    () => ({
      selectedCategory,
      setSelectedCategory,
      selectedProgramId,
      setSelectedProgramId,
      selectedExercise,
      setSelectedExercise,
    }),
    [
      selectedCategory,
      setSelectedCategory,
      selectedProgramId,
      setSelectedProgramId,
      selectedExercise,
      setSelectedExercise,
    ]
  );

  return (
    <FreeProgramsBankContext.Provider value={contextValue}>
      <Row>
        <Col span={6}>
          <CategoriesColumn />
        </Col>
        <Col span={6}>
          <ProgramsColumn />
        </Col>
        <Col span={6}>
          <ProgramInfoColumn />
        </Col>
        <Col span={6}>
          <ExerciseColumn />
        </Col>
      </Row>
    </FreeProgramsBankContext.Provider>
  );
}
