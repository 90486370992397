export enum AccountType {
  Client = "client",
  Trainer = "trainer",
  Admin = "admin",
}

export enum AuthStatus {
  Pending = "pending",
  Error = "error",
  Success = "success",
}

export enum Gender {
  Male = "male",
  Female = "female",
  Helicopter = "other",
}

export enum Level {
  Beginner = "beginner",
  Intermediate = "intermediate",
  Advanced = "advanced",
}

export enum Weekdays {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum ProgramCategory {
  Mobility = "mobility",
  Strength = "strength",
  Endurance = "endurance",
  Speed = "speed",
  CoordinationBalance = "coordinationBalance",
  WorkingCapacity = "workingCapacity",
  AthleticCapacity = "athleticCapacity",
  SpecificGoal = "specificGoal",
  SportsMedicine = "sportsMedicine",
}

export enum ExerciseCategory {
  Mobility = "mobility",
  Strength = "strength",
  Endurance = "endurance",
  Speed = "speed",
  CoordinationBalance = "coordinationBalance",
  SportsMedicine = "sportsMedicine",
}

export enum RequestStatus {
  pending = "pending",
  accepted = "accepted",
  declined = "declined",
  resigned = "resigned",
}

export enum MediaType {
  image = "image",
  video = "video",
  comment = "comment",
}

export enum Country {
  Albania = "Albania",
  Andorra = "Andorra",
  Armenia = "Armenia",
  Austria = "Austria",
  Azerbaijan = "Azerbaijan",
  Belarus = "Belarus",
  Belgium = "Belgium",
  BosniaAndHerzegovina = "Bosnia and Herzegovina",
  Bulgaria = "Bulgaria",
  Croatia = "Croatia",
  Cyprus = "Cyprus",
  CzechRepublic = "Czech Republic",
  Denmark = "Denmark",
  Estonia = "Estonia",
  Finland = "Finland",
  France = "France",
  Georgia = "Georgia",
  Germany = "Germany",
  Greece = "Greece",
  Hungary = "Hungary",
  Iceland = "Iceland",
  Ireland = "Ireland",
  Italy = "Italy",
  Kazakhstan = "Kazakhstan",
  Kosovo = "Kosovo",
  Latvia = "Latvia",
  Liechtenstein = "Liechtenstein",
  Lithuania = "Lithuania",
  Luxembourg = "Luxembourg",
  Malta = "Malta",
  Moldova = "Moldova",
  Monaco = "Monaco",
  Montenegro = "Montenegro",
  Netherlands = "Netherlands",
  NorthMacedonia = "North Macedonia",
  Norway = "Norway",
  Poland = "Poland",
  Portugal = "Portugal",
  Romania = "Romania",
  Russia = "Russia",
  SanMarino = "SanMarino",
  Serbia = "Serbia",
  Slovakia = "Slovakia",
  Slovenia = "Slovenia",
  Spain = "Spain",
  Sweden = "Sweden",
  Switzerland = "Switzerland",
  Turkey = "Turkey",
  Ukraine = "Ukraine",
  UnitedKingdom = "United Kingdom",
  VaticanCity = "Vatican City",
}

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  Default = "default",
}
