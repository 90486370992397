import { SVGProps } from "react";

interface PlaneSwitchProps {
  label: string;
  icon: React.FunctionComponent<
    SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  checked: boolean;
  setChecked?: (checked: boolean) => void;
}

export function PlaneSwitch({
  label,
  icon: Icon,
  checked,
  setChecked,
}: PlaneSwitchProps) {
  return (
    <div
      onClick={
        !!setChecked
          ? () => {
              setChecked(!checked);
            }
          : undefined
      }
      style={{
        width: "80px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "15px 0",
        cursor: !!setChecked ? "pointer" : "default",
      }}
    >
      <Icon
        height={32}
        width={32}
        style={{}}
        color={checked ? "black" : "gray"}
      />

      <div
        style={{
          paddingTop: "5px",
          textAlign: "center",
          color: checked ? "black" : "gray",
          fontSize: "12px",
        }}
      >
        {label.toUpperCase().replace(" ", "\n")}
      </div>
    </div>
  );
}
