import { Col, Image, Row, Typography, Divider } from "antd";
import { Formik } from "formik";
import {
  initCreateProgramFormData,
  createProgramSchema,
  CreateProgramFormData,
} from "./components/AddNewProgram.utils";
import {
  LabelInput,
  LabelSelect,
  LabelNumberInput,
  LabelTextArea,
  RoundButton,
  UploadButton,
} from "../../../../../../../components";
import { useGetFolders } from "../../../../../../../hooks/api/Folders";
import {
  levelOptions,
  programCategoryOptionsCreateProgram,
} from "../../../../../../../constants/selectOptions";
import { useMyProgramsContext } from "../../../../../../../hooks/useMyPrograms";
import { useGenericProgramImage } from "../../../../../../../hooks/useGenericProgramImage";
import {
  useAddProgramTemplate,
  useAddProgramTemplateImage,
} from "../../../../../../../hooks/api/ProgramTemplates";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../../../../services/notificationService";
import { useState } from "react";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { useRefreshQuery } from "../../../../../../../hooks/useRefreshQuery";
import { AddIcon, CopyButtonIcon } from "../../../../../../../assets";
import { colors } from "../../../../../../../constants/colors";
import { programTemplateExerciseTemplateEdit } from "../../../../../../../hooks/useProgramExercises";
import { EditableExerciseList } from "./components/EditableExerciseList";

const { Text } = Typography;

interface PropsCreateNewProgram {
  exercises: Array<programTemplateExerciseTemplateEdit>;
  moveDown: (index: number) => void;
  moveUp: (index: number) => void;
  changeBreak: (index: number) => void;
}

export function CreateNewProgramColumn({
  exercises,
  moveDown,
  moveUp,
  changeBreak,
}: PropsCreateNewProgram) {
  const [image, setImage] = useState<FormData | null>(null);
  const [imageSrc, setImageSrc] = useState<string>("");
  const { t } = useTranslation("createProgram");
  const { t: tNotification } = useTranslation("notificationText");

  const {
    setIsCreatingNewProgram,
    setSelectedFolderId,
    setIsAddingExercises,
    setIsCopyingFromProgram,
    selectedFolderId,
  } = useMyProgramsContext();

  const { getImage } = useGenericProgramImage();
  const { onRefresh } = useRefreshQuery(["getFolders"]);

  const { mutate: addProgram } = useAddProgramTemplate();
  const { data, refetch } = useGetFolders();

  const foldersOptions = data.map((folder: any) => ({
    label: folder.name,
    value: folder.id,
  }));

  const { mutate: uploadImage } = useAddProgramTemplateImage();

  const addNewProgram = (values: CreateProgramFormData) => {
    addProgram(
      {
        ...values,
        exercises: exercises.map((e) => {
          return {
            id: e.id,
            order: e.order,
            breakBefore: e.breakBefore,
            exerciseParameters: {
              equipment: e.exerciseParameters?.equipment,
              repetitionMaximum: e.exerciseParameters?.repetitionMaximum,
              repetitions: e.exerciseParameters?.repetitions,
              restTime: e.exerciseParameters?.restTime,
              sets: e.exerciseParameters?.sets,
              tempo1: e.exerciseParameters?.tempo1,
              tempo2: e.exerciseParameters?.tempo2,
              tempo3: e.exerciseParameters?.tempo3,
              tempo4: e.exerciseParameters?.tempo4,
              time: e.exerciseParameters?.time,
              weight: e.exerciseParameters?.weight,
            },
          };
        }),
      },
      {
        onSuccess: (data) => {
          image ? uploadImageFile(data.id) : onRefresh();
          setIsCreatingNewProgram(false);
          setSelectedFolderId(values.folderId);
          DisplaySuccessNotification(
            `${tNotification("successCreateProgram")}`
          );
        },
        onError: () => {
          DisplayErrorNotification(tNotification("errorCreateProgram"));
        },
      }
    );
  };

  const uploadImageFile = (id: number) => {
    image &&
      uploadImage(
        {
          id,
          reqData: image,
        },
        {
          onSuccess: async () => {
            await refetch();
            onRefresh();
            DisplaySuccessNotification(
              `${tNotification("successUpdateProgramImg")}`
            );
          },
          onError: () => {
            DisplayErrorNotification(tNotification("errorUpdateProgramImg"));
          },
        }
      );
  };

  return (
    <div className="program-bank-container">
      <Formik
        initialValues={{
          ...initCreateProgramFormData,
          folderId: selectedFolderId || data[0]?.id,
        }}
        validationSchema={createProgramSchema}
        onSubmit={addNewProgram}
      >
        {({ handleChange, setFieldValue, handleSubmit, values, errors }) => (
          <>
            <Row style={{ position: "relative" }}>
              <Image
                preview={false}
                src={getImage(imageSrc, values.category)}
              />
              <div
                style={{ position: "absolute", right: "10px", bottom: "10px" }}
              >
                <UploadButton
                  type={"image"}
                  text={t("editPhoto")}
                  uploadFile={(c, f) => {
                    setImage(f);
                    // @ts-ignore
                    setImageSrc(URL.createObjectURL(f.get("image")));
                  }}
                />
              </div>
            </Row>
            <Row style={{ flexDirection: "column", margin: "20px 20px 15px" }}>
              <LabelInput
                title={t("programName")}
                value={values.name}
                onChange={handleChange("name")}
                error={errors.name}
              />
              <LabelSelect
                items={programCategoryOptionsCreateProgram}
                onChange={(category) => setFieldValue("category", category)}
                title={t("category")}
                error={errors.category}
              />
              <LabelSelect
                items={foldersOptions}
                onChange={(id) => setFieldValue("folderId", id)}
                title={t("folders")}
                error={errors.folderId}
                placeholder={
                  foldersOptions.length === 0 ? "add your first folder" : ""
                }
                // @ts-ignore
                defaultValue={values.folderId}
              />
              <LabelSelect
                items={levelOptions}
                onChange={(level) => setFieldValue("level", level)}
                title={t("level")}
                error={errors.level}
              />
              <LabelNumberInput
                title={t("length")}
                onChange={(e) => setFieldValue("length", e)}
                value={values.length}
                error={errors.length}
              />
              <LabelInput
                title={t("eq")}
                onChange={handleChange("equipmentNeeded")}
                value={values.equipmentNeeded}
                error={errors.equipmentNeeded}
              />
              <LabelTextArea
                value={values.description}
                title={t("description")}
                onChange={handleChange("description")}
                error={errors.description}
              />
              <Row style={{ marginBottom: "30px" }}>
                <Col
                  onClick={() => {
                    setIsAddingExercises(true);
                    setIsCopyingFromProgram(false);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <AddIcon width={30} height={30} />
                  <Text
                    style={{
                      marginLeft: "20px",
                      color: colors.primary,
                      fontWeight: "bold",
                    }}
                  >
                    {t("addExercise")}
                  </Text>
                </Col>
              </Row>
              <Row style={{ marginBottom: "30px" }}>
                <Col
                  onClick={() => {
                    setIsCopyingFromProgram(true);
                    setIsAddingExercises(false);
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <CopyButtonIcon width={30} height={30} />
                  <Text
                    style={{
                      marginLeft: "20px",
                      color: colors.primary,
                      fontWeight: "bold",
                    }}
                  >
                    {t("copyProgramFrom")}
                  </Text>
                </Col>
              </Row>
              <EditableExerciseList
                exercises={exercises}
                changeBreak={changeBreak}
                moveUp={moveUp}
                moveDown={moveDown}
              />
              <Divider style={{ height: "2px", backgroundColor: "darkgray" }} />
              <Row
                style={{ alignItems: "center", justifyContent: "space-around" }}
              >
                <Text
                  style={{
                    color: "#1ba8f2",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsCreatingNewProgram(false)}
                >
                  {t("cancel")}
                </Text>
                <RoundButton
                  style={{ width: "80px" }}
                  title={t("save")}
                  typeColor="secondary"
                  onClick={() => handleSubmit()}
                />
              </Row>
            </Row>
          </>
        )}
      </Formik>
    </div>
  );
}
