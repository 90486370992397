import { Gender, Country } from "../../../constants/enums";
import * as Yup from "yup";
import i18n from "i18next";
import dayjs from "dayjs";
import { TrainerDto } from "../../../hooks/api/Trainers";

export const signupInitValues: TrainerDto = {
  name: "",
  surname: "",
  email: "",
  password: "",
  confirmPassword: "",
  phone: "",
  gender: Gender.Male,
  birthDate: new Date(),
  city: "",
  country: Country.Albania,
  discipline: "",
  price: 0,
  languages: "",
  description: "",
  aboutMe: "",
  policy: false,
  termsOfUse: false,
  trainerRegulations: false,
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const strongPasswordRegExp =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

export const signupSchema: Yup.SchemaOf<TrainerDto> = Yup.object()
  .shape({
    name: Yup.string()
      .min(2, i18n.t("validators.tooShort", { ns: "translation" }))
      .max(50, i18n.t("validators.tooLong", { ns: "translation" }))
      .required(i18n.t("validators.required", { ns: "translation" })),
    surname: Yup.string()
      .min(2, i18n.t("validators.tooShort", { ns: "translation" }))
      .max(50, i18n.t("validators.tooLong", { ns: "translation" }))
      .required(i18n.t("validators.required", { ns: "translation" })),
    email: Yup.string()
      .email(i18n.t("validators.email", { ns: "translation" }))
      .required(i18n.t("validators.required", { ns: "translation" })),
    password: Yup.string()
      .matches(
        strongPasswordRegExp,
        i18n.t("validators.passwordTooWeak", { ns: "translation" })
      )
      .required(i18n.t("validators.required", { ns: "translation" })),
    confirmPassword: Yup.string()
      .when("password", {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          i18n.t("validators.diffrentPasswords", { ns: "translation" })
        ),
      })
      .required(i18n.t("validators.required", { ns: "translation" })),
    phone: Yup.string()
      .matches(phoneRegExp, i18n.t("validators.phone", { ns: "translation" }))
      .required(i18n.t("validators.required", { ns: "translation" })),
    gender: Yup.mixed<Gender>()
      .oneOf(Object.values(Gender))
      .required(i18n.t("validators.required", { ns: "translation" })),
    birthDate: Yup.date()
      .required(i18n.t("validators.required", { ns: "translation" }))
      .test(
        "birthDate",
        i18n.t("validators.futureDate", { ns: "translation" }),
        function (value) {
          return dayjs(value).isBefore(dayjs());
        }
      ),
    city: Yup.string()
      .min(2, i18n.t("validators.tooShort", { ns: "translation" }))
      .max(50, i18n.t("validators.tooLong", { ns: "translation" }))
      .required(i18n.t("validators.required", { ns: "translation" })),
    country: Yup.mixed<Country>()
      .oneOf(Object.values(Country))
      .required(i18n.t("validators.required", { ns: "translation" })),
    discipline: Yup.string().required(
      `${i18n.t("validators.required", { ns: "translation" })}`
    ),
    price: Yup.number()
      .typeError(
        `${i18n.t("validators.numberRequired", { ns: "translation" })}`
      )
      .min(
        1,
        `${i18n.t("validators.minValueIs", {
          minValue: 1,
          ns: "translation",
        })}`
      )
      .max(
        99999,
        `${i18n.t("validators.maxValueIs", {
          maxValue: 99999,
          ns: "translation",
        })}`
      )
      .required(`${i18n.t("validators.required", { ns: "translation" })}`),
    languages: Yup.string().required(
      `${i18n.t("validators.required", { ns: "translation" })}`
    ),
    description: Yup.string().required(
      `${i18n.t("validators.required", { ns: "translation" })}`
    ),
    aboutMe: Yup.string().required(
      `${i18n.t("validators.required", { ns: "translation" })}`
    ),
    policy: Yup.boolean().oneOf(
      [true],
      i18n.t("validators.required", { ns: "translation" })
    ),
    termsOfUse: Yup.boolean().oneOf(
      [true],
      i18n.t("validators.required", { ns: "translation" })
    ),
    trainerRegulations: Yup.boolean().oneOf(
      [true],
      i18n.t("validators.required", { ns: "translation" })
    ),
  })
  .noUnknown(true)
  .strict()
  .required();
