import {
  ExerciseCategoryItem,
  ListLoader,
} from "../../../../../../../../components";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { useMyProgramsContext } from "../../../../../../../../hooks/useMyPrograms";
import { Folders } from "../../../../../../../../hooks/api/Folders";

interface PropsFoldersList {
  folders: Folders[];
}

export function FoldersList({ folders }: PropsFoldersList) {
  const { t } = useTranslation("myProgramsPage");
  const { selectedFolderId, setSelectedFolderId } = useMyProgramsContext();

  return (
    <ListLoader
      items={folders}
      renderItem={(item) => (
        <ExerciseCategoryItem
          key={item.id}
          name={item.name}
          isActive={selectedFolderId === item.id}
          onClick={() => setSelectedFolderId(item.id)}
        />
      )}
      emptyArrayText={t("noFolders")}
    />
  );
}
