import { Col, Row, Typography } from "antd";
import { useAddExerciseTemplate } from "../../../../../../../../hooks/api/ExerciseTemplates";
import {
  FrontalPlaneIcon,
  SagittalPlaneIcon,
  TransversePlaneIcon,
} from "../../../../../../../../assets";
import { restTimeInSeconds } from "../../../../../../../../utils/timeConverter";
import { RoundButton } from "../../../../../../../../components";
import { LabelInfoEdit } from "./LabelInfoEdit";
import { PlaneSwitch } from "./PlaneSwitch";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { Formik } from "formik";
import { colors } from "../../../../../../../../constants/colors";
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from "../../../../../../../../services/notificationService";
import { useRefreshQuery } from "../../../../../../../../hooks/useRefreshQuery";

import {
  AddExerciseSchema,
  AddExerciseTemplateType,
  initAddExerciseFormData,
} from "./ExerciseAdd.utils";
import { useEffect, useState } from "react";

const { Title, Text } = Typography;

interface ExerciseditProps {
  subcategoryId: number;
}

export function ExerciseAdd({ subcategoryId }: ExerciseditProps) {
  const { t } = useTranslation("exerciseBankPage.exerciseBank");
  const { t: tNotification } = useTranslation("notificationText");

  const { mutate: add } = useAddExerciseTemplate();
  const { onRefresh } = useRefreshQuery(["getSubcategory"]);

  const addExerciseTemplate = (values: AddExerciseTemplateType) => {
    add(
      {
        ...values,
        isFree: false,
        exerciseSubcategoryId: subcategoryId,
        restTime: restTimeInSeconds(values.restTime),
      },
      {
        onSuccess: async (data) => {
          onRefresh();
          DisplaySuccessNotification(
            `${tNotification("successUpdateExercise")}`
          );
        },
        onError: (error) => {
          DisplayErrorNotification(tNotification("errorUpdateExercise"));
        },
      }
    );
  };

  const [defaultWeight, setDefaultWeight] = useState(
    initAddExerciseFormData.weight
      ? Number(initAddExerciseFormData.weight)
      : null
  );

  useEffect(() => {
    setDefaultWeight(
      initAddExerciseFormData.weight
        ? Number(initAddExerciseFormData.weight)
        : null
    );
  }, [initAddExerciseFormData]);

  return (
    <div style={{ marginTop: "20px" }}>
      <Formik
        enableReinitialize
        initialValues={initAddExerciseFormData}
        validationSchema={AddExerciseSchema}
        onSubmit={addExerciseTemplate}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
          handleBlur,
          setFieldValue,
        }) => (
          <>
            <Row
              style={{
                justifyContent: "center",
                position: "absolute",
                top: "10px",
                left: "10px",
                right: "10px",
                backgroundColor: colors.textSecondary,
              }}
            >
              <Text>{t("creatingNewExercise").toUpperCase()}</Text>
            </Row>
            <Title
              level={3}
              editable={{
                onChange: handleChange("name"),
              }}
            >
              {values.name}
            </Title>

            <Row
              style={{
                justifyContent: "space-between",
              }}
            >
              <PlaneSwitch
                label={"sagittal Plane"}
                icon={FrontalPlaneIcon}
                checked={values.sagittalPlane}
                setChecked={(e) => setFieldValue("sagittalPlane", e)}
              />
              <PlaneSwitch
                label={"frontal Plane"}
                icon={SagittalPlaneIcon}
                checked={values.frontalPlane}
                setChecked={(e) => setFieldValue("frontalPlane", e)}
              />
              <PlaneSwitch
                label={"transversal Plane"}
                icon={TransversePlaneIcon}
                checked={values.transversePlane}
                setChecked={(e) => setFieldValue("transversePlane", e)}
              />
            </Row>

            <Title level={5}>{t("description")}</Title>
            <Text
              editable={{
                onChange: handleChange("description"),
              }}
            >
              {values.description}
            </Text>

            <Title level={5}>{t("instructions")}</Title>
            <Text
              editable={{
                onChange: handleChange("instructions"),
              }}
            >
              {values.instructions}
            </Text>

            <Row style={{ padding: "10px 0px" }}>
              <Col span={7}>
                <LabelInfoEdit
                  label={t("reps")}
                  onChange={handleChange("repetitions")}
                  onBlur={handleBlur("repetitions")}
                  errorText={
                    errors.repetitions && touched.repetitions
                      ? errors.repetitions
                      : undefined
                  }
                  value={values.repetitions}
                />
              </Col>
              <Col
                span={3}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  paddingTop: "20px",
                  fontSize: "20px",
                }}
              >
                X
              </Col>
              <Col span={4}>
                <LabelInfoEdit
                  label={t("sets")}
                  onChange={(e) =>
                    setFieldValue("sets", e === "" ? null : Number(e))
                  }
                  onBlur={handleBlur("sets")}
                  errorText={
                    errors.sets && touched.sets ? errors.sets : undefined
                  }
                  value={values.sets}
                />
              </Col>
              <Col span={3} />
              <Col span={5}>
                <LabelInfoEdit
                  label={t("time")}
                  onChange={handleChange("time")}
                  onBlur={handleBlur("time")}
                  errorText={
                    errors.time && touched.time ? errors.time : undefined
                  }
                  value={values.time}
                />
              </Col>
            </Row>

            <Row style={{ padding: "10px 0px" }}>
              <Col span={14}>
                <LabelInfoEdit
                  label={t("equipment")}
                  onChange={handleChange("equipment")}
                  onBlur={handleBlur("equipment")}
                  errorText={
                    errors.equipment && touched.equipment
                      ? errors.equipment
                      : undefined
                  }
                  value={values.equipment}
                />
              </Col>
              <Col span={10}>
                <LabelInfoEdit
                  label={t("kg")}
                  onChange={(e) =>
                    setFieldValue(
                      "weight",
                      e === "" ? null : Number(e.replace(",", "."))
                    )
                  }
                  onBlur={handleBlur("weight")}
                  errorText={
                    errors.weight && touched.weight ? errors.weight : undefined
                  }
                  defaultValue={values.weight}
                  key={defaultWeight}
                />
              </Col>
            </Row>

            <div style={{ color: colors.textSecondary }}>{t("tempo")}</div>

            <Row style={{ padding: "10px 0px" }}>
              <Col
                span={4}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  fontSize: "23px",
                }}
              >
                @
              </Col>
              <Col span={5}>
                <LabelInfoEdit
                  onChange={handleChange("tempo1")}
                  onBlur={handleBlur("tempo1")}
                  errorText={
                    errors.tempo1 && touched.tempo1 ? errors.tempo1 : undefined
                  }
                  value={values.tempo1}
                />
              </Col>
              <Col span={5}>
                <LabelInfoEdit
                  onChange={handleChange("tempo2")}
                  onBlur={handleBlur("tempo2")}
                  errorText={
                    errors.tempo2 && touched.tempo2 ? errors.tempo2 : undefined
                  }
                  value={values.tempo2}
                />
              </Col>
              <Col span={5}>
                <LabelInfoEdit
                  onChange={handleChange("tempo3")}
                  onBlur={handleBlur("tempo3")}
                  errorText={
                    errors.tempo3 && touched.tempo3 ? errors.tempo3 : undefined
                  }
                  value={values.tempo3}
                />
              </Col>
              <Col span={5}>
                <LabelInfoEdit
                  onChange={handleChange("tempo4")}
                  onBlur={handleBlur("tempo4")}
                  errorText={
                    errors.tempo4 && touched.tempo4 ? errors.tempo4 : undefined
                  }
                  value={values.tempo4}
                />
              </Col>
            </Row>
            <Row style={{ padding: "10px 0px" }}>
              <Col span={12}>
                <LabelInfoEdit
                  label={t("rmSets")}
                  onChange={(e) =>
                    setFieldValue(
                      "repetitionMaximum",
                      e === "" ? null : Number(e)
                    )
                  }
                  onBlur={handleBlur("repetitionMaximum")}
                  errorText={
                    errors.repetitionMaximum && touched.repetitionMaximum
                      ? errors.repetitionMaximum
                      : undefined
                  }
                  value={values.repetitionMaximum}
                />
              </Col>
              <Col span={12}>
                <LabelInfoEdit
                  label={t("rest")}
                  onChange={(e: any) => {
                    const restTimeValue = e.length === 2 ? e + ":" : e;
                    setFieldValue("restTime", restTimeValue);
                  }}
                  // onBlur={handleBlur("restTime")}
                  errorText={
                    errors.restTime && touched.restTime
                      ? errors.restTime
                      : undefined
                  }
                  value={values.restTime}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12} style={{ padding: "5px" }}>
                <RoundButton title={t("save")} onClick={() => handleSubmit()} />
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </div>
  );
}
