import { API } from "../../services/API";
import { useQuery } from "react-query";
import { AxiosResponse } from "axios";
import { exerciseTemplate } from "./ExerciseTemplates";

// const getAdminSubSubcategories = async (reqData) => {
//   const url = `/admin/subsubcategories?${reqData.size && `size=${reqData.size}`}${
//     reqData.name && `&name=${reqData.name}`
//   }${reqData.subcategory && `&subcategory=${reqData.subcategory}`}`;
//   const { data } = await API.get(url);
//   return data;
// };

// export function useGetAdminSubSubcategories(reqData) {
//   return useQuery(["getAdminSubSubcategories", reqData], () => getAdminSubSubcategories(reqData));
// }

// const getSubSubcategories = async (reqData) => {
//   const url = `/subsubcategories?${reqData.name && `&name=${reqData.name}`}${
//     reqData.subcategory && `&subcategory=${reqData.subcategory}`
//   }`;
//   const { data } = await API.get(url);
//   return data;
// };

// export function useGetSubSubcategories(reqData) {
//   return useQuery(["getSubSubcategories", reqData], () => getSubSubcategories(reqData));
// }

export interface SubSubCategoryViewer {
  description: string;
  exerciseTemplates: exerciseTemplate[];
  id: number;
  imageUrl: string | null;
  isDeleted: boolean;
  name: string;
  order: number;
  parentSubSubcategoryId: number | null;
  subcategoryId: number;
}

const getSubSubcategory = async (id: number) => {
  const url = `/subsubcategories/${id}`;
  const { data } = await API.get<unknown, AxiosResponse<SubSubCategoryViewer>>(
    url
  );
  return data;
};

export function useGetSubSubcategory(id: number) {
  return useQuery(["getSubSubcategory", id], () => getSubSubcategory(id), {
    enabled: !!id,
  });
}

const getSubSubcategories = async (reqData: {
  name: string;
  subcategory: string;
}) => {
  const url = `/subsubcategories`;
  const { data } = await API.get(url, {
    params: { name: reqData.name, subcategory: reqData.subcategory },
  });
  return data;
};

export function useGetSubSubcategories(reqData: {
  name: string;
  subcategory: string;
}) {
  return useQuery(["getSubSubcategories", reqData], () =>
    getSubSubcategories(reqData)
  );
}

// const addSubSubcategory = async (reqData) => {
//   const url = "/subsubcategories";
//   const { data } = await API.post(url, reqData);
//   return data;
// };

// export function useAddSubSubcategories() {
//   return useMutation(addSubSubcategory);
// }

// const updateSubSubcategory = async ({ id, reqData }) => {
//   const url = `/subsubcategories/${id}`;
//   const { data } = await API.put(url, reqData);
//   return data;
// };

// export function useUpdateSubSubcategory() {
//   return useMutation(updateSubSubcategory);
// }

// const deleteSubSubcateogry = async ({ id }) => {
//   const url = `/subsubcategories/${id}`;
//   const { data } = await API.delete(url);
//   return data;
// };

// export function useDeleteSubSubcategory() {
//   return useMutation(deleteSubSubcateogry);
// }

// const addSubSubcategoryImage = async ({ id, reqData, config }) => {
//   const url = `/subsubcategories/${id}/image`;
//   const { data } = await API.post(url, reqData, config);
//   return data;
// };

// export function useAddSubSubcategoryImage() {
//   return useMutation(addSubSubcategoryImage);
// }

// const deleteSubSubcategoryImage = async ({ id }) => {
//   const url = `/subsubcategories/${id}/image`;
//   const { data } = await API.delete(url);
//   return data;
// };

// export function useDeleteSubSubcategoryImage() {
//   return useMutation(deleteSubSubcategoryImage);
// }
