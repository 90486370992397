import React, { createContext, useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import { CategoriesColumn } from "./components/CategoriesColumn";
import { SubcategoriesColumn } from "./components/SubcategoriesColumn";
import { ExercisesColumn } from "./components/ExercisesColumn";
import { ExerciseColumn } from "./components/ExerciseColumn";
import { ExerciseCategory } from "../../../../../constants/enums";

export type ExerciseBankCategories = ExerciseCategory | "myExercise";

type ReactDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface ContextProps {
  selectedCategory: ExerciseBankCategories;
  setSelectedCategory: ReactDispatch<ExerciseBankCategories>;
  selectedSubcategoryId: number | null;
  setSelectedSubcategoryId: ReactDispatch<number | null>;
  selectedSubsubcategoryId: number | null;
  setSelectedSubsubcategoryId: ReactDispatch<number | null>;
  selectedExerciseId: number | null;
  setSelectedExerciseId: ReactDispatch<number | null>;
  isCreatingNewExercise: boolean;
  setIsCreatingNewExercise: ReactDispatch<boolean>;
}

export const ExerciseBankContext = createContext<ContextProps | null>(null);

export function ExerciseBank() {
  const [selectedCategory, setSelectedCategory] =
    useState<ExerciseBankCategories>(ExerciseCategory.Mobility);

  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState<
    number | null
  >(null);

  const [selectedSubsubcategoryId, setSelectedSubsubcategoryId] = useState<
    number | null
  >(null);

  const [selectedExerciseId, setSelectedExerciseId] = useState<number | null>(
    null
  );

  const [isCreatingNewExercise, setIsCreatingNewExercise] =
    useState<boolean>(false);

  useEffect(() => {
    setSelectedSubcategoryId(null);
  }, [selectedCategory]);

  useEffect(() => {
    setSelectedExerciseId(null);
    setSelectedSubsubcategoryId(null);
    setIsCreatingNewExercise(false);
  }, [selectedSubcategoryId]);

  useEffect(() => {
    setSelectedExerciseId(null);
    setIsCreatingNewExercise(false);
  }, [selectedSubsubcategoryId]);

  useEffect(() => {
    if (selectedExerciseId) {
      setIsCreatingNewExercise(false);
    }
  }, [selectedExerciseId]);

  const contextValue = useMemo(
    () => ({
      selectedCategory,
      setSelectedCategory,
      selectedSubcategoryId,
      setSelectedSubcategoryId,
      selectedExerciseId,
      setSelectedExerciseId,
      selectedSubsubcategoryId,
      setSelectedSubsubcategoryId,
      isCreatingNewExercise,
      setIsCreatingNewExercise,
    }),
    [
      selectedCategory,
      setSelectedCategory,
      selectedSubcategoryId,
      setSelectedSubcategoryId,
      selectedExerciseId,
      setSelectedExerciseId,
      selectedSubsubcategoryId,
      setSelectedSubsubcategoryId,
      isCreatingNewExercise,
      setIsCreatingNewExercise,
    ]
  );

  return (
    <ExerciseBankContext.Provider value={contextValue}>
      <Row>
        <Col span={6}>
          <CategoriesColumn />
        </Col>
        <Col span={6}>
          <SubcategoriesColumn />
        </Col>
        <Col span={6}>
          <ExercisesColumn />
        </Col>
        <Col span={6}>
          <ExerciseColumn />
        </Col>
      </Row>
    </ExerciseBankContext.Provider>
  );
}
