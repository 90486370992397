import {
  ListLoader,
  FreeProgramItem,
} from "../../../../../../../../components";
import { programTemplate } from "../../../../../../../../hooks/api/ProgramTemplates";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";

interface PropsProgramsList {
  programs: programTemplate[];
}

export function ProgramsList({ programs }: PropsProgramsList) {
  const { t } = useTranslation("programsBankPage.programsBank");

  return (
    <ListLoader
      items={programs}
      renderItem={(item) => (
        <FreeProgramItem key={item.id} program={item} clickable />
      )}
      emptyArrayText={t("noPrograms")}
    />
  );
}
