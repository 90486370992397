import * as React from "react";
import { SubscriptionContext } from "../context/SubscriptionProvider";

export const useSubscriptionState = () => {
  const context = React.useContext(SubscriptionContext);
  if (!context) {
    throw new Error("SubscriptionContext must be within SubscriptionProvider");
  }

  return context;
};
