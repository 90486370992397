import { Row, Typography } from "antd";
import { FormikErrors } from "formik";
import DatePicker from "./DatePicker";

interface DateInputProps {
  title: string;
  error: FormikErrors<Date> | undefined;
  value: Date;
  onChange: (e: Date | null) => void;
}

export function DateInput({ title, onChange, value, error }: DateInputProps) {
  const { Text } = Typography;

  return (
    <Row className="component-row">
      <Text type="secondary">{title}</Text>
      <DatePicker onChange={onChange} value={value} allowClear={false} />
      {error && <Text type="danger">{error}</Text>}
    </Row>
  );
}
