import { Row } from "antd";
import { useMemo } from "react";
import { ExerciseCategoryItem } from "../../../../../../../components";
import { ProgramCategory } from "../../../../../../../constants/enums";
import { useTranslation } from "../../../../../../../hooks/useTranslation";
import { useProgramBankContext } from "../../../../../../../hooks/useProgramBank";

export function CategoryColumn() {
  const { t } = useTranslation("programCategories");
  const { selectedCategory, setSelectedCategory } = useProgramBankContext();
  const options = useMemo(() => {
    return ["all", ...Object.values(ProgramCategory)];
  }, []);

  return (
    <Row className="exercise-bank-categories-container">
      {options.map((item) => (
        <ExerciseCategoryItem
          key={item}
          name={t(item)}
          isActive={selectedCategory === item}
          onClick={() => setSelectedCategory(item)}
        />
      ))}
    </Row>
  );
}
