import { Checkbox, CheckboxProps } from "antd";
import { Link } from "react-router-dom";

interface LabelCheckboxProps extends CheckboxProps {
  text: string;
  path: string;
  textLink: string;
  error: string | undefined;
}

export function LinkCheckbox({
  checked,
  onChange,
  text,
  path,
  textLink,
  error,
}: LabelCheckboxProps) {
  return (
    <Checkbox
      className={error && "error-checkbox"}
      checked={checked}
      onChange={onChange}
    >
      {text}
      <Link to={path} target="_blank">
        {textLink}
      </Link>
    </Checkbox>
  );
}
