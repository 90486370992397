import { Routes, Route, Navigate } from "react-router-dom";
import { Login } from "../containers/Public/Login";
import { SignUp } from "../containers/Public/Signup";
import { ForgotPassword } from "../containers/Public/ForgotPassword";
import { Statutes } from "../containers/Public/Statues";
import { DeleteAccountInstruction } from "../containers/Public/DeleteAccountInstruction";

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/statutes/:statute" element={<Statutes />} />
      <Route
        path="/delete-account-instruction"
        element={<DeleteAccountInstruction />}
      />
    </Routes>
  );
}
