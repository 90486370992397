import { i18n } from "../../../../../../../../i18n";
import * as Yup from "yup";
import {
  SubcategoryWithPrograms,
  UpdateSubcategoryEntry,
} from "../../../../../../../../hooks/api/Subcategories";

export type EditSubcategoryFormData = UpdateSubcategoryEntry;

export const initEditSubcategoryFormData = (
  subcategory: SubcategoryWithPrograms
): EditSubcategoryFormData => ({
  name: subcategory.name,
  description: subcategory.description ?? "",
});

export const EditSubcategorySchema: Yup.SchemaOf<EditSubcategoryFormData> =
  Yup.object()
    .shape({
      name: Yup.string().required(
        i18n.t("validators.required", { ns: "translation" })
      ),
      description: Yup.string().required(
        i18n.t("validators.required", { ns: "translation" })
      ),
    })
    .noUnknown(true)
    .strict()
    .required();
