import { MainContainer } from "./containers/MainContainer";
import { useAuthState } from "../hooks/useAuthState";
import { PublicRoutes } from "./PublicRoutes";
import { SubscriptionProvider } from "../context/SubscriptionProvider";
import { LoggedRoutes } from "./LoggedRoutes";

export function Router() {
  const { isAuthenticated } = useAuthState();

  if (isAuthenticated)
    return (
      <SubscriptionProvider>
        <MainContainer>
          <LoggedRoutes />
        </MainContainer>
      </SubscriptionProvider>
    );

  return <PublicRoutes />;
}
