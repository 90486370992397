import { ProgramCategory } from "../constants/enums";
import mobility from "../assets/images/Mobility_1.jpg";
import endurance from "../assets/images/Endurance_1.jpg";
import speed from "../assets/images/Speed_2.jpg";
import strenght from "../assets/images/Strenght_2.jpg";
import coordinateBalance from "../assets/images/Coordination&Balance_2.jpg";
import workingCapacity from "../assets/images/WorkingCapacity_2.jpg";
import athleticCapacity from "../assets/images/AthleticCapacity_2.jpg";
import specificGoal from "../assets/images/SpecificGoal_2.jpg";
import rehabilitation from "../assets/images/Rehabilitation_2.jpg";

export const useGenericProgramImage = () => {
  const getImage = (imageUrl: string | null, category: ProgramCategory) => {
    if (!imageUrl) {
      switch (category) {
        case ProgramCategory.Mobility:
          return mobility;
        case ProgramCategory.Endurance:
          return endurance;
        case ProgramCategory.Speed:
          return speed;
        case ProgramCategory.Strength:
          return strenght;
        case ProgramCategory.CoordinationBalance:
          return coordinateBalance;
        case ProgramCategory.WorkingCapacity:
          return workingCapacity;
        case ProgramCategory.AthleticCapacity:
          return athleticCapacity;
        case ProgramCategory.SpecificGoal:
          return specificGoal;
        case ProgramCategory.SportsMedicine:
          return rehabilitation;
      }
    } else return imageUrl;
  };

  return { getImage };
};
