import { Level } from "../constants/enums";
import { useTranslation } from "./useTranslation";

export const useLevel = (levelType: Level) => {
  const { t } = useTranslation("enums.level");

  switch (levelType) {
    case Level.Beginner:
      return t("beginner");
    case Level.Intermediate:
      return t("intermediate");
    case Level.Advanced:
      return t("advanced");
  }
};
