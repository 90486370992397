import { API } from "../../services/API";
import { useQuery } from "react-query";

const getStatue = async (name: string) => {
  const url = `/settings?name=${name}`;
  const { data } = await API.get(url);
  return data;
};

export function useGetStatue(name: string) {
  return useQuery(["getStatue", name], () => getStatue(name));
}
