import { SubscriptionStatus } from "./enums";

export const colors = {
  primary: "#1BA8F2", // blue
  successColor: "#52c41a",
  errorColor: "#ff0000",
  textSecondary: "#A7A7A7",
};

export const StatusColors: { [x in SubscriptionStatus]: string } = {
  active: colors.successColor,
  canceled: "gray",
  default: "black",
};
