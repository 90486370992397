import { Row, Typography } from "antd";
import { ArrowIcon } from "../../assets";

const { Title } = Typography;

interface PackItemProps {
  name: string;
  isActive: boolean;
  onClick: () => void;
}

export function PackItem({ name, isActive, onClick }: PackItemProps) {
  return (
    <Row
      style={{
        backgroundColor: isActive ? "#E4E4E4" : "white",
        width: "100%",
        borderColor: "black",
        borderWidth: isActive ? "2px" : "0px",
        borderStyle: "solid",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        margin: "10px 5px",
        boxShadow: "2px 3px 3px 1px #0005",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Title level={4} style={{ margin: 0, flexBasis: "90%" }}>
        {name}
      </Title>
      <ArrowIcon style={{ flexBasis: "3%" }} />
    </Row>
  );
}
