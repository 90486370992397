import axios from "axios";
import { storage } from "./storage";

const url = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: url,
  timeout: 10000,
});

API.interceptors.request.use((config) => {
  const { token } = storage.getAuth() as { token: string | null };
  if (token && config.headers != null) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config;

      if (
        err.response &&
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        originalReq._retry = true;
        const { token, refreshToken } = storage.getAuth();

        fetch(`${url}/auth/refresh-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, refreshToken }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.token && res.refreshToken) {
              storage.setAuth(res.token, res.refreshToken);
              originalReq.headers.Authorization = `Bearer ${res.token}`;

              return resolve(API(originalReq));
            } else {
              storage.clearStorage();
              window.location.reload();
            }
          });
      }
      return reject(err);
    });
  }
);

export { API };
