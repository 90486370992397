import { useMemo, Fragment } from "react";
import { ExerciseWithParametersItem } from "../../../../../../../../components/listItems/ExerciseWithParametersItem";
import { ExerciseTempalteWithSubcategory } from "../../../../../../../../hooks/api/ExerciseTemplates";
import { useTranslation } from "../../../../../../../../hooks/useTranslation";
import { groupByCategory } from "../../../../../../../../utils/groupBy";
import { Typography } from "antd";
import { useMyProgramsContext } from "../../../../../../../../hooks/useMyPrograms";

interface PropsGroupedExerciseTemplates {
  exercises: ExerciseTempalteWithSubcategory[];
}

const { Text } = Typography;

export function GroupedExerciseTemplates({
  exercises,
}: PropsGroupedExerciseTemplates) {
  const { selectedExerciseId, setSelectedExerciseId, setSelectedEditExercise } =
    useMyProgramsContext();
  const { t } = useTranslation("exerciseCategories");
  const groupedByCategory = useMemo(
    () => groupByCategory(exercises),
    [exercises]
  );

  return (
    <div style={{ width: "100%" }}>
      {groupedByCategory.map((category) => (
        <>
          {category.subcategories.map((subcategory) => (
            <Fragment key={subcategory.name}>
              {subcategory?.exercises.length > 0 && (
                <div
                  style={{
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    padding: "20px 0",
                  }}
                >
                  <Text style={{ fontWeight: "bold" }}>{t(category.name)}</Text>
                  <Text style={{ fontWeight: "bold" }}>
                    {` / ${subcategory.name}`}
                  </Text>
                </div>
              )}
              {subcategory.exercises.map(
                (exercise: ExerciseTempalteWithSubcategory) => (
                  <div
                    key={exercise.id}
                    style={{
                      margin: "0 5px",
                    }}
                  >
                    <ExerciseWithParametersItem
                      exercise={exercise}
                      isActive={exercise.id === selectedExerciseId}
                      onClick={() => {
                        setSelectedExerciseId(exercise.id);
                        setSelectedEditExercise(null);
                      }}
                    />
                  </div>
                )
              )}

              {subcategory.subsubcategories.map((subsubcategory) => (
                <Fragment key={subsubcategory.name}>
                  <div
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      alignItems: "center",
                      padding: "20px 0",
                    }}
                  >
                    <Text style={{ fontWeight: "bold" }}>
                      {t(category.name)}
                    </Text>
                    <Text style={{ fontWeight: "bold" }}>
                      {" "}
                      / {subcategory.name}
                    </Text>
                    <Text style={{ fontWeight: "bold" }}>
                      / {subsubcategory.name}
                    </Text>
                  </div>
                  {subsubcategory.exercises.map(
                    (exercise: ExerciseTempalteWithSubcategory) => (
                      <div
                        key={exercise.id}
                        style={{
                          margin: "0 5px",
                        }}
                      >
                        <ExerciseWithParametersItem
                          exercise={exercise}
                          isActive={exercise.id === selectedExerciseId}
                          onClick={() => {
                            setSelectedExerciseId(exercise.id);
                            setSelectedEditExercise(null);
                          }}
                        />
                      </div>
                    )
                  )}
                </Fragment>
              ))}
            </Fragment>
          ))}
        </>
      ))}
    </div>
  );
}
